import styled, { css } from 'styled-components'

import { Text } from '@renderforest/rf-ui-library/atoms/Text'
import SemrushDependedPopup from '@editor/common/components/SemrushDependedPopup'
import { Input } from '@renderforest/rf-ui-library/molecules/Input'
import { globalColors } from '@renderforest/rf-ui-library/consts/colors'

export const MyDomainsPopup = styled(SemrushDependedPopup)`
  & > div {
    padding: 20px 0 0 0;
    @media only screen and (min-width: 767px) {
      width: 100%;
      max-width: 400px;
    }
  }
  & > div > div {
    padding: 0;
  }
  @media only screen and (max-width: 768px) {
    height: 100%;
    max-height: 100%;
    & > div {
      height: 100%;
      max-height: 100%;
    }
  }
`
export const MyDomainsPopupContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const MyDomainsPopupHeader = styled.div`
  text-align: center;
  padding: 0 30px 20px 30px;
  @media only screen and (max-width: 767px) {
    padding: 0 20px 15px;
  }
`
export const Title = styled(Text)`
  padding: 0 20px 20px;

  @media only screen and (max-width: 992px) {
    padding: 0 90px 20px;
  }
  @media only screen and (max-width: 768px) {
    padding: 0 20px 20px;
  }
`
export const SearchInput = styled(Input)`
  input {
    &::placeholder {
      color: ${globalColors.secondarySemiLight};
    }
  }
  button {
    padding: 0 22px;
  }
`

export const MyDomainsPopupContent = styled.div`
  position: relative;
  overflow-y: hidden;
  height: 245px;
  width: 100%;
  @media only screen and (max-width: 768px) {
    height: calc(100vh - 200px);
    flex-grow: 1;
    max-width: 100%;
    box-sizing: border-box;
  }
`
export const MyDomainsPopupMain = styled.div`
  position: relative;
  overflow: auto;
  width: 100%;
  padding: 0 30px 10px;
  box-sizing: border-box;
  overscroll-behavior: contain;
  @media only screen and (max-width: 767px) {
    padding: 10px 20px;
  }
`

export const Item = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
`

export const DomainInfo = styled.div``

export const DomainName = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: ${({ isChecked, isDisabled }) =>
    isChecked
      ? globalColors.secondaryDark
      : isDisabled
      ? globalColors.secondarySemiLight
      : globalColors.secondarySemiDark};
`
export const DomainInfoText = styled.p`
  margin-top: 5px;
  font-size: 12px;
  font-weight: 500;
  color: ${globalColors.secondarySemiLight};
`
export const MyDomainsPopupFooter = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  border-top: 1px solid ${globalColors.primaryExtraLight};
  box-shadow: ${({ totalCount }) =>
    totalCount > 4 && `0 -3px 12px rgba(0,82,224,0.2)`};
  .footer-btn {
    margin: 0 10px;
    font-weight: 500;
  }
`

export const NotFoundDomainsWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
`
export const NotFoundDomainsImage = styled.img`
  width: 135px;
  height: 147px;
  padding-top: 40px;
`

export const ExpiresDate = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
`

export const ExpiresText = styled(Text)`
  margin-right: 5px;
  font-weight: 500;
`
export const NoDomainsWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 45px 0 50px;
  flex-grow: 1;
`
export const NoDomainsImage = styled.img`
  width: 134px;
  height: 147px;
  padding: 10px 0 40px;
`
export const NoDomainsText = styled(Text)`
  text-align: center;
  line-height: 25px;
  padding: 0 50px 20px;
  font-size: 20px;
  @media only screen and (max-width: 992px) {
    padding: 0 45px 20px;
  }
`
export const ListItem = styled.div`
  cursor: ${({ isDisabled }) => (isDisabled ? 'default' : 'pointer')};
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'auto')};
  display: flex;
  position: relative;
  padding: 10px 0;
`
const getRadioStyles = ({ isSelected, isDisabled }) => css`
  background: ${isSelected
    ? 'linear-gradient(0, #387dff 0%, #5690ff 100%)'
    : isDisabled
    ? globalColors.secondaryUltraLight
    : 'white'};
  border: ${isSelected ? '1px solid #387dff' : '1px solid #DDE3F0'};
`

export const Radio = styled.div`
  ${getRadioStyles};
  border-radius: 50%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 18px;
  width: 18px;
  height: 18px;
  margin: 2px 10px 0 0;
`

export const RadioIcon = styled.div`
  width: 6px;
  height: 6px;
  background-color: ${({ isDisabled }) =>
    isDisabled ? globalColors.secondaryUltraLight : 'white'};
  border-radius: 100%;
`

export const NoResults = styled(Text)`
  text-align: center;
`
