//@flow
import React, { memo, useCallback, useEffect, useContext, useMemo, useState } from 'react'

import * as Styled from './styled'
import {
  ACTIVE_ITEM_STATIC_DATA,
  LIST_MAX_HEIGHT,
  LIST_ITEM_HEIGHT
} from './consts'
import DropdownItem from './DropdownItem'
import {
  TLanguageDropdownContainerProps,
  TLanguageDropdownProps
} from './types'
import { getDropdownItems } from './utils'
import ArrowIcon from '@editor/common/assets/svgr-icons/arrow_filled_top.svg'
import { MobileDetectContext } from '@contexts'
import { translate } from '@editor/common/utils/translations'

const LanguageDropdownDesktop = memo(
  ({
    onClick,
    possibleLanguages,
    hasAnimation
  }: TLanguageDropdownContainerProps) => {
    useEffect(() => {
      const dropdownElem = document.querySelector('.language-search-dropdown')
      dropdownElem.addEventListener('click', () => {
        setTimeout(() => {
          dropdownElem.scrollIntoView({ behavior: 'smooth'})
        }, 200)
      })
    }, [])

    return (
      <Styled.SearchDropdownWrapper className="search-dropdown-wrapper">
        <Styled.SearchDropdown
          activeItem={ACTIVE_ITEM_STATIC_DATA}
          dropdownList={getDropdownItems(possibleLanguages)}
          placeholder={translate('select_from_list_label')}
          noResultMessage={translate('dropdown_no_results_found_message')}
          onListItemClick={onClick}
          className="language-search-dropdown"
          dropdownTogglerIcon="arrow_filled_bottom"
          listItemHeight={LIST_ITEM_HEIGHT}
          dropdownWidth="100%"
          listMaxHeight={LIST_MAX_HEIGHT}
          hasAnimation={hasAnimation}
        />
      </Styled.SearchDropdownWrapper>
    )
  }
)

const LanguageDropdownMobile = memo(
  ({
    onClick,
    possibleLanguages,
    selectedLanguage,
    hasAnimation
  }: TLanguageDropdownContainerProps) => {
    const [isDropdownOpen, setDropdownOpenedState] = useState(false)

    const openDropdown = useCallback(() => {
      setDropdownOpenedState(true)
    }, [])

    const closeDropdown = useCallback(() => {
      setDropdownOpenedState(false)
    }, [])

    const onItemClick = useCallback(
      name => {
        onClick(name)
        closeDropdown()
      },
      [onClick]
    )

    return (
      <>
        <Styled.MobileDropdown
          onClick={openDropdown}
          isOpen={isDropdownOpen}
          hasAnimation={hasAnimation}
        >
          {selectedLanguage ? (
            <DropdownItem
              language={selectedLanguage}
              className="active-value"
            />
          ) : (
            <p>{translate('select_from_list_label')}</p>
          )}
          <ArrowIcon />
        </Styled.MobileDropdown>
        <Styled.MobileSlideUp
          isOpen={isDropdownOpen}
          onClose={closeDropdown}
          title={translate('choose_a_language_label')}
        >
          <Styled.MobileSlideUpContent>
            {possibleLanguages.map((language, idx) => (
              <DropdownItem
                key={idx}
                language={language}
                onClick={() => onItemClick(`${language.code}-${language.flagCode}`)}
              />
            ))}
          </Styled.MobileSlideUpContent>
        </Styled.MobileSlideUp>
      </>
    )
  }
)

const LanguagesDropdown = ({
  value,
  possibleLanguages,
  onClick,
  hasAnimation
}: TLanguageDropdownProps) => {
  const isMobile = useContext(MobileDetectContext)

  const selectedLanguage = useMemo(
    () => possibleLanguages.find(language => language.name === value),
    [possibleLanguages, value]
  )

  return isMobile ? (
    <LanguageDropdownMobile
      onClick={onClick}
      selectedLanguage={selectedLanguage}
      possibleLanguages={possibleLanguages}
      hasAnimation={hasAnimation}
    />
  ) : (
    <LanguageDropdownDesktop
      onClick={onClick}
      possibleLanguages={possibleLanguages}
      hasAnimation={hasAnimation}
    />
  )
}

export default memo(LanguagesDropdown)
