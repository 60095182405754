/* @flow */

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { translate } from '@editor/common/utils/translations'

import {
  selectDomains,
  selectIsDomainsLoading,
  selectDomainsTotalCount,
  selectDomainsSearchQuery
} from '@selectors'
import * as Styled from './styled'
import NoDomains from './NoDomains'
import debounce from '@editor/common/utils/debounce'
import DomainsList from './DomainsList'
import If from '@website/common/components/Conditional'
import { TMyDomainsPopupProps } from './types'
import DomainsListItem from './DomainsListItem'
import { isValidSearchString } from '../../../../utils'
import Button from '@renderforest/rf-ui-library/atoms/Button'
import { fetchDomains, setSearchQuery } from '@actions/editor'
import { NO_DOMAINS_IMG_PATH } from '../../consts'
import BusyWrapper from '@renderforest/rf-ui-library/atoms/BusyWrapper'

const MyDomainsPopup = ({
  isOpen,
  onClose,
  fetchDomains,
  domains,
  searchQuery,
  totalCount,
  isLoading,
  setSearchQuery,
  domain,
  setDomain
}: TMyDomainsPopupProps) => {
  const [value, setValue] = useState(domain)
  const [searchText, setSearchText] = useState('')
  const [isOnlySelected, setSelectedState] = useState(false)
  const scrollableContentRef = useRef(null)
  const scrollableElem = scrollableContentRef.current

  useEffect(() => {
    setValue(domain)
  }, [domain])

  useEffect(() => {
    fetchDomains()
  }, [])

  useEffect(() => {
    if (!value) {
      setSelectedState(true)
    }
  }, [value])

  const handleClose = useCallback(() => {
    if (isOnlySelected) {
      setValue(null)
    }
    setSearchText('')
    setSearchQuery('')
    fetchDomains()
    onClose()
  }, [
    onClose,
    setSearchText,
    setSearchQuery,
    isOnlySelected,
    setValue,
    fetchDomains
  ])

  const handleScroll = useCallback(() => {
    if (
      scrollableElem.scrollHeight - scrollableElem.scrollTop ===
      scrollableElem.clientHeight
    ) {
      fetchDomains()
    }
  }, [fetchDomains, scrollableElem])

  useEffect(() => {
    if (scrollableElem) {
      scrollableElem.addEventListener('scroll', handleScroll)
      return () => scrollableElem.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll, scrollableElem])

  const items = useMemo(
    () =>
      domains &&
      domains.map(item => ({
        label: (
          <DomainsListItem
            isChecked={item.name === value}
            isDisabled={item.siteId !== null}
            key={item.id}
            item={item}
          />
        ),
        value: item.name,
        isDisabled: item.siteId !== null
      })),
    [value, domains]
  )

  const onItemClick = useCallback(
    value => {
      setValue(value)
    },
    [setValue]
  )

  const { current: onDebounceSearch } = useRef<Function>(
    debounce(text => {
      fetchDomains(text)
      setSearchQuery(text)
    }, 500)
  )

  const onSearchInputChange = useCallback(
    e => {
      if (!isValidSearchString(e.target.value)) {
        return
      }
      setSearchText(e.target.value)
      onDebounceSearch(e.target.value)
    },
    [onDebounceSearch, setSearchText]
  )

  const onChooseBtnClick = useCallback(() => {
    setSelectedState(false)
    setDomain(value)
    onClose()
  }, [value, setDomain])

  if (!domains.length && isLoading && !searchQuery) {
    return null
  }

  return (
    <Styled.MyDomainsPopup
      isMobileFullScreen
      isOpen={isOpen}
      onClose={handleClose}
    >
      {domains.length || !!searchQuery ? (
        <Styled.MyDomainsPopupContainer>
          <Styled.MyDomainsPopupHeader totalCount={totalCount}>
            <Styled.Title size="xLarge" color="secondaryDark" weight={600}>
              {translate('my_domains_label')}
            </Styled.Title>
            <Styled.SearchInput
              onChange={onSearchInputChange}
              value={searchText}
              placeholder={translate('search_label')}
              iconProps={{
                name: 'search',
                color: 'secondarySemiLight',
                size: 'small'
              }}
            />
          </Styled.MyDomainsPopupHeader>
          <Styled.MyDomainsPopupContent>
            <BusyWrapper isBusy={isLoading}>
              <Styled.MyDomainsPopupMain ref={scrollableContentRef}>
                <If
                  condition={!!domains.length}
                  then={() => (
                    <DomainsList
                      list={items}
                      onItemClick={onItemClick}
                      selected={value}
                    />
                  )}
                  otherwise={() => (
                    <If
                      condition={searchQuery}
                      then={() => (
                        <Styled.NotFoundDomainsWrapper>
                          <Styled.NoResults
                            size="large"
                            color="secondaryDark"
                            weight={600}
                          >
                            {translate('there_are_no_domains_label')}
                          </Styled.NoResults>
                          <Styled.NotFoundDomainsImage
                            src={NO_DOMAINS_IMG_PATH}
                            alt="notFound"
                          />
                        </Styled.NotFoundDomainsWrapper>
                      )}
                    />
                  )}
                />
              </Styled.MyDomainsPopupMain>
            </BusyWrapper>
          </Styled.MyDomainsPopupContent>
          <Styled.MyDomainsPopupFooter totalCount={totalCount}>
            <Button
              onClick={handleClose}
              type="transparent"
              size="small"
              className="footer-btn"
            >
              {translate('cancel_label')}
            </Button>
            <Button
              type="secondary"
              size="small"
              className="footer-btn"
              disabled={!value}
              onClick={onChooseBtnClick}
            >
              {translate('choose_label', true)}
            </Button>
          </Styled.MyDomainsPopupFooter>
        </Styled.MyDomainsPopupContainer>
      ) : (
        <NoDomains
          onClose={handleClose}
          text={translate('you_have_no_domain_label')}
        />
      )}
    </Styled.MyDomainsPopup>
  )
}

const mapStateToProps = state => ({
  domains: selectDomains(state),
  searchQuery: selectDomainsSearchQuery(state),
  isLoading: selectIsDomainsLoading(state),
  totalCount: selectDomainsTotalCount(state)
})

export default connect(mapStateToProps, { fetchDomains, setSearchQuery })(
  MyDomainsPopup
)
