/* @flow */
import React from 'react'
import { translate } from '@editor/common/utils/translations'
import * as Styled from './styled'
import ExpireDate from './ExpireDate'
import { TDomainsListItemProps } from './types'

const DomainsListItem = React.memo<TDomainsListItemProps>(
  ({ item, isChecked, isDisabled }: TDomainsListItemProps) => (
    <Styled.Item>
      <Styled.DomainInfo>
        <Styled.DomainName isChecked={isChecked} isDisabled={isDisabled}>
          {item.name}
        </Styled.DomainName>
        {isDisabled ? (
          <Styled.DomainInfoText>
            {translate('domain_is_already_used_label')}
          </Styled.DomainInfoText>
        ) : (
          <ExpireDate endDate={item.endDate} />
        )}
      </Styled.DomainInfo>
    </Styled.Item>
  )
)

export default DomainsListItem
