import styled from 'styled-components'
import { Icon } from '@renderforest/rf-ui-library/atoms/Icon'

export const MessageContainer = styled.div`
  display: flex;
  align-items: ${({ isWarning }) => (isWarning ? 'flex-start' : 'center')};
  padding-top: 10px;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`
export const MessageWrapper = styled.p`
  margin-left: 5px;
`
export const MessageIcon = styled(Icon)`
  transform: ${({ isWarning }) =>
    isWarning ? 'translateY(2px)' : 'translateY(0)'};
`
export const Message = styled.span`
  font-size: 12px;
  color: ${({ textColor }) => textColor || '#7683a8'};
`
