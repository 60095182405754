//@flow
import React, { memo } from 'react'

import * as Styled from './styled'
import type { TLanguagesForFreeUsersProps } from './types'
import {
  MULTILINGUAL_UPGRADE_IMAGE,
  MULTILINGUAL_UPGRADE_IMAGE_HOVER
} from './consts'
import { Footer, UpgradeBanner } from '../shared-components'
import { translate } from '@editor/common/utils/translations'

const LanguagesForFreeUsers = ({
  closeSettingsPopup
}: TLanguagesForFreeUsersProps) => (
  <>
    <Styled.UpgradeBannerWrapper>
      <UpgradeBanner
        className="upgrade-banner"
        img={MULTILINGUAL_UPGRADE_IMAGE}
        hoverImg={MULTILINGUAL_UPGRADE_IMAGE_HOVER}
        info={translate('upgrade_to_make_multilingual_label')}
        customTranslationAlignment={130}
      />
    </Styled.UpgradeBannerWrapper>
    <Footer isSaveButtonDisabled onDiscard={closeSettingsPopup} />
  </>
)

export default memo(LanguagesForFreeUsers)
