//@flow
import React from 'react'
import { connect } from 'react-redux'

import type { TLanguagesProps } from './types'
import LanguagesForFreeUsers from './LanguagesForFreeUsers'
import LanguagesForSubscribers from './LanguagesForSubscribers'
import { isSubscriber } from '@selectors'

const Languages = ({
  isSubscriber,
  setChangesState,
  closeSettingsPopup
}: TLanguagesProps) =>
  isSubscriber ? (
    <LanguagesForSubscribers
      closeSettingsPopup={closeSettingsPopup}
      setChangesState={setChangesState}
    />
  ) : (
    <LanguagesForFreeUsers closeSettingsPopup={closeSettingsPopup} />
  )

const mapStateToProps = state => ({
  isSubscriber: isSubscriber(state)
})

export default connect(mapStateToProps)(Languages)
