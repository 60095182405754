import styled, { css, keyframes } from 'styled-components'

import { MobilePopup } from '@editor/common/lib/Popup'
import { primary, secondary } from '@website/common/styles/colors'
import { scrollBarStyles } from '@editor/common/styles'
import { SearchDropdown as LibSearchDropdown } from '@renderforest/rf-ui-library/molecules/CustomizableDropdown/SearchDropdown'

const pulseAnimationKeyframes = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(50, 113, 230, 0.8);
  }
  100% {
    box-shadow: 0 0 0 5px rgba(50, 113, 230, 0);
  }
`

export const SearchDropdownWrapper = styled.div`
  position: relative;
`

export const SearchDropdown = styled(LibSearchDropdown)`
  .dropdown-wrapper,
  .dropdown-wrapper-active {
    border-radius: 5px;
    max-height: 40px;
    padding: 10px 12px;
  }

  .dropdown-wrapper {
    border: 1px solid ${secondary.Light};
  }
  .dropdown-wrapper-active {
    border: 1px solid #3271e6;
  }

  .active-value-wrapper {
    input {
      margin: 0;
      padding: 0;
      &::placeholder {
        color: #8493bd;
      }
    }

    .arrow_filled_bottom {
      margin-top: 0;
      position: absolute;
      right: 10px;
      top: 7px;
      svg {
        width: 24px;
        height: 24px;
      }
    }
  }

  .search-dropdown-options-wrapper {
    border: 1px solid ${secondary.Light};
    border-radius: 5px;
    box-shadow: 0 3px 6px #3271e633;
    overflow: hidden;
    top: 45px;
    left: 0;
    padding: 0;

    .react-window-wrapper {
      padding: 0;

      &::-webkit-scrollbar-thumb {
        background: ${secondary.Light};
      }

      .search-dropdown-list-item {
        padding: 0;
        border-radius: 0;

        &:hover {
          background: ${primary.Light};
        }
      }
    }

    .empty-result-container {
      & > span {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }

  ${({ hasAnimation }) =>
    hasAnimation
      ? css`
          animation: ${pulseAnimationKeyframes} 2s infinite;
        `
      : ''}

  &:hover {
    box-shadow: unset;
  }
`

export const MobileDropdown = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  padding: 0 15px 0 15px;
  border-radius: 5px;
  border: 1px solid ${secondary.Light};
  box-sizing: border-box;
  margin-top: 10px;

  & > svg {
    fill: ${secondary.SemiDark};
    transform: rotate(${({ isOpen }) => (isOpen ? 0 : 180)}deg);
  }

  & > p {
    font-size: 14px;
    color: #8493bd;
  }

  .active-value {
    padding: 0;

    &:hover {
      color: ${secondary.SemiDark};
    }
  }

  ${({ hasAnimation }) =>
    hasAnimation
      ? css`
          animation: ${pulseAnimationKeyframes} 2s infinite;
        `
      : ''}
`

export const DropdownItem = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  font-size: 14px;
  color: ${secondary.SemiDark};

  & > img {
    width: 20px;
    height: 20px;
    border-radius: 5px;
    margin-right: 10px;
    border: 1px solid #dee3f0;
    box-sizing: unset;
  }

  &:hover {
    color: ${secondary.ExtraDark};
  }

  @media only screen and (max-width: 768px) {
    height: unset;
  }
`

export const MobileSlideUp = styled(MobilePopup)`
  display: flex;
  flex-direction: column;
  & > div:first-child {
    max-height: 395px;
  }
`

export const Flag = styled.img`
  position: absolute;
  top: 10px;
  left: 16px;
  width: 20px;
  height: 20px;
  border-radius: 5px;
`

export const MobileSlideUpContent = styled.div`
  ${scrollBarStyles};
  overflow-y: scroll;
`
