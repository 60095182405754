// @flow
import React from 'react'
import { connect } from 'react-redux'

import Card from '../Card'
import {
  selectYandexMetricaId,
  selectYandexMetricaSuccessMessage
} from '@selectors'
import { setYandexMetricaId } from '@actions/meta'

import type { TYandexMetricaProps } from './types'

const YandexMetrica = ({
  value,
  setValue,
  yandexMetricaId,
  setYandexMetricaId,
  successMessage,
  ...rest
}: TYandexMetricaProps) => {
  const hasChanges = value !== yandexMetricaId
  const isConnected = !!yandexMetricaId

  return (
    <Card
      inputValue={value}
      isConnected={isConnected}
      hasChanges={hasChanges}
      successMessage={successMessage}
      setId={setYandexMetricaId}
      setInputValue={setValue}
      {...rest}
    />
  )
}

const mapStateToProps = state => ({
  yandexMetricaId: selectYandexMetricaId(state),
  successMessage: selectYandexMetricaSuccessMessage(state)
})

const mapDispatchToProps = { setYandexMetricaId }

export default connect(mapStateToProps, mapDispatchToProps)(YandexMetrica)
