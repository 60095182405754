import styled, { createGlobalStyle, css } from 'styled-components'
import { styleByLang } from '@editor/common/utils/style-by-lang'

const cardCustomFontSize = {
  titleFontSize: '16px',
  descriptionFontSize: '14px'
}

const cardTitleCustomWidth = {
  titleMaxWidth: '190px'
}

const cardTitleDefaultStyles = {
  titleFontSize: '14px',
  descriptionFontSize: '12px'
}

const stylesObj = {
  en: cardCustomFontSize,
  tr: cardCustomFontSize,
  es: {
    ...cardTitleCustomWidth,
    ...cardTitleDefaultStyles
  },
  de: {
    ...cardTitleCustomWidth,
    ...cardTitleDefaultStyles
  },
  default: cardTitleDefaultStyles
}

const getStylesByLang = styleByLang(stylesObj)

export const GlobalStyles = createGlobalStyle`
  .card-tooltip_content {
    transform: translateY(-40px);
  }
  `

const getCardStyles = ({ isSelected }) =>
  isSelected
    ? css`
        border: 1px solid #387dff;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      `
    : css`
        border: 1px solid #d2e0fc;
        border-radius: 10px;
        &:hover {
          box-shadow: 3px 3px 12px #387dff33;
          transform: translateY(-5px);
          transition: all 0.4s ease-out;
        }
      `

export const CardWrapper = styled.div`
  position: relative;
  background-color: ${({ isSelected }) => (isSelected ? '#eaf2ff' : '#ffffff')};
  padding: 5px 5px 15px;
  border-radius: 10px;
`
export const Card = styled.div`
  ${getCardStyles};
  position: relative;
  width: 350px;
  height: 110px;
  display: flex;
  background-color: #ffffff;
  cursor: pointer;
  box-sizing: border-box;
`

export const UpgradeLabel = styled.img`
  position: absolute;
  z-index: 1;
  top: -7px;
  right: 15px;
`

export const Info = styled.div`
  width: 220px;
  padding-left: 5px;
`

export const Title = styled.h4`
  font-size: ${getStylesByLang('titleFontSize')};
  max-width: ${getStylesByLang('titleMaxWidth')};
  color: #363f5a;
  font-weight: 500;
  padding-top: 15px;
`
export const Description = styled.p`
  color: #545f7e;
  font-size: ${getStylesByLang('descriptionFontSize')};
  margin-top: 5px;
`

export const Overlay = styled.div`
  position: absolute;
  background-color: #ffffff;
  opacity: 0.5;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`
