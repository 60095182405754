// @flow

import React, { memo, useCallback } from 'react'
import * as Styled from './styled'

const DomainsList = ({ list, onItemClick, selected }) => {
  const handleItemClick = useCallback(
    (value, isDisabled) => {
      !isDisabled && onItemClick(value)
    },
    [onItemClick]
  )

  return (
    <>
      {list.map(item => {
        const isSelected = item.value === selected

        return (
          <Styled.ListItem
            isDisabled={item.isDisabled}
            onClick={() => handleItemClick(item.value, item.isDisabled)}
          >
            <Styled.Radio isSelected={isSelected} isDisabled={item.isDisabled}>
              <Styled.RadioIcon isDisabled={item.isDisabled} />
            </Styled.Radio>
            {item.label}
          </Styled.ListItem>
        )
      })}
    </>
  )
}

export default memo(DomainsList)
