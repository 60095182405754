import styled from 'styled-components'

import { DELETED_ITEM_OVERLAY } from './consts'
import { secondary, white } from '@website/common/styles/colors'
import { hexToRgbA } from '@website/common/utils'

export const ListItemWrapper = styled.div`
  position: relative;

  .drag-icon-tooltip {
    position: absolute;
    left: -24px;
    top: 7px;

    & > svg {
      fill: #8493bd;
    }

    @media only screen and (max-width: 768px) {
      left: -20px;

      & > svg {
        width: 20px;
        height: 20px;
      }
    }
  }
`

export const ListItem = styled.div`
  position: relative;
  height: 38px;
  padding: 0 10px 0 15px;
  border: 1px solid
    ${({ isLanguageVisible }) =>
      isLanguageVisible ? secondary.Light : hexToRgbA(secondary.Light, 0.5)};
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  background-color: ${white};
  background-image: ${({ isLanguageDeleted }) =>
    isLanguageDeleted ? `url(${DELETED_ITEM_OVERLAY})` : 'none'};
  opacity: ${({ isDragging }) => (isDragging ? 0 : 1)};
  transform: ${({ isDraggable }) =>
    isDraggable ? 'translate(0px, 0px)' : 'unset'};
  cursor: ${({ isDropped, isDraggable }) =>
    !isDraggable ? 'default' : isDropped ? 'grab' : 'grabbing'};
`

export const Divider = styled.div`
  height: 20px;
  width: 1px;
  background-color: ${secondary.Light};
  margin: 0 5px;
`
