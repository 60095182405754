import { translate } from '@editor/common/utils/translations'
import { getLocalizedURL } from '@editor/common/utils/translations'
import { transformTextVariables } from '@src/editor/common/utils/text-transformation'

const SIGN_IN_TO_YOUR_DOMAIN_PROVIDER = translate(
  'sign_in_your_domain_provider_label'
)
const EDIT_YOUR_DNS_RECORD = translate('edit_your_dns_records_label')
const CREATE_CNAME_RECORD = translate('create_new_cname_record_label')
const CREATE_A_RECORD = translate('create_new_a_record_label')

export const CNAME_DESCRIPTION_STEPS = [
  {
    text: SIGN_IN_TO_YOUR_DOMAIN_PROVIDER
  },
  {
    text: EDIT_YOUR_DNS_RECORD
  },
  {
    text: CREATE_CNAME_RECORD,
    tooltip: transformTextVariables(translate('www_option_tooltip_text'), [
      {
        value: 'CNAME'
      }
    ])
  }
]

export const ARECORD_DESCRIPTION_STEPS = [
  {
    text: SIGN_IN_TO_YOUR_DOMAIN_PROVIDER
  },
  {
    text: EDIT_YOUR_DNS_RECORD
  },
  {
    text: CREATE_A_RECORD,
    tooltip: transformTextVariables(translate('www_option_tooltip_text'), [
      {
        value: 'A record'
      }
    ])
  }
]

export const NEED_HELP_LINK = `https://www.renderforest.com/${getLocalizedURL(
  '/website-maker/custom-domain'
)}`

export const WWW_OPTIONS_IMG_SRC =
  'https://static.rfstat.com/renderforest/images/website_maker_images/redirect_domain.svg'
