export const SUBDOMAIN = 'subdomain'

export const ACCORDION_TYPES = {
  customDomain: 0,
  subdomain: 1
}

export const GET_NOW_CARD_IMAGE =
  'https://static.rfstat.com/renderforest/images/website_maker_images/buy_domain.svg'
export const GET_NOW_CARD_IMAGE_HOVER =
  'https://static.rfstat.com/renderforest/images/website_maker_images/buy_domain_hover.svg'
export const CUSTOM_DOMAIN_IMG =
  'https://static.rfstat.com/renderforest/images/website_maker_images/custom_domain.svg'
export const SUBDOMAIN_IMG =
  'https://static.rfstat.com/renderforest/images/website_maker_images/subdomain.svg'
export const UPGRADE_LABEL =
  'https://static.rfstat.com/renderforest/images/website_maker_images/label-upgrade.svg'
export const CUSTOM_DOMAIN_CORNER =
  'https://static.rfstat.com/renderforest/images/website_maker_images/custom_domain_corner.svg'
export const SUBDOMAIN_CORNER =
  'https://static.rfstat.com/renderforest/images/website_maker_images/subdomain_corner.svg'
