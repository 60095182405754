//@flow
import React, { memo, useRef, useState } from 'react'

import * as Styled from './styled'
import { TNotificationBannerProps } from './types'
import InfoIcon from '@editor/common/assets/svgr-icons/Info_icon.svg'
import { useDidUpdateEffect } from '@hooks/useDidUpdateEffect'

const NotificationBanner = ({
  text,
  isVisible: isVisibleFromProps
}: TNotificationBannerProps) => {
  const [isVisible, setVisibility] = useState(false)
  const timeoutRef = useRef(null)

  useDidUpdateEffect(() => {
    if (isVisibleFromProps) {
      setVisibility(true)
      timeoutRef.current = setTimeout(() => setVisibility(false), 10000)
    } else {
      setVisibility(false)
    }

    return () => {
      clearTimeout(timeoutRef.current)
    }
  }, [isVisibleFromProps, timeoutRef.current])

  return isVisible ? (
    <Styled.NotificationBanner>
      <InfoIcon />
      <span>{text}</span>
    </Styled.NotificationBanner>
  ) : null
}

export default memo(NotificationBanner)
