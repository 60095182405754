// @flow
import React, { useCallback, useContext, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { translate } from '@editor/common/utils/translations'

import * as Styled from './styled'
import { TCookieTabsProps } from './types'
//TODO: convert svgs to links
import CookieBannerImg from './simple-cookie.svg'
import GDPRCookieBannerImg from './gdpr-cookie.svg'
import { TabContent } from '../shared-components'
import Simple from './containers/Simple'
import SettingsTabs from '@editor/common/components/SettingsTabs'
import { UpgradePopupContext } from '@contexts'
import { isSubscriber } from '@selectors'
import GDPR from './containers/GDPR'
import { GDPRCookieContext, SimpleCookieContext } from './store'
import {
  LEAVE_TAB_DIALOG_BUTTONS
} from '@editor/containers/edit/containers/settings/consts'
import { useDiscardDialog } from '@hooks/useDiscardDialog'

const CookiesDesktop = ({
  isSubscriber,
  hasChangesInTab,
  isSimpleDisabled
}: TCookieTabsProps) => {
  const initialActiveTab = isSubscriber ? 'right' : 'left'
  const [activeTab, setActiveTab] = useState(initialActiveTab)

  const { setUpgradePopupState } = useContext(UpgradePopupContext)
  const {
    actions: { resetGDPRSettings }
  } = useContext(GDPRCookieContext)

  const {
    actions: { resetSimpleSettings }
  } = useContext(SimpleCookieContext)

  const tabsData = useMemo(
    () => ({
      left: {
        tab: (
          <TabContent
            imgSrc={CookieBannerImg}
            title={translate('cookie_banner_label')}
            description={translate('simple_banner_description')}
          />
        ),
        content: <Simple />,
        isDisabled: isSimpleDisabled,
        tooltipText: isSimpleDisabled
          ? translate('disabled_gdpr_banner_tooltip')
          : ''
      },
      right: {
        tab: (
          <TabContent
            imgSrc={GDPRCookieBannerImg}
            title={translate('gdpr_banner_label')}
            description={translate('gdpr_banner_description')}
            showUpgradeLabel={!isSubscriber}
          />
        ),
        content: <GDPR />
      }
    }),
    [isSubscriber, isSimpleDisabled]
  )

  const onDiscard = tab => {
    setActiveTab(tab)
    tab === 'left' ? resetGDPRSettings() : resetSimpleSettings()
  }

  const onTabChange = useDiscardDialog(
    onDiscard,
    {
      id: 'cookies',
      hasChanges: hasChangesInTab
    },
    {
      title: translate('settings_tab_changing_popup_label'),
      buttonTexts: LEAVE_TAB_DIALOG_BUTTONS
    }
  )

  const onTabClick = useCallback(
    active => {
      if (active === activeTab) {
        return
      }

      if (!isSubscriber && active === 'right') {
        setUpgradePopupState({
          isOpen: true
        })
        return
      }

      onTabChange(active)
    },
    [isSubscriber, onTabChange, activeTab]
  )

  return (
    <Styled.CookieTabsWrapper>
      <SettingsTabs
        activeTab={activeTab}
        tabsData={tabsData}
        onTabClick={onTabClick}
      />
    </Styled.CookieTabsWrapper>
  )
}

const mapStateToProps = state => ({
  isSubscriber: isSubscriber(state)
})

export default connect(mapStateToProps)(CookiesDesktop)
