import styled from 'styled-components'

import Button from '@editor/common/lib/Button'
import Popup from '@renderforest/rf-ui-library/organism/Popup'
import { secondary } from '@website/common/styles/colors'

export const SuccessPopup = styled(Popup)`
  & > div {
    width: 420px;
    & > div {
      padding: 10px;
      align-items: center;
    }

    @media screen and (max-width: 550px) {
      width: 100%;
    }
  }
`

export const AnimationContainer = styled.div`
  .animation-wrapper {
    zoom: 0.6;
  }
`

export const Title = styled.p`
  font-weight: 700;
  font-size: 20px;
  color: ${secondary.Dark};
  text-align: center;
  margin: 30px 0 20px;
`

export const Description = styled.p`
  font-size: 16px;
  color: ${secondary.Dark};
  text-align: center;
  max-width: 350px;
`

export const GotItButton = styled(Button)`
  margin-top: 30px;
`
