import styled from 'styled-components'
import { scrollBarStyles } from '@editor/common/styles'
import { primary } from '@website/common/styles/colors'
import { styleByLang } from '@editor/common/utils/style-by-lang'
import {
  Description as _Description,
  TitleContainer as SharedTitle
} from '../../../shared-components'

const cookieBannerTitle250Width = {
  maxWidth: '250px'
}

const cookieBannerTitle240Width = {
  maxWidth: '240px'
}

const stylesObj = {
  de: {
    titleWidth: 'min-content'
  },
  es: cookieBannerTitle250Width,
  ru: cookieBannerTitle240Width,
  fr: cookieBannerTitle240Width,
  pt: cookieBannerTitle250Width,
  jp: cookieBannerTitle250Width
}
const getStylesByLang = styleByLang(stylesObj)

export const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;

  .gdpr-accordion-btns {
    padding: 0 20px;

    @media only screen and (max-width: 767px) {
      padding: 10px;
    }

    @media only screen and (min-width: 769px) {
      max-width: 500px;
      padding: 0 30px;
    }
  }

  ${scrollBarStyles}

  .single-accordion-content {
    .gdpr-buttons-input {
      margin-bottom: 20px;
    }
  }
`

export const ConsentSection = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 20px 20px 0;

  .gdpr-consent-input {
    width: 100%;
  }

  @media only screen and (min-width: 769px) {
    padding: 30px 30px 0 30px;

    .gdpr-consent-input {
      width: 500px;
    }
  }

  @media only screen and (max-width: 767px) {
    padding: 10px;
  }
`

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;

  div[role='switch'] {
    margin-left: 15px;
  }
`

export const Title = styled(SharedTitle)`
  @media only screen and (max-width: 768px) {
    max-width: ${getStylesByLang('maxWidth')};
    width: ${getStylesByLang('titleWidth')};
  }
`

export const NeedHelpLink = styled.a`
  display: flex;
  align-items: center;
  padding: 0 20px 10px 0;
  font-size: 14px;
  font-weight: 500;
  color: ${primary.Dark};
  text-decoration: none;
  & > div {
    margin-right: 6px;
  }
  &:hover {
    text-decoration: underline;
    text-decoration-color: ${primary.Dark};
  }
`

export const Description = styled(_Description)`
  padding: 20px 0;
`

export const InputLabel = styled(_Description)`
  padding: 10px 0;
`

export const Image = styled.img`
  display: none;

  @media only screen and (max-width: 768px) {
    display: block;
    width: 90px;
    height: 107px;
    margin: 0 auto 15px;
  }
`
