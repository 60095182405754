import styled from 'styled-components'
import { IconTextButton } from '../../shared-components'

export const SecondaryLanguagesList = styled.div`
  & > div:not(:first-child) {
    margin-top: 10px;
  }

  .item-list__item {
    width: calc(100% - 40px);

    .drag-icon-tooltip {
      display: none;
    }
  }
`

export const AddLanguageButton = styled(IconTextButton)`
  margin-top: 20px;
  color: #3271e6;

  svg {
    fill: #3271e6;
  }
`
