/* @flow */
import React from 'react'

import * as Styled from './styled'
import { TTableProps } from './types'
import Icon from '@website/common/components/Icon'
import { primary } from '@website/common/styles/colors'
import { copyToClipboard } from '@website/common/utils'
import { translate } from '@editor/common/utils/translations'

export const Table = React.memo<TTableProps>(({ type, value }: TTableProps) => {
  return (
    <Styled.Table>
      <tbody>
        <tr className="first-block">
          <td>{translate('type_label')}</td>
          <td>{translate('name_label')}</td>
          <td>{translate('value_label')}</td>
        </tr>
        <tr className="second-block">
          <td>{type}</td>
          <td>
            WWW
            <p>or</p>@
          </td>
          <td>
            <div className="copy-btn-wrapper">
              <p className="copy-btn-text">{value}</p>
              <button
                className="copy-btn"
                onClick={() => copyToClipboard(value)}
              >
                <Icon
                  name="copy-complexity-3"
                  color={primary.Dark}
                  size="xnormal"
                />
                {translate('copy_label')}
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </Styled.Table>
  )
})

export const SmallTable = React.memo<TTableProps>(
  ({ type, value }: TTableProps) => {
    return (
      <Styled.SmallTable>
        <tbody>
          <tr className="first-block">
            <td>{translate('type_label', true)}</td>
            <td>{translate('name_label', true)}</td>
          </tr>
          <tr className="second-block">
            <td>{type}</td>
            <td>
              WWW
              <p>{translate('or_label')}</p>@
            </td>
          </tr>
          <tr className="third-block">
            <td colSpan="2">{translate('value_label', true)}</td>
          </tr>
          <tr className="fourth-block">
            <td colSpan="2">
              <div className="copy-btn-wrapper">
                <p className="copy-btn-text">{value}</p>
                <button
                  className="copy-btn"
                  onClick={() => copyToClipboard(value)}
                >
                  <Icon
                    name="copy-complexity-3"
                    color={primary.Dark}
                    size="xnormal"
                  />
                  {translate('copy_label')}
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </Styled.SmallTable>
    )
  }
)
