// @flow
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { translate } from '@editor/common/utils/translations'
import { transformTextVariables } from '@src/editor/common/utils/text-transformation'
import { BoldComp } from '@editor/common/styled-components/simple-tags'

import * as Styled from './styled'
import type { TIntegrationsProps } from './types'
import {
  UPGRADE_BANNER_INFO_LIST,
  ANALYTICS_UPGRADE_IMAGE,
  ANALYTICS_UPGRADE_HOVER_IMAGE,
  MARKETING_INTEGRATIONS_KEYS
} from './consts'
import { UpgradeBanner } from '../shared-components'
import { ConnectCard, ConnectedCard } from './components/Semrush'
import {
  getMarketingIntegrationsMeta,
  reorderMarketingIntegrations
} from './utils'
import {
  isSubscriber,
  selectMarketingSettings,
  selectGDPRCookies,
  selectSemrushLoginInfo
} from '@selectors'
import { fetchSemrushLoggedInStatus } from '@actions'

const SEMRUSHT_CARDS_LABELS = {
  primaryText: translate('semrush_keyword_magic_tool_title'),
  secondaryText: translate('enter_a_topic_and_get_keywords_list')
}

const NOTIFICATION_MESSAGE = transformTextVariables(
  translate('marketing_integration_notification_message'),
  [
    {
      value: translate('get_link_to_invite_contributor_note_tr1'),
      Comp: BoldComp
    }
  ]
)

const Integrations = ({
  marketingIntegrations,
  isSubscriber,
  isGDPRVisible,
  isLoggedInSemrush,
  setChangesState,
  fetchSemrushLoggedInStatus
}: TIntegrationsProps) => {
  const {
    googleAnalyticsId: initialGoogleAnalyticsId,
    googleTagManagerId: initialGoogleTagManagerId,
    googleAdSenseId: initialGoogleAdSenseId,
    authDigitalSellersContent: initialAuthDigitalSellersContent,
    facebookPixelCode: initialFacebookPixelCode,
    yandexMetricaId: initialYandexMetricaId
  } = marketingIntegrations

  const [integrations, setIntegrationsOrder] = useState(
    MARKETING_INTEGRATIONS_KEYS
  )

  const [googleAnalyticsId, setGoogleAnalyticsId] = useState(
    initialGoogleAnalyticsId
  )
  const [googleTagManagerId, setGoogleTagManagerId] = useState(
    initialGoogleTagManagerId
  )
  const [googleAdSenseId, setGoogleAdSenseId] = useState(initialGoogleAdSenseId)
  const [authDigitalSellersContent, setAuthDigitalSellersContent] = useState(
    initialAuthDigitalSellersContent
  )
  const [facebookPixelCode, setFacebookPixelCode] = useState(
    initialFacebookPixelCode
  )

  const [yandexMetricaId, setYandexMetricaId] = useState(initialYandexMetricaId)

  const hasGoogleAnalyticsIdChanges =
    googleAnalyticsId !== initialGoogleAnalyticsId
  const hasGoogleTagMangerIdChanges =
    googleTagManagerId !== initialGoogleTagManagerId
  const hasGoogleAdSenseIdChanges = googleAdSenseId !== initialGoogleAdSenseId
  const hasAuthDigitalSellersContentChange =
    initialAuthDigitalSellersContent !== authDigitalSellersContent
  const hasFacebookPixelCodeChanges =
    facebookPixelCode !== initialFacebookPixelCode
  const hasYandexMetricaIdChanges = yandexMetricaId !== initialYandexMetricaId

  const hasChanges =
    hasGoogleAnalyticsIdChanges ||
    hasGoogleTagMangerIdChanges ||
    hasGoogleAdSenseIdChanges ||
    hasAuthDigitalSellersContentChange ||
    hasFacebookPixelCodeChanges ||
    hasYandexMetricaIdChanges

  const inputsStatesAndSetters = {
    googleAnalyticsId: {
      value: googleAnalyticsId,
      setValue: setGoogleAnalyticsId
    },
    yandexMetricaId: {
      value: yandexMetricaId,
      setValue: setYandexMetricaId
    },
    googleTagManagerId: {
      value: googleTagManagerId,
      setValue: setGoogleTagManagerId
    },
    googleAdSenseId: {
      value: googleAdSenseId,
      setValue: setGoogleAdSenseId
    },
    authDigitalSellersContent: {
      value: authDigitalSellersContent,
      setValue: setAuthDigitalSellersContent
    },
    facebookPixelCode: {
      value: facebookPixelCode,
      setValue: setFacebookPixelCode
    }
  }

  useEffect(() => {
    fetchSemrushLoggedInStatus()
  }, [])

  useEffect(() => {
    setChangesState(hasChanges)
  }, [hasChanges])

  useEffect(() => {
    const reorderedIntegrations = reorderMarketingIntegrations(
      marketingIntegrations
    )

    setIntegrationsOrder(reorderedIntegrations)
  }, [])

  return (
    <Styled.IntegrationsContainer>
      <Styled.IntegrationsWrapper>
        {isLoggedInSemrush ? (
          <ConnectedCard {...SEMRUSHT_CARDS_LABELS} />
        ) : (
          <>
            <ConnectCard {...SEMRUSHT_CARDS_LABELS} />
            <Styled.Line />
          </>
        )}
        {isGDPRVisible ? (
          <Styled.NotificationMessage message={NOTIFICATION_MESSAGE} />
        ) : null}
        {integrations.map(integrationKey => {
          const integration = getMarketingIntegrationsMeta(isSubscriber).find(
            integration => integration.key === integrationKey
          )

          if (integration) {
            const { Comp, key, ...rest } = integration
            return (
              <Comp
                key={integration.key}
                value={inputsStatesAndSetters[key]?.value}
                setValue={inputsStatesAndSetters[key]?.setValue}
                {...rest}
              />
            )
          }
        })}
        {!isSubscriber ? (
          <Styled.UpgradeBannerWrapper>
            <UpgradeBanner
              className="upgrade-banner"
              customTranslationAlignment={120}
              img={ANALYTICS_UPGRADE_IMAGE}
              hoverImg={ANALYTICS_UPGRADE_HOVER_IMAGE}
              info={translate('upgrade_to_add_label')}
              infoList={UPGRADE_BANNER_INFO_LIST}
            />
          </Styled.UpgradeBannerWrapper>
        ) : null}
      </Styled.IntegrationsWrapper>
    </Styled.IntegrationsContainer>
  )
}

const mapStateToProps = state => ({
  marketingIntegrations: selectMarketingSettings(state),
  isSubscriber: isSubscriber(state),
  isGDPRVisible: selectGDPRCookies(state)?.isVisible,
  isLoggedInSemrush: selectSemrushLoginInfo(state).isLoggedIn
})

const mapDispatchToProps = {
  fetchSemrushLoggedInStatus
}

export default connect(mapStateToProps, mapDispatchToProps)(Integrations)
