/* @flow */

import React, { useCallback } from 'react'
import { connect } from 'react-redux'
import { translate } from '@editor/common/utils/translations'

import { selectDomain, selectSiteOnlineStatus } from '@selectors'
import { TDisconnectPopupProps } from './types'
import { disconnectDomain } from '@actions/editor'
import Popup from '@editor/common/lib/Popup'
import { BoldComp } from '@editor/common/styled-components/simple-tags'
import { transformTextVariables } from '@src/editor/common/utils/text-transformation'

import {
  ButtonsWrapper,
  ConfirmPopupContainer,
  ConfirmingPopupContent,
  ConfirmingQuestion,
  InfoText
} from '../../styled'
import * as Styled from './styled'
import Button from '@editor/common/lib/Button'

const DisconnectPopup = ({
  isOpen,
  isSiteOnline,
  onClose,
  domain,
  disconnectDomain
}: TDisconnectPopupProps) => {
  const onDisconnectBtnClick = useCallback(() => {
    disconnectDomain()
    onClose()
  }, [disconnectDomain, onClose])

  return (
    <Popup isOpen={isOpen} onClose={onClose}>
      <ConfirmPopupContainer>
        <ConfirmingPopupContent>
          <ConfirmingQuestion>
            {translate('disconnect_domain_popup_title')}
          </ConfirmingQuestion>
          <InfoText>
            {transformTextVariables(translate('disconnect_domain_popup_text'), [
              {
                value: domain,
                Comp: BoldComp
              }
            ])}
          </InfoText>
          {!isSiteOnline ? (
            <Styled.NotificationMessage
              type="warning"
              message={translate('status_change_warning_message')}
            />
          ) : null}
          <ButtonsWrapper>
            <Button onClick={onClose} type="secondary" className="footer-btn">
              {translate('cancel_label')}
            </Button>
            <Button
              onClick={onDisconnectBtnClick}
              type="primary"
              className="footer-btn"
            >
              {translate('disconnect_label', true)}
            </Button>
          </ButtonsWrapper>
        </ConfirmingPopupContent>
      </ConfirmPopupContainer>
    </Popup>
  )
}

const mapStateToProps = state => ({
  domain: selectDomain(state),
  isSiteOnline: selectSiteOnlineStatus(state)
})

export default connect(mapStateToProps, { disconnectDomain })(DisconnectPopup)
