import styled, { css } from 'styled-components'

import { primary } from '@website/common/styles/colors'

export const Tabs = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  & > div {
    width: 50%;
  }
`

export const CornerImg = styled.img`
  position: absolute;
  bottom: -7px;
  left: 50%;
  z-index: 2;
`

export const ContentWrapper = styled.div`
  position: relative;
  flex-grow: 1;
  background-color: #eaf2ff;
  padding: 5px;
  box-sizing: border-box;
`

export const Content = styled.div`
  background: #ffffff;
  height: 100%;
  border: 1px solid ${primary.Dark};
  box-sizing: border-box;
  overflow-y: scroll;
`

export const SettingsTabsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  ${({ isLeftTabActive }) =>
    isLeftTabActive
      ? css`
          ${CornerImg} {
            transform: translateX(-7px);
          }

          ${ContentWrapper}, ${Content} {
            border-radius: 0 10px 10px 10px;
            overflow: hidden;
          } ;
        `
      : css`
          ${CornerImg} {
            transform: rotate(270deg) translateY(-6px);
          }

          ${ContentWrapper}, ${Content} {
            border-radius: 10px 0 10px 10px;
            overflow: hidden;
          } ;
        `}
`
