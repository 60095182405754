//@flow
import React, { memo, useCallback, useContext } from 'react'
import { translate } from '@editor/common/utils/translations'

import * as Styled from './styled'
import SimpleCookieImg from '../../simple-cookie.svg'
import { Input } from '../../../shared-components'
import { Switcher } from '@renderforest/rf-ui-library/atoms/Switcher'
import { SimpleCookieContext, INITIAL_SIMPLE_COOKIES } from '../../store'
import { CONSENT_TOOLTIP_IMAGE } from './consts'

const Simple = () => {
  const {
    state: {
      isVisible,
      consentText,
      acceptBtn: { name: acceptBtnName }
    },
    actions: {
      setSimpleCookieVisibility,
      setCookieConsentText,
      setAcceptBtnName
    }
  } = useContext(SimpleCookieContext)

  const {
    consentText: defaultConsentText,
    acceptBtn: { name: defaultAcceptBtnName }
  } = INITIAL_SIMPLE_COOKIES

  const toggleSwitcher = useCallback(() => {
    setSimpleCookieVisibility(!isVisible)
  }, [isVisible])

  return (
    <Styled.Container>
      <Styled.Image src={SimpleCookieImg} alt="simple-cookie" />
      <Styled.TitleContainer>
        <Styled.Title
          title={translate('show_cookie_consent_banner')}
          tooltipText={translate('show_cookie_consent_bar_tooltip_text')}
          imgSrc={CONSENT_TOOLTIP_IMAGE}
          hasTooltipContent
        />
        <Switcher checked={isVisible} onClick={toggleSwitcher} size="medium" />
      </Styled.TitleContainer>
      <Styled.Description>
        {translate('simple_consent_description')}
      </Styled.Description>
      <Styled.InputLabel>
        {translate('consent_input_label')} *
      </Styled.InputLabel>
      <Input
        isRequired
        isTextArea
        value={consentText}
        defaultValue={INITIAL_SIMPLE_COOKIES.consentText}
        placeholder={defaultConsentText}
        disabled={!isVisible}
        onChange={setCookieConsentText}
        className="simple-cookie-input"
      />
      <Styled.InputLabel>
        {translate('accept_btn_input_label')} *
      </Styled.InputLabel>
      <Input
        isRequired
        count={19}
        value={acceptBtnName}
        defaultValue={INITIAL_SIMPLE_COOKIES.acceptBtn.name}
        placeholder={defaultAcceptBtnName}
        disabled={!isVisible}
        onChange={setAcceptBtnName}
        className="simple-cookie-input"
      />
    </Styled.Container>
  )
}

export default memo(Simple)
