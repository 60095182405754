//@flow
import React, { memo, useState, useRef, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useDrag, useDrop } from 'react-dnd'

import * as Styled from './styled'
import { ItemLanguageBlock, ItemActionsBlock, Button } from '../styled'
import type { TListItemProps } from './types'
import {
  SetPrimaryButton,
  LanguageVisibilitySwitcher,
  NotificationBanner,
  AutoTranslationSwitcher
} from './components'
import DeleteIcon from '@editor/common/assets/svgr-icons/Delete_icon.svg'
import RestoreIcon from '@editor/common/assets/svgr-icons/restore_icon.svg'
import DragIcon from '@editor/common/assets/svgr-icons/drag_icon.svg'
import TooltipWrapper from '@website/common/components/TooltipWrapper'
import { BoldComp } from '@editor/common/styled-components/simple-tags'
import { getUseDragArgObject, getUseDropArgObject } from './utils'
import { translate } from '@editor/common/utils/translations'
import { TRANSLATING } from '@editor/conf/consts'
import { transformTextVariables } from '@editor/common/utils/text-transformation'
import {
  selectIsAnyLanguageTranslating,
  selectLanguageTranslationState
} from '@selectors'
import { getFlagUrl } from '@website/common/utils/website-languages'

const ListItem = ({
  data,
  idx,
  moveItem,
  isDraggable: isDraggableFromProps,
  isLanguageDeleted,
  isInDragPreview,
  changePrimary,
  changeLanguageVisibility,
  deleteLanguage,
  restoreLanguage,
  autoTranslateLanguage
}: TListItemProps) => {
  const {
    lang: { name, flagCode, isAutoTranslateSupported, code },
    status
  } = data

  const [languageStatus, setLanguageStatus] = useState(status)
  const [isAutoTranslationEnabled, setIsAutoTranslationEnabled] = useState(true)
  const ref = useRef(null)

  const isLanguageVisible = languageStatus === 'active'

  const languageTranslationState = useSelector(state =>
    selectLanguageTranslationState(state, code)
  )

  const isAnyLanguageTranslating = useSelector(selectIsAnyLanguageTranslating)
  const isDraggable = isDraggableFromProps && !isAnyLanguageTranslating

  const [{ isDragging }, drag] = useDrag({
    ...getUseDragArgObject(name, idx),
    canDrag: isDraggable
  })

  const [{ handlerId, isDropped }, drop] = useDrop(
    getUseDropArgObject(ref, idx, moveItem)
  )

  drag(drop(ref))

  const preventDrag = useCallback(e => {
    e.preventDefault()
    e.stopPropagation()
  }, [])

  const isActionItemDisabled = !!(
    languageTranslationState && languageTranslationState === TRANSLATING
  )

  const onDeleteBtnClick = () => {
    if (isActionItemDisabled) return

    deleteLanguage && deleteLanguage(name)()
  }

  return (
    <Styled.ListItemWrapper>
      <Styled.ListItem
        ref={ref}
        data-handler-id={handlerId}
        isDragging={isDragging}
        isDraggable={isDraggable}
        isDropped={isDropped}
        isLanguageVisible={isLanguageVisible}
        isLanguageDeleted={isLanguageDeleted}
      >
        {isDraggable ? (
          <TooltipWrapper
            text={isDropped ? translate('drag_to_move_label') : ''}
            className="drag-icon-tooltip"
          >
            <DragIcon />
          </TooltipWrapper>
        ) : null}
        <ItemLanguageBlock hasOpacity={!isLanguageVisible || isLanguageDeleted}>
          <img src={getFlagUrl(flagCode)} alt={name} />
          <p>{name}</p>
        </ItemLanguageBlock>
        <ItemActionsBlock
          draggable={isDraggable}
          onDragStart={preventDrag}
          onTouchStart={preventDrag}
        >
          {isLanguageDeleted ? (
            <Button onClick={restoreLanguage && restoreLanguage(name)}>
              <RestoreIcon />
              <span>{translate('restore_label')}</span>
            </Button>
          ) : (
            <>
              <AutoTranslationSwitcher
                language={name}
                languageCode={code}
                isNewAddedLanguage={data.added}
                isEnabled={isAutoTranslationEnabled}
                isAutoTranslateSupported={isAutoTranslateSupported}
                setIsAutoTranslationEnabled={setIsAutoTranslationEnabled}
                autoTranslateLanguage={
                  autoTranslateLanguage && autoTranslateLanguage(idx)
                }
              />
              {isLanguageVisible ? (
                <>
                  <SetPrimaryButton
                    language={name}
                    isDisabled={isActionItemDisabled}
                    onConfirm={changePrimary && changePrimary(idx)}
                  />
                  <Styled.Divider />
                </>
              ) : null}
              <LanguageVisibilitySwitcher
                isLanguageVisible={isLanguageVisible}
                isDisabled={isActionItemDisabled}
                setLanguageStatus={setLanguageStatus}
                changeLanguageVisibility={
                  changeLanguageVisibility && changeLanguageVisibility(idx)
                }
              />
              <Styled.Divider />
              <Button
                onClick={onDeleteBtnClick}
                disabled={isActionItemDisabled}
              >
                <DeleteIcon />
                <span>{translate('delete_label')}</span>
              </Button>
            </>
          )}
        </ItemActionsBlock>
      </Styled.ListItem>
      {!isInDragPreview ? (
        <NotificationBanner
          text={transformTextVariables(translate('hidden_language_message'), [
            {
              value: name,
              Comp: BoldComp
            }
          ])}
          isVisible={!isLanguageVisible}
        />
      ) : null}
    </Styled.ListItemWrapper>
  )
}

export default memo(ListItem)
