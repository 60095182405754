//@flow
import React, { memo, useCallback, useContext } from 'react'
import * as Styled from '../common-styled'
import { GDPRCookieContext, INITIAL_GDPR_SETTINGS } from '../../../store'
import { GDPR_BUTTONS_INPUT_CLASSNAME } from '../consts'
import { translate } from '@editor/common/utils/translations'

const checkboxItem = {
  label: translate('decline_btn_checkbox_label')
}

const DeclineBtn = () => {
  const {
    state: {
      declineBtn: { name: declineBtnName, isVisible }
    },
    actions: { setDeclineBtnName, setDeclineBtnVisibility }
  } = useContext(GDPRCookieContext)

  const onCheckboxChange = useCallback(() => {
    setDeclineBtnVisibility(!isVisible)
  }, [isVisible])

  return (
    <Styled.Container>
      <Styled.Label>{translate('decline_btn_description')}</Styled.Label>
      <Styled.Checkbox
        isChecked={isVisible}
        className="gdpr-decline-checkbox"
        onChange={onCheckboxChange}
        item={checkboxItem}
      />
      <Styled.InputLabel>{translate('decline_btn_label')}</Styled.InputLabel>
      <Styled.Input
        isRequired
        count={19}
        defaultValue={INITIAL_GDPR_SETTINGS.declineBtn.name}
        className={GDPR_BUTTONS_INPUT_CLASSNAME}
        value={declineBtnName}
        disabled={!isVisible}
        onChange={setDeclineBtnName}
      />
    </Styled.Container>
  )
}

export default memo(DeclineBtn)
