//@flow
import React, { memo, useContext } from 'react'
import { translate } from '@editor/common/utils/translations'

import * as Styled from '../common-styled'
import { GDPR_BUTTONS_INPUT_CLASSNAME } from '../consts'
import { GDPRCookieContext, INITIAL_GDPR_SETTINGS } from '../../../store'

const AcceptBtn = () => {
  const {
    state: { acceptBtn },
    actions: { setAcceptBtnName }
  } = useContext(GDPRCookieContext)

  return (
    <Styled.Container>
      <Styled.Label>{translate('accept_btn_description')}</Styled.Label>
      <Styled.InputLabel>{translate('accept_btn_label')}</Styled.InputLabel>
      <Styled.Input
        isRequired
        count={19}
        defaultValue={INITIAL_GDPR_SETTINGS.acceptBtn.name}
        className={GDPR_BUTTONS_INPUT_CLASSNAME}
        onChange={setAcceptBtnName}
        value={acceptBtn.name}
      />
    </Styled.Container>
  )
}

export default memo(AcceptBtn)
