import styled from 'styled-components'
import { secondary } from '@website/common/styles/colors'

export const SSLInfoTextWrapper = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: ${secondary.Dark};
  padding-top: 30px;
  margin: 0 auto;
  max-width: 390px;
`

export const SSLInfoText = styled.span`
  font-size: 16px;
  color: ${secondary.Dark};
  font-weight: 400;
  margin: 0 5px;
`
