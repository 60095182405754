import styled from 'styled-components'

import { secondary } from '@website/common/styles/colors'
import { styleByLang } from '@editor/common/utils/style-by-lang'

const cardCustomStyle = {
  titleFontSize: '14px'
}

const stylesObj = {
  ru: cardCustomStyle,
  default: {
    titleFontSize: '16px'
  }
}
const getStylesByLang = styleByLang(stylesObj)

export const AccordionTitleWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 18px;
  box-sizing: border-box;

  p {
    @media only screen and (max-width: 768px) {
      font-size: ${getStylesByLang('titleFontSize')};
    }
    font-size: 16px;
    font-weight: 600;
    color: ${secondary.Dark};
  }
`

export const UpgradeLabel = styled.img`
  position: absolute;
  right: 20px;
  top: -7px;
`
