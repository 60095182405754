import React from 'react'
import { GlobalStyles } from './styled'

const Animation = () => (
  <>
    <GlobalStyles />
    <div className="animation-wrapper animate">
      <div className="circle">
        <div className="check"></div>
      </div>
      <img
        className="icon icon-1"
        src="https://static.rfstat.com/renderforest/images/v2/thank-you-pages/ribbon-green.svg"
        alt=""
      />
      <img
        className="icon icon-2"
        src="https://static.rfstat.com/renderforest/images/v2/thank-you-pages/triangle-purple-2.svg"
        alt=""
      />
      <img
        className="icon icon-3"
        src="https://static.rfstat.com/renderforest/images/v2/thank-you-pages/triangle-red-1.svg"
        alt=""
      />
      <img
        className="icon icon-4"
        src="https://static.rfstat.com/renderforest/images/v2/thank-you-pages/circle-yellow.svg"
        alt=""
      />
      <img
        className="icon icon-5"
        src="https://static.rfstat.com/renderforest/images/v2/thank-you-pages/circle-green.svg"
        alt=""
      />
      <img
        className="icon icon-6"
        src="https://static.rfstat.com/renderforest/images/v2/thank-you-pages/circle-yellow.svg"
        alt=""
      />
      <img
        className="icon icon-7"
        src="https://static.rfstat.com/renderforest/images/v2/thank-you-pages/triangle-purple-1.svg"
        alt=""
      />
      <img
        className="icon icon-8"
        src="https://static.rfstat.com/renderforest/images/v2/thank-you-pages/ribbon-red.svg"
        alt=""
      />
      <img
        className="icon icon-9"
        src="https://static.rfstat.com/renderforest/images/v2/thank-you-pages/triangle-red-1.svg"
        alt=""
      />
      <img
        className="icon icon-10"
        src="https://static.rfstat.com/renderforest/images/v2/thank-you-pages/circle-yellow.svg"
        alt=""
      />
      <img
        className="icon icon-11"
        src="https://static.rfstat.com/renderforest/images/v2/thank-you-pages/circle-green.svg"
        alt=""
      />
      <img
        className="icon icon-12"
        src="https://static.rfstat.com/renderforest/images/v2/thank-you-pages/ribbon-purple.svg"
        alt=""
      />
      <img
        className="icon icon-13"
        src="https://static.rfstat.com/renderforest/images/v2/thank-you-pages/circle-purple.svg"
        alt=""
      />
      <img
        className="icon icon-14"
        src="https://static.rfstat.com/renderforest/images/v2/thank-you-pages/circle-yellow.svg"
        alt=""
      />
      <img
        className="icon icon-15"
        src="https://static.rfstat.com/renderforest/images/v2/thank-you-pages/triangle-red-3.svg"
        alt=""
      />
      <img
        className="icon icon-16"
        src="https://static.rfstat.com/renderforest/images/v2/thank-you-pages/circle-yellow.svg"
        alt=""
      />
      <img
        className="icon icon-17"
        src="https://static.rfstat.com/renderforest/images/v2/thank-you-pages/circle-green.svg"
        alt=""
      />
      <img
        className="icon icon-18"
        src="https://static.rfstat.com/renderforest/images/v2/thank-you-pages/ribbon-yellow.svg"
        alt=""
      />
      <img
        className="icon icon-19"
        src="https://static.rfstat.com/renderforest/images/v2/thank-you-pages/triangle-red-2.svg"
        alt=""
      />
      <img
        className="icon icon-20"
        src="https://static.rfstat.com/renderforest/images/v2/thank-you-pages/circle-yellow.svg"
        alt=""
      />
      <img
        className="icon icon-21"
        src="https://static.rfstat.com/renderforest/images/v2/thank-you-pages/triangle-purple-1.svg"
        alt=""
      />
      <img
        className="icon icon-22"
        src="https://static.rfstat.com/renderforest/images/v2/thank-you-pages/circle-green.svg"
        alt=""
      />
    </div>
  </>
)

export default Animation
