// @flow

import React, { useCallback, useMemo, useState } from 'react'
import { connect } from 'react-redux'

import * as Styled from './styled'
import type { TSSLCertificateProps } from './types'
import { FOUR_HOURS, LINK_TO_SSL_DOC, SSL_LIMIT_TOOLTIP_TEXT } from './consts'
import { FOUR_HOURS_IN_MS } from '../../consts'
import {
  accentGreen,
  accentRed,
  primary,
  secondary
} from '@website/common/styles/colors'
import { SSLPopup } from '../../Popups'
import { Message } from '../../../../components'
import Switcher from '@renderforest/rf-ui-library/atoms/Switcher'
import { TooltipWrapper } from '@website/common/components/TooltipWrapper'
import InfoIcon from '@editor/common/assets/svgr-icons/Info_icon.svg'
import { getSSLDatesDiffInMs, msToHours } from './utils'
import {
  replaceTextWithLink,
  transformTextVariables,
  replaceVariableWithStyles
} from '@src/editor/common/utils/text-transformation'
import {
  selectDomain,
  selectDomainCertifiedStatus,
  selectDomainConfiguredStatus,
  selectLastTimeSslOnDate,
  selectSSLErrorMessage,
  selectSSLSuccessMessage
} from '@selectors'
import { configureHttps } from '@actions/editor'
import { useResponsive } from '@shared/hooks'
import { translate } from '@editor/common/utils/translations'
import {
  LinkComp,
  BoldComp
} from '@editor/common/styled-components/simple-tags'

const sslSertificateText = transformTextVariables(
  translate('learn_more_about_ssl_cert_label'),
  [
    {
      value: 'SSL (HTTPS)',
      Comp: LinkComp,
      props: {
        href: LINK_TO_SSL_DOC
      }
    }
  ]
)

const SSLCertificate = ({
  domain,
  isDomainConfigured,
  certifiedStatus,
  lastTimeSslOnDate,
  sslSuccessMessage,
  sslErrorMessage,
  configureHttps
}: TSSLCertificateProps) => {
  const [isSSLPopupOpen, setSSLPopupState] = useState(false)
  const [isAnimationActive, setAnimationState] = useState(false)

  const isSmall = useResponsive(919)
  const isSmallMobile = useResponsive(400)
  const isTooltipWithClick = useResponsive(769)

  const isSSLEnabled = domain && isDomainConfigured
  const sslDatesDiffInMs = getSSLDatesDiffInMs(lastTimeSslOnDate)
  const sslDatesDiffInHours = msToHours(sslDatesDiffInMs)
  const showSwitcherWithSslLimitTooltip =
    isSSLEnabled &&
    certifiedStatus === false &&
    sslDatesDiffInMs > 0 &&
    sslDatesDiffInMs < FOUR_HOURS_IN_MS

  const sslHours = useMemo(() => {
    if (FOUR_HOURS - sslDatesDiffInHours === 1) {
      return `1 hour`
    }
    return `${FOUR_HOURS - sslDatesDiffInHours} hours`
  }, [sslDatesDiffInHours])

  const onSwitcherClick = useCallback(() => {
    if (certifiedStatus) {
      setSSLPopupState(true)
    } else {
      configureHttps()
    }
  }, [certifiedStatus, configureHttps])

  const onDisabledSwitcherClick = useCallback(() => {
    setAnimationState(true)
    setTimeout(() => setAnimationState(false), 500)
  }, [])

  const closeSSLPopup = useCallback(() => {
    setSSLPopupState(false)
  }, [])

  return (
    <>
      <Styled.SSLContainer>
        <Styled.SwitcherWrapper
          isAnimationActive={isAnimationActive}
          isSSLEnabled={certifiedStatus}
        >
          <Styled.SSLText>{translate('free_ssl_cert_label')}</Styled.SSLText>
          {showSwitcherWithSslLimitTooltip ? (
            <TooltipWrapper
              className="tooltip"
              position="right"
              text={transformTextVariables(
                translate('ssl_limit_tooltip_text'),
                [
                  {
                    value: sslHours,
                    Comp: BoldComp
                  }
                ]
              )}
              color={secondary.Dark}
              withClick={isTooltipWithClick}
            >
              <Switcher
                onClick={onDisabledSwitcherClick}
                size="medium"
                type="primary"
              />
            </TooltipWrapper>
          ) : !isSSLEnabled ? (
            <TooltipWrapper
              className="tooltip"
              position="right"
              text={translate('free_ssl_cert_disable_switcher_label')}
              color={secondary.Dark}
            >
              <Switcher
                onClick={onDisabledSwitcherClick}
                size="medium"
                type="primary"
              />
            </TooltipWrapper>
          ) : (
            <Switcher
              onClick={onSwitcherClick}
              checked={certifiedStatus}
              size="medium"
              type="primary"
            />
          )}
        </Styled.SwitcherWrapper>
        {sslSuccessMessage && (
          <Message
            message={sslSuccessMessage}
            iconName="rounded_check"
            iconColor="green"
            textColor={accentGreen}
          />
        )}
        {sslErrorMessage && (
          <Message
            message={sslErrorMessage}
            iconName="warning"
            iconColor="red"
            textColor={accentRed}
          />
        )}
        <Styled.LearnMoreWrapper>
          <Styled.LearnMore>
            {/* {replaceTextWithLink(
              'Learn more about %s certificate connection.',
              'SSL (HTTPS)',
              LINK_TO_SSL_DOC,
              { color: primary.Dark },
              true
            )} */}
            {sslSertificateText}
          </Styled.LearnMore>
          {!isSmall && (
            <TooltipWrapper
              className="tooltip"
              position="right"
              text={translate('free_ssl_cert_tooltip_text')}
              color={secondary.Dark}
            >
              <InfoIcon className="info-icon" />
            </TooltipWrapper>
          )}
        </Styled.LearnMoreWrapper>
      </Styled.SSLContainer>
      <SSLPopup
        isOpen={isSSLPopupOpen}
        onClose={closeSSLPopup}
        sslHours={sslHours}
        sslDatesDiffInMs={sslDatesDiffInMs}
      />
    </>
  )
}

const mapStateToProps = state => ({
  domain: selectDomain(state),
  sslErrorMessage: selectSSLErrorMessage(state),
  sslSuccessMessage: selectSSLSuccessMessage(state),
  certifiedStatus: selectDomainCertifiedStatus(state),
  isDomainConfigured: selectDomainConfiguredStatus(state),
  lastTimeSslOnDate: selectLastTimeSslOnDate(state)
})

export default connect(mapStateToProps, { configureHttps })(SSLCertificate)
