import { translate } from '@editor/common/utils/translations'
import { transformTextVariables } from '@editor/common/utils/text-transformation'
import { BoldComp } from '@editor/common/styled-components/simple-tags'

export const getWarningPopupProps = areAllLanguagesDeleted => ({
  isOpen: true,
  isWarning: true,
  title: translate('are_you_sure_save_changes_label'),
  content: transformTextVariables(
    translate(
      `${
        areAllLanguagesDeleted
          ? 'deleted_last_language_message'
          : 'deleted_language_message'
      }`
    ),
    [
      {
        value: translate(
          `${
            areAllLanguagesDeleted
              ? 'deleted_last_language_message_tr1'
              : 'deleted_language_message_tr1'
          }`
        ),
        Comp: BoldComp
      }
    ]
  ),
  buttonTexts: {
    confirm: translate('save_label'),
    cancel: translate('cancel_label')
  }
})

export const addOrdersToSecondaryLanguages = languages => {
  const startingOrder = 2

  return languages.map((lang, idx) => ({
    ...lang,
    order: startingOrder + idx
  }))
}
