/* @flow */
import React, { Suspense, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'

import * as Styled from './styled'
import If from '@website/common/components/Conditional'
import { isDomainPageOpen, getSiteId } from '@redux/selectors'
import { closeDomainPage } from '@redux/actions/editor'
import { PAGE_BASE } from '@editor/conf/routes'

const MyDomainCore = React.lazy(() =>
  import(/* webpackChunkName: "my-domain"*/ '@renderforest/domains-spa')
)

const mapDispatchToProps = {
  closeDomainPage
}

const mapStateToProps = (state: TAppState) => ({
  isDomainPageOpen: isDomainPageOpen(state),
  siteId: getSiteId(state)
})

const MyDomain = ({ closeDomainPage, isDomainPageOpen, siteId }) => {
  const { url } = useRouteMatch(`${PAGE_BASE}/settings/domain`)
  const { CURRENT_LANGUAGE, location } = window
  const language =
    CURRENT_LANGUAGE === 'en' && !location.pathname.includes('/en')
      ? ''
      : `/${CURRENT_LANGUAGE}`

  useEffect(() => {
    window.SITE_ID = siteId
  }, [siteId])

  return (
    <If
      condition={isDomainPageOpen}
      then={() => {
        return ReactDOM.createPortal(
          <Styled.MyDomainWrapper>
            <Suspense fallback={null}>
              <MyDomainCore
                onClose={closeDomainPage}
                routeBase={`${language}/website-maker${url}/`}
              />
            </Suspense>
          </Styled.MyDomainWrapper>,
          document.body
        )
      }}
    />
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(MyDomain)
