// @flow
import React from 'react'
import { connect } from 'react-redux'

import type { TFacebookPixelProps } from './types'
import Card from '../Card'
import {
  selectFacebookPixelCode,
  selectFacebookPixelSuccessMessage
} from '@selectors'
import { setFacebookPixelCode } from '@actions/meta'

const FacebookPixel = ({
  value,
  setValue,
  facebookPixelCode,
  setFacebookPixelCode,
  successMessage,
  ...rest
}: TFacebookPixelProps) => {
  const hasChanges = value !== facebookPixelCode
  const isConnected = !!facebookPixelCode

  return (
    <Card
      inputValue={value}
      isConnected={isConnected}
      hasChanges={hasChanges}
      successMessage={successMessage}
      setId={setFacebookPixelCode}
      setInputValue={setValue}
      {...rest}
    />
  )
}

const mapStateToProps = state => ({
  facebookPixelCode: selectFacebookPixelCode(state),
  successMessage: selectFacebookPixelSuccessMessage(state)
})

const mapDispatchToProps = { setFacebookPixelCode }

export default connect(mapStateToProps, mapDispatchToProps)(FacebookPixel)
