import styled from 'styled-components'

import SemrushDependedPopup from '@editor/common/components/SemrushDependedPopup'
import LibButton from '@renderforest/rf-ui-library/atoms/Button'
import { globalColors } from '@renderforest/rf-ui-library/consts/colors'
const TEXT_COLOR = globalColors.secondaryDark

export const CongratulationPopup = styled(SemrushDependedPopup)`
  & > div {
    padding: 0;
    max-height: 96%;
    max-width: 458px;
    & > div {
      padding: 0;
    }
  }
  @media only screen and (max-width: 450px) {
    height: 100%;
    max-height: 100%;
    & > div {
      height: 100%;
      max-height: 100%;
      width: 100%;
      max-width: 100%;
      border-radius: 0;
      padding: 30px 20px 0;
    }
  }
`

export const PopupContent = styled.div`
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  @media only screen and (max-width: 767px) {
    padding: 30px;
  }
`

export const AnimationHeader = styled.div`
  padding-bottom: 40px;
  display: flex;
  justify-content: center;
`

export const Heading = styled.h1`
  color: ${TEXT_COLOR};
  font-size: 32px;
`

export const MainInfoText = styled.p`
  max-width: 290px;
  font-size: 18px;
  margin-top: 40px;
  font-weight: 600;
  color: ${TEXT_COLOR};
  text-align: center;
`

export const SecondaryInfoText = styled.p`
  font-size: 16px;
  margin-top: 20px;
  color: ${TEXT_COLOR};
  text-align: center;
  max-width: 350px;
  line-height: 20px;
`

export const Button = styled(LibButton)`
  margin: 40px 0 10px;
  padding: 15px 40px;
`

export const TextBold = styled.span`
  font-weight: 600;
`
