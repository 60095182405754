// @flow

import React, { useCallback } from 'react'
import { connect } from 'react-redux'
import { translate } from '@editor/common/utils/translations'
import { BoldComp } from '@editor/common/styled-components/simple-tags'

import { TProps } from './types'
import * as Styled from './styled'
import CongratsAnimation from '@editor/common/components/CongratsAnimation'
import { publishProject } from '@actions/project'
import { selectDomainConfiguredStatus } from '@selectors'
import { transformTextVariables } from '@src/editor/common/utils/text-transformation'

const CongratulationPopup = ({
  isOpen,
  onClose,
  isDomainConfigured,
  publishProject
}: TProps) => {
  const onBtnClick = useCallback(() => {
    if (isDomainConfigured) {
      publishProject()
    }

    onClose()
  }, [publishProject, onClose, isDomainConfigured])

  return (
    <Styled.CongratulationPopup isOpen={isOpen} onClose={onClose}>
      <Styled.PopupContent>
        <Styled.AnimationHeader>
          <CongratsAnimation />
        </Styled.AnimationHeader>
        <Styled.Heading>{translate('congratulations_label')}</Styled.Heading>
        <Styled.MainInfoText>
          {translate('domain_connect_text')}
        </Styled.MainInfoText>
        <Styled.SecondaryInfoText>
          {isDomainConfigured
            ? translate('domain_congrats_after_connection_label')
            : transformTextVariables(translate('domain_connect_description'), [
                {
                  value: `48 ${translate('hours')}`,
                  Comp: BoldComp
                }
              ])}
        </Styled.SecondaryInfoText>
        <Styled.Button size="small" type="info" onClick={onBtnClick}>
          {isDomainConfigured
            ? translate('publish_label', true)
            : translate('ok_got_it_label', true)}
        </Styled.Button>
      </Styled.PopupContent>
    </Styled.CongratulationPopup>
  )
}

const mapStateToProps = state => ({
  isDomainConfigured: selectDomainConfiguredStatus(state)
})

export default connect(mapStateToProps, { publishProject })(CongratulationPopup)
