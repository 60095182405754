// @flow
import React, { memo } from 'react'

import * as Styled from './styled'
import { PleaseNote } from '../../styled'
import type { TMessageProps } from './types'

const Message = ({
  message,
  textColor,
  iconName,
  iconColor,
  isWarning
}: TMessageProps) => (
  <Styled.MessageContainer isWarning={isWarning}>
    <Styled.MessageIcon
      isWarning={isWarning}
      color={iconColor}
      name={iconName}
      size="small"
    />
    <Styled.MessageWrapper>
      {isWarning && <PleaseNote>Please note: </PleaseNote>}
      <Styled.Message isWarning={isWarning} textColor={textColor}>
        {message}
      </Styled.Message>
    </Styled.MessageWrapper>
  </Styled.MessageContainer>
)

export default memo(Message)
