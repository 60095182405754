// @flow

import React, { memo, useContext } from 'react'

import type { TCustomizationWindowProps } from './types'
import { MobilePopup } from '@editor/common/lib/Popup'
import SlideLeft from '@editor/common/lib/SlideLeft'
import { MobileDetectContext } from '@contexts'

const CustomizationWindow = ({
  isOpen,
  children,
  onClose,
  mobileProps,
  desktopProps
}: TCustomizationWindowProps) => {
  const isMobile = useContext(MobileDetectContext)
  const commonProps = { isOpen, onClose }

  return isMobile ? (
    <MobilePopup {...commonProps} {...mobileProps}>
      {children}
    </MobilePopup>
  ) : (
    <SlideLeft {...commonProps} {...desktopProps}>
      {children}
    </SlideLeft>
  )
}

export default memo(CustomizationWindow)
