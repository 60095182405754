import styled from 'styled-components'
import { primary } from '@website/common/styles/colors'
import Icon from '@website/common/components/Icon'

export const ViewMyWebsite = styled.div`
  width: 100%;
  border-top: ${({ isInSubdomain }) =>
    isInSubdomain ? '1px solid #e2ecff' : 'none'};
  padding: ${({ isInSubdomain }) => (isInSubdomain ? '20px 30px' : '40px 0 0')};
  box-sizing: border-box;
  @media only screen and (max-width: 889px) {
    padding: ${({ isInSubdomain }) =>
      isInSubdomain ? '20px 30px' : '0 0 10px'};
  }
`

export const ViewMyWebsiteBtn = styled.button`
  display: flex;
  align-items: center;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0;
  &:hover {
    & > p {
      text-decoration: underline;
      text-decoration-color: ${primary.Dark};
    }
  }
  @media only screen and (max-width: 889px) {
    margin: 0 auto;
  }
`

export const BtnText = styled.p`
  font-size: 12px;
  color: ${primary.Dark};
  font-weight: 500;
`

export const BtnIcon = styled(Icon)`
  margin-left: 5px;
`
