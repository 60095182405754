/* @flow */
import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react'
import { connect } from 'react-redux'
import { translate } from '@editor/common/utils/translations'

import * as Styled from './styled'
import type { TCustomDomainContentProps } from './types'
import {
  secondary,
  accentRed,
  warningColor
} from '@website/common/styles/colors'
import { addAnalyticsEvent } from '@editor/common/utils'
import { BoldComp } from '@editor/common/styled-components/simple-tags'

import {
  CUSTOM_DOMAIN_IMG,
  GET_NOW_CARD_IMAGE,
  GET_NOW_CARD_IMAGE_HOVER
} from '../../consts'
import { ViewMyWebsite, Message } from '../index'
import { SSLCertificate, DNSDetails } from './components'
import { UpgradeBanner } from '../../../shared-components'
import { MyDomainsPopup, DisconnectPopup, CongratulationPopup } from './Popups'
import {
  connectDomain,
  openDomainPage,
  setSearchQuery,
  removeHttpsConfigs,
  setOwnDomainErrorMessage,
  closeCongratulationPopup,
  getDomainConfiguredStatus
} from '@actions/editor'
import {
  getSiteData,
  selectDomain,
  selectDomains,
  selectSitePublishedState,
  selectConfiguredMessage,
  selectIsCongratsPopupOpen,
  selectOwnDomainErrorMessage,
  selectDomainConfiguredStatus,
  selectDomainCertifiedStatus
} from '@selectors'
import { useResponsive } from '@shared/hooks'
import ResizeObserver from '@website/common/utils/ResizeObserver'
import {
  transformTextVariables,
  replaceValueInText
} from '@src/editor/common/utils/text-transformation'

const CUSTOM_DOMAIN_CONTENT_TEXT = transformTextVariables(
  translate('enter_external_domain_or_choose_text'),
  [
    {
      value: translate('page_settings_note_label_tr1'),
      Comp: BoldComp
    },
    {
      value: `48 ${translate('hours')}`,
      Comp: BoldComp
    }
  ]
)

const CustomDomainContent = ({
  domain,
  domains,
  value,
  setValue,
  isRfDomain,
  isPublished,
  errorMessage,
  heightHandler,
  connectDomain,
  openDomainPage,
  certifiedStatus,
  configuredMessage,
  isDomainConfigured,
  isCongratsPopupOpen,
  closeCongratulationPopup,
  setOwnDomainErrorMessage,
  getDomainConfiguredStatus
}: TCustomDomainContentProps) => {
  const [isDisconnectPopupOpen, setDisconnectPopupState] = useState(false)
  const [isMyDomainsPopupOpen, setMyDomainsPopupState] = useState(false)
  const containerRef = useRef(null)
  const observerRef = useRef(null)
  const isSmallSizes = useResponsive(889)

  const isDomainChanged = value !== domain
  const isMyDomain = domains.find(domain => domain.name === value)
  const showDNSDetails =
    !isMyDomain || (!isMyDomain && domain && !isDomainConfigured)
  const showSuccessIcon = domain && !isDomainChanged && isDomainConfigured

  const setHeight = useCallback(() => {
    heightHandler && heightHandler()
  }, [heightHandler])

  useEffect(() => {
    getDomainConfiguredStatus()

    return () => {
      setValue(null)
    }
  }, [])

  useEffect(() => {
    setValue(domain)
  }, [domain])

  useEffect(() => {
    observerRef.current = new ResizeObserver(setHeight)
  }, [setHeight])

  useEffect(() => {
    observerRef.current.observe(containerRef.current)
    return () => {
      observerRef.current.disconnect()
    }
  }, [containerRef.current, observerRef.current])

  const openMyDomainsPopup = useCallback(() => {
    setMyDomainsPopupState(true)
  }, [])

  const closeMyDomainsPopup = useCallback(() => {
    setMyDomainsPopupState(false)
  }, [])

  const closeDisconnectPopup = useCallback(() => {
    setDisconnectPopupState(false)
  }, [])

  const connect = useCallback(() => {
    if (!value || !isDomainChanged) {
      return
    }

    connectDomain(value)
  }, [isDomainChanged, value, connectDomain])

  const disconnect = useCallback(() => {
    setDisconnectPopupState(true)
  }, [])

  const handleUpgradeBannerClick = useCallback(() => {
    addAnalyticsEvent(
      'SiteMaker Domains',
      'get_now_button_click',
      'location: Custom Domain Settings'
    )
    openDomainPage()
  }, [openDomainPage])

  const btnProps = useMemo(() => {
    if (!domain) {
      return {
        text: translate('connect_label'),
        action: connect
      }
    } else {
      return {
        text: translate('disconnect_label'),
        action: disconnect
      }
    }
  }, [domain, connect, disconnect])

  const onInputChange = useCallback(
    e => {
      if (errorMessage) {
        setOwnDomainErrorMessage('')
      }

      setValue(e.target.value)
    },
    [errorMessage, setOwnDomainErrorMessage, setValue]
  )

  const onKeyPress = useCallback(
    e => {
      if (e.key === 'Enter') {
        btnProps.action()
      }
    },
    [btnProps]
  )

  const siteUrl = `${certifiedStatus ? 'https://' : 'http://'}${domain}`

  const message = useMemo(() => {
    if (errorMessage) {
      return (
        <Message
          message={errorMessage}
          iconName="warning"
          iconColor="red"
          textColor={accentRed}
        />
      )
    }
    if (configuredMessage) {
      return (
        <Message
          isWarning={true}
          message={replaceValueInText(
            configuredMessage,
            '48 hours',
            '48 hours',
            Styled.BoldText
          )}
          iconName="warning"
          iconColor={warningColor}
          textColor={secondary.SemiDark}
        />
      )
    }
  }, [errorMessage, configuredMessage])

  return (
    <Styled.CustomDomainContent ref={containerRef}>
      {isSmallSizes && (
        <Styled.Img src={CUSTOM_DOMAIN_IMG} alt="custom-domain" />
      )}
      <Styled.CustomDomainMainContent>
        <Styled.ContentTitleWrapper>
          {domain
            ? translate('change_your_domain_here_label')
            : translate('enter_external_domain_or_choose_label')}
          {!domain && (
            <Styled.MyDomains onClick={openMyDomainsPopup}>
              {translate('my_domains_label')}
            </Styled.MyDomains>
          )}
        </Styled.ContentTitleWrapper>
        {domain ? (
          <Styled.CustomDomainContentTextWrapper>
            <Styled.CustomDomainContentText>
              {translate('disconnect_domain_to_change')}
            </Styled.CustomDomainContentText>
          </Styled.CustomDomainContentTextWrapper>
        ) : (
          <Styled.CustomDomainContentTextWrapper>
            <Styled.CustomDomainContentText>
              {CUSTOM_DOMAIN_CONTENT_TEXT}
            </Styled.CustomDomainContentText>
          </Styled.CustomDomainContentTextWrapper>
        )}
        <Styled.CustomDomainForm>
          <Styled.CustomDomainInputWrapper>
            <Styled.HttpText>
              {certifiedStatus ? 'https://' : 'http://'}
            </Styled.HttpText>
            <Styled.CustomDomainInput
              showErrorBorder={!!errorMessage}
              showWarningBorder={!!configuredMessage}
              onChange={onInputChange}
              placeholder="yourdomain.com"
              type="text"
              value={value}
              inputProps={{
                onKeyPress
              }}
              disabled={domain}
            />
            {showSuccessIcon && (
              <Styled.SuccessIcon
                name="rounded_check"
                color="green"
                size="small"
              />
            )}
          </Styled.CustomDomainInputWrapper>
          {isSmallSizes && (
            <Styled.MessageWrapper>{message}</Styled.MessageWrapper>
          )}
          <Styled.ConnectBtn disabled={!value} onClick={btnProps.action}>
            {btnProps.text}
          </Styled.ConnectBtn>
        </Styled.CustomDomainForm>
        {!isSmallSizes && message}
        {isPublished && domain && <ViewMyWebsite siteUrl={siteUrl} />}
        {domain && isRfDomain ? (
          <Styled.WWWOptionDescription>
            <Styled.WWWOptionsTitle>
              {translate('want_to_creat_subdomain_label')}
            </Styled.WWWOptionsTitle>
            <Styled.WWWOptionsDescription>
              {transformTextVariables(
                translate('to_redirect_from_your_root_domain_label'),
                [
                  {
                    value: 'www.domain.com',
                    Comp: BoldComp
                  },
                  {
                    value: 'domain.com',
                    Comp: BoldComp
                  }
                ]
              )}
            </Styled.WWWOptionsDescription>
          </Styled.WWWOptionDescription>
        ) : null}
        <SSLCertificate />
        {showDNSDetails && <DNSDetails />}
        <UpgradeBanner
          className="domain-upgrade-banner"
          img={GET_NOW_CARD_IMAGE}
          hoverImg={GET_NOW_CARD_IMAGE_HOVER}
          customTranslationAlignment={115}
          info={translate('want_to_get_custom_domain_label')}
          btnText={translate('get_it_now_label')}
          isBtnIconHidden
          isDescriptionHidden
          onClick={handleUpgradeBannerClick}
        />
      </Styled.CustomDomainMainContent>
      <MyDomainsPopup
        isOpen={isMyDomainsPopupOpen}
        onClose={closeMyDomainsPopup}
        domain={value}
        setDomain={setValue}
      />
      <DisconnectPopup
        isOpen={isDisconnectPopupOpen}
        onClose={closeDisconnectPopup}
      />
      <CongratulationPopup
        isOpen={isCongratsPopupOpen}
        onClose={closeCongratulationPopup}
      />
    </Styled.CustomDomainContent>
  )
}

const mapStateToProps = state => ({
  domain: selectDomain(state),
  domains: selectDomains(state),
  isPublished: selectSitePublishedState(state),
  errorMessage: selectOwnDomainErrorMessage(state),
  configuredMessage: selectConfiguredMessage(state),
  certifiedStatus: selectDomainCertifiedStatus(state),
  isCongratsPopupOpen: selectIsCongratsPopupOpen(state),
  isDomainConfigured: selectDomainConfiguredStatus(state),
  isRfDomain: getSiteData(state).isRfDomain
})

export default connect(mapStateToProps, {
  openDomainPage,
  connectDomain,
  setSearchQuery,
  removeHttpsConfigs,
  setOwnDomainErrorMessage,
  closeCongratulationPopup,
  getDomainConfiguredStatus
})(CustomDomainContent)
