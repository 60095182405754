// @flow

import React, { createContext, useReducer, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setWebsitePremiumFeatureState } from '@redux/actions/editor'

import { simpleCookieReducer, gdprCookieReducer } from './reducers'
import { simpleCookieActionGetter, gdprCookieActionGetter } from './actions'
import { selectHasFooterInProject } from '@selectors'

import { selectSimpleCookies, selectGDPRCookies } from '@selectors'

import {
  INITIAL_SIMPLE_COOKIES,
  INITIAL_GDPR_SETTINGS,
  PRIVACY_POLICY_LINK_TYPES
} from './consts'
import { setPPBtnFooterVisibility } from './utils'

export const SimpleCookieContext = createContext()
export const GDPRCookieContext = createContext()

const isInternalUrl = (url: string) => url.startsWith('/')

const CookieStoreContextProvider = ({ children }) => {
  const hasFooterInProject = useSelector(selectHasFooterInProject)
  const dispatch = useDispatch()

  const initialSimpleCookies =
    useSelector(selectSimpleCookies) || INITIAL_SIMPLE_COOKIES

  const _initialGDPRCookies =
    useSelector(selectGDPRCookies) || INITIAL_GDPR_SETTINGS
  const initialGDPRCookies = setPPBtnFooterVisibility(
    _initialGDPRCookies,
    hasFooterInProject
  )

  const initialPrivacyPolicyLinkType = isInternalUrl(
    initialGDPRCookies.privacyPolicyBtn.link
  )
    ? PRIVACY_POLICY_LINK_TYPES.INTERNAL
    : PRIVACY_POLICY_LINK_TYPES.EXTERNAL
  const [privacyPolicyLinkType, setPrivacyPolicyLinkType] = useState(
    initialPrivacyPolicyLinkType
  )

  const [simpleCookieState, simpleCookieDispatcher] = useReducer(
    simpleCookieReducer,
    initialSimpleCookies
  )
  const [gdprCookieState, gdprCookieDispatcher] = useReducer(
    gdprCookieReducer,
    initialGDPRCookies
  )

  const callback = () => {
    dispatch(setWebsitePremiumFeatureState(true))
  }

  return (
    <SimpleCookieContext.Provider
      value={{
        state: simpleCookieState,
        actions: simpleCookieActionGetter(
          simpleCookieDispatcher,
          initialSimpleCookies
        )
      }}
    >
      <GDPRCookieContext.Provider
        value={{
          state: gdprCookieState,
          actions: gdprCookieActionGetter(
            gdprCookieDispatcher,
            initialGDPRCookies,
            callback
          ),
          privacyPolicyLinkType,
          setPrivacyPolicyLinkType
        }}
      >
        {children}
      </GDPRCookieContext.Provider>
    </SimpleCookieContext.Provider>
  )
}

export default CookieStoreContextProvider
