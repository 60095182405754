//@flow
import React, { memo, useState, useEffect, useCallback } from 'react'

import * as Styled from './styled'
import { TSingleAccordionProps } from './types'
import ArrowIcon from '@editor/common/assets/svgr-icons/arrow_filled_top.svg'

const SingleAccordion = ({
  id,
  title,
  isOpen: isOpenFromProps,
  className,
  disabled,
  children,
  maxHeight,
  hideArrow = false,
  disabledClassName,
  isScrollableIntoView,
  onExpanderClick
}: TSingleAccordionProps) => {
  const [isMounted, setMounted] = useState(false)

  const isOpen = isOpenFromProps && !disabled

  useEffect(() => {
    if (isOpen) {
      setMounted(true)
    }
  }, [isOpen])

  const onTransitionEnd = useCallback(() => {
    if (!isOpen) {
      setMounted(false)
    }
  }, [isOpen])

  const expanderRef = el => {
    if (!isScrollableIntoView) {
      return
    }
    setTimeout(() => {
      el && el.scrollIntoView({ behavior: 'smooth' })
    }, 300)
  }

  return (
    <Styled.Expander className={className} ref={expanderRef}>
      <Styled.DisabledOverlay
        disabled={disabled}
        className={disabledClassName}
      />
      <Styled.TitleWrapper
        onClick={() => (disabled ? null : onExpanderClick(id))}
      >
        {title}
        {hideArrow ? null : (
          <Styled.ArrowButton isExpanded={isOpen}>
            <ArrowIcon />
          </Styled.ArrowButton>
        )}
      </Styled.TitleWrapper>

      <Styled.Content
        isOpen={isOpen}
        maxHeight={maxHeight}
        onTransitionEnd={onTransitionEnd}
        className="single-accordion-content"
      >
        {isMounted ? children : null}
      </Styled.Content>
    </Styled.Expander>
  )
}

export default memo(SingleAccordion)
