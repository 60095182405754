import styled from 'styled-components'

export const MyDomainWrapper = styled.div`
  position: absolute;
  z-index: 10001;
  width: 100%;
  height: 100%;
  & > div {
    overflow-y: auto;
  }
`
