import styled, { createGlobalStyle, css } from 'styled-components'

import { secondary } from '@website/common/styles/colors'

export const GlobalStyles = createGlobalStyle`
  .tab-tooltip_content {
    transform: translateY(-40px);
  }
  `

export const TabWrapper = styled.div`
  position: relative;
  padding: 5px 5px 15px;
  border-radius: 10px;

  ${({ isActive }) =>
    isActive
      ? css`
          background-color: #eaf2ff;

          &:before {
            content: '';
            height: 10px;
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            background-color: #eaf2ff;
            z-index: 0;
          }
        `
      : css`
          background-color: #ffffff;
        `}
`

export const Tab = styled.div`
  position: relative;
  height: 110px;
  display: flex;
  background-color: #ffffff;
  cursor: pointer;
  box-sizing: border-box;

  ${({ isActive }) =>
    isActive
      ? css`
          border: 1px solid #387dff;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;

          &:after {
            content: '';
            height: 24px;
            width: 100%;
            position: absolute;
            bottom: -23px;
            left: -1px;
            background-color: #ffffff;
            border-left: 1px solid #387dff;
            border-right: 1px solid #387dff;
            z-index: 1;
          }
        `
      : css`
          border: 1px solid #d2e0fc;
          border-radius: 10px;
          &:hover {
            box-shadow: 3px 3px 12px #387dff33;
            transform: translateY(-5px);
            transition: all 0.4s ease-out;
          }
        `}
`

export const Line = styled.div`
  height: 1px;
  width: 90%;
  background-color: ${secondary.ExtraLight};
  position: absolute;
  z-index: 2;
  bottom: -16px;
  left: 50%;
  transform: translateX(-50%);
`

export const Overlay = styled.div`
  position: absolute;
  background-color: #ffffff;
  opacity: 0.5;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  z-index: 2;
  top: 0;
  left: 0;
`
