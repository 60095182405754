//@flow
import React, { memo } from 'react'

import type { TDropdownItemProps } from './types'
import { DropdownItem as StyledDropdownItem } from './styled'
import { getFlagUrl } from '@website/common/utils/website-languages'

const DropdownItem = ({ language, className, onClick }: TDropdownItemProps) => (
  <StyledDropdownItem className={className} onClick={onClick}>
    <img src={getFlagUrl(language.flagCode)} alt={language.name} />
    {language.name}
  </StyledDropdownItem>
)

export default memo(DropdownItem)
