import styled, { createGlobalStyle } from 'styled-components'
import { secondary, white } from '@website/common/styles/colors'
import LibButton from '@editor/common/lib/Button'

import { Logo } from '../../../styled'

export const GlobalStyles = createGlobalStyle`
  .more-options-flyout_container {
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 3px 10px #0052E026;
    background-color: white;
  }
`

export const Text = styled.span`
  color: #252e48;
  line-height: 20px;
`

export const PrimaryText = styled(Text)`
  font-weight: 600;
  font-size: 16px;
`

export const SecondaryText = styled(Text)`
  color: #545f7e;
  font-size: 14px;
  margin-top: 10px;

  @media only screen and (max-width: 500px) {
    margin-top: 20px;
  }
`

export const ConnectSecondaryText = styled(SecondaryText)`
  @media only screen and (max-width: 500px) {
    margin-top: 10px;
  }
`

export const ConnectedCardWrapper = styled.div`
  position: relative;
  margin: 20px 0;
`

export const TextsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 10px 0 0;
`

export const ConnectTextsWrapper = styled(TextsWrapper)`
  margin: 0 0 0 20px;

  @media only screen and (max-width: 767px) {
    margin: 0 10px 15px 0;
  }
`

export const ConnectImageWrapper = styled.div`
  padding: 10px 10px 0 5px;
  width: 107px;
  border-radius: 9px 0 0 9px;

  background: rgba(255, 98, 45, 0.15);

  @media only screen and (max-width: 768px) {
    border-radius: 10px 10px 0 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0 0;
  }
`

export const Button = styled(LibButton)`
  height: 40px;
  padding: 11px 20px;
  white-space: nowrap;

  @media only screen and (max-width: 500px) {
    width: 85%;
  }
`

export const ConnectButton = styled(Button)`
  @media only screen and (max-width: 500px) {
    width: 100%;
  }
`

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;

  @media only screen and (max-width: 768px) {
    padding: 20px;
  }

  @media only screen and (max-width: 500px) {
    flex-direction: column;
  }
`

export const Card = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${white};
  border-radius: 10px;
  border: 1px solid ${secondary.ExtraLight};
  box-sizing: border-box;
  min-height: 107px;
`

export const ConnectedCard = styled(Card)`
  min-height: 123px;
  padding: 50px 30px 30px 20px;

  @media only screen and (max-width: 768px) {
    padding: 50px 20px 40px;
  }

  @media only screen and (max-width: 500px) {
    flex-direction: column;
  }
`

export const ConnectCard = styled(Card)`
  padding-right: 40px;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    padding-right: 0;
  }
`

export const Image = styled.img`
  margin-top: 5px;

  @media only screen and (max-width: 768px) {
    margin-top: 0;
    width: 127px;
    height: 95px;
  }
`
export const CardLogo = styled(Logo)`
  svg {
    fill: #ff642d;
  }
`

const GrayButton = styled.div`
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  background: linear-gradient(180deg, #fbfbfb 0%, #f5fafe 100%);
  border: 1px solid #dee3f0;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    fill: #545f7e;
  }
`

export const MoreFlyoutButton = styled(GrayButton)`
  margin-left: 10px;

  :hover {
    cursor: pointer;
  }
`

export const DisconnectButton = styled(GrayButton)`
  margin-left: 15px;

  svg {
    fill: #545f7e;
  }
`

export const ButtonsWrapper = styled.div`
  display: flex;

  @media only screen and (max-width: 500px) {
    width: 100%;
    margin-top: 30px;
  }
`

export const FlyOutOption = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  height: 54px;
  box-sizing: border-box;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #545f7e;
  padding: 15px;

  svg {
    fill: #545f7e;
    width: 24px;
  }

  :hover {
    background: #eef5ff;
    border-radius: 0px 0px 4px 4px;
    cursor: pointer;
    color: #252e48;

    svg * {
      fill: #252e48;
    }
  }
`

export const OptionText = styled.span`
  margin-left: 10px;
`
