// @flow
import React, { memo } from 'react'

import * as Styled from './styled'
import { TSettingsTabsProps } from './types'
import { LEFT, RIGHT, TAB_CORNER } from './consts'
import Tab from './Tab'

const SettingsTabs = ({
  activeTab,
  tabsData,
  onTabClick
}: TSettingsTabsProps) => {
  const isLeftTabActive = activeTab === LEFT

  return (
    <Styled.SettingsTabsWrapper isLeftTabActive={isLeftTabActive}>
      <Styled.Tabs>
        <Tab
          tabContent={tabsData[LEFT].tab}
          tooltipText={tabsData[LEFT].tooltipText}
          isDisabled={tabsData[LEFT].isDisabled}
          isActive={isLeftTabActive}
          onClick={() => onTabClick(LEFT)}
        />
        <Tab
          tabContent={tabsData[RIGHT].tab}
          tooltipText={tabsData[RIGHT].tooltipText}
          isDisabled={tabsData[RIGHT].isDisabled}
          isActive={activeTab === RIGHT}
          onClick={() => onTabClick(RIGHT)}
        />
        <Styled.CornerImg src={TAB_CORNER} className="corner" alt="corner" />
      </Styled.Tabs>
      <Styled.ContentWrapper>
        <Styled.Content>{tabsData[activeTab].content}</Styled.Content>
      </Styled.ContentWrapper>
    </Styled.SettingsTabsWrapper>
  )
}

export default memo(SettingsTabs)
