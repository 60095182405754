// @flow
import React from 'react'
import { connect } from 'react-redux'

import Card from '../Card'
import {
  selectGoogleAnalyticsId,
  selectGoogleAnalyticsSuccessMessage
} from '@selectors'
import { setGoogleAnalyticsId } from '@actions/meta'

import type { TGoogleAnalyticsProps } from './types'

const GoogleAnalytics = ({
  value,
  setValue,
  googleAnalyticsId,
  setGoogleAnalyticsId,
  successMessage,
  ...rest
}: TGoogleAnalyticsProps) => {
  const hasChanges = value !== googleAnalyticsId
  const isConnected = !!googleAnalyticsId

  return (
    <Card
      inputValue={value}
      isConnected={isConnected}
      hasChanges={hasChanges}
      successMessage={successMessage}
      setId={setGoogleAnalyticsId}
      setInputValue={setValue}
      {...rest}
    />
  )
}

const mapStateToProps = state => ({
  googleAnalyticsId: selectGoogleAnalyticsId(state),
  successMessage: selectGoogleAnalyticsSuccessMessage(state)
})

const mapDispatchToProps = { setGoogleAnalyticsId }

export default connect(mapStateToProps, mapDispatchToProps)(GoogleAnalytics)
