//@flow

import React, { memo, useState, useCallback } from 'react'
import * as Styled from './styled'
import type { TMultipleAccordionProps } from './types'

const MultipleAccordion = ({
  data,
  className,
  onChange,
  isDisabled,
  isMultiExpandable = false,
  hasScrollableElement = false,
  initiallyExpandedKeys = []
}: TMultipleAccordionProps) => {
  if (!Array.isArray(data) || !data.length) {
    return null
  }
  const [expandedKeys, setExpandedKeys] = useState(initiallyExpandedKeys)

  const onExpanderClick = useCallback(
    key => {
      typeof onChange === 'function' && onChange(key)
      const isExpanded = expandedKeys.includes(key)

      const nextExpandedKeys = isExpanded
        ? expandedKeys.filter(expandedKey => key !== expandedKey)
        : isMultiExpandable
        ? [...expandedKeys, key]
        : [key]

      setExpandedKeys(nextExpandedKeys)
    },
    [expandedKeys]
  )

  return (
    <Styled.Container className={className}>
      {data.map(({ content, type, ...settings }) => (
        <Styled.SingleAccordion
          id={type}
          key={type}
          disabled={isDisabled}
          isScrollableIntoView={
            hasScrollableElement && type === initiallyExpandedKeys[0]
          }
          isOpen={expandedKeys.includes(type)}
          onExpanderClick={onExpanderClick}
          {...settings}
        >
          {content}
        </Styled.SingleAccordion>
      ))}
    </Styled.Container>
  )
}

export default memo(MultipleAccordion)
