// @flow

export const setPPBtnFooterVisibility = (
  GDPRSettings: Object,
  hasFooterInProject: boolean
): Object => {
  if (hasFooterInProject) {
    return GDPRSettings
  }
  return {
    ...GDPRSettings,
    privacyPolicyBtn: {
      ...GDPRSettings.privacyPolicyBtn,
      showInFooter: false
    }
  }
}
