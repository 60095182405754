import {
  GoogleAnalytics,
  GoogleTagManager,
  AdSense,
  AuthDigitalSellers,
  FacebookPixel,
  YandexMetrica
} from './components'
import { MARKETING_INTEGRATIONS_KEYS } from './consts'
import { translate } from '@editor/common/utils/translations'
import { transformTextVariables } from '@src/editor/common/utils/text-transformation'

export const getMarketingIntegrationsMeta = isSubscriber => [
  ...(isSubscriber
    ? [
        {
          key: 'googleAnalyticsId',
          title: 'Google Analytics',
          logo: 'https://static.rfstat.com/renderforest/images/website_maker_images/google-analytics.svg',
          inputLabel: translate('enter_your_property_id'),
          placeholder: 'Ex. UA-XXXXXXXX-Y or G-XXYYXXYYXX',
          tooltipText: transformTextVariables(
            translate('google_analytics_prop_id_tooltip_text'),
            [{ value: 'ID' }]
          ),
          tooltipImageSrc:
            'https://static.rfstat.com/renderforest/images/website_maker_images/google_analytics.svg',
          Comp: GoogleAnalytics
        },
        {
          key: 'yandexMetricaId',
          title: 'Yandex Metrica',
          logo: 'https://static.rfstat.com/renderforest/images/website_maker_images/yandex_metrics_icon.svg',
          inputLabel: translate('yandex_metrica_input_label'),
          placeholder: 'XXXXXXXX',
          tooltipText: transformTextVariables(
            translate('yandex_metrica_tooltip_text'),
            [{ value: 'Yandex Metrica' }, { value: 'Yandex Metrica' }]
          ),
          tooltipImageSrc:
            'https://static.rfstat.com/renderforest/images/website_maker_images/yandex_metrica_tooltip.svg',
          Comp: YandexMetrica
        },
        {
          key: 'googleTagManagerId',
          title: 'Google Tag Manager',
          logo: 'https://static.rfstat.com/renderforest/images/website_maker_images/google-tag-manager.svg',
          inputLabel: translate('enter_your_container_id'),
          placeholder: 'GTM-XXXXXXX',
          tooltipText: translate('google_tag_manager_tooltip_text'),
          tooltipImageSrc:
            'https://static.rfstat.com/renderforest/images/website_maker_images/google-tag-manager-tooltip.svg',
          Comp: GoogleTagManager,
          formatEmbed: embed => (embed.match(/GTM-[A-Z0-9]{1,7}/) || [])[0]
        },
        {
          key: 'googleAdSenseId',
          title: 'Google AdSense',
          logo: 'https://static.rfstat.com/renderforest/images/website_maker_images/google-adsense.svg',
          inputLabel: translate('enter_your_property_id'),
          placeholder: 'Ex. pub-1234567891234567',
          tooltipText: translate('google_adsense_id_tooltip_text'),
          tooltipImageSrc:
            'https://static.rfstat.com/renderforest/images/website_maker_images/google_adsense.svg',
          Comp: AdSense
        },
        {
          key: 'authDigitalSellersContent',
          title: 'Ads.txt',
          logo: 'https://static.rfstat.com/renderforest/images/website_maker_images/ads.txt.svg',
          inputLabel: translate('enter_your_adsTxt_content'),
          placeholder:
            'Ex. google.com, pub-1234567891234567, DIRECT, f08c47fec0942fa',
          tooltipText: translate('ads_txt_tooltip_text'),
          tooltipImageSrc:
            'https://static.rfstat.com/renderforest/images/website_maker_images/ads_txt_illustration.svg',
          Comp: AuthDigitalSellers
        }
      ]
    : []),
  {
    key: 'facebookPixelCode',
    title: 'Facebook Pixel',
    logo: 'https://static.rfstat.com/renderforest/images/website_maker_images/fb-pixel.svg',
    inputLabel: translate('enter_your_id'),
    placeholder: 'Ex. 823067130259512',
    tooltipText: translate('facebook_pixel_tooltip_text'),
    tooltipImageSrc:
      'https://static.rfstat.com/renderforest/images/website_maker_images/facebook_pixel.svg',
    Comp: FacebookPixel
  }
]

export const reorderMarketingIntegrations = marketingIntegrations => {
  const connectedIntegrations = Object.keys(marketingIntegrations).filter(
    key => marketingIntegrations[key]
  )
  const restIntegrations = []

  MARKETING_INTEGRATIONS_KEYS.forEach(integration => {
    const isIntegrationConnected =
      connectedIntegrations.indexOf(integration) !== -1

    if (isIntegrationConnected) return

    restIntegrations.push(integration)
  })

  return [...connectedIntegrations, ...restIntegrations]
}
