import styled from 'styled-components'
import { primary, secondary, white } from '@website/common/styles/colors'
import { scrollBarStyles } from '@editor/common/styles'
import { Description as _Description } from '../../../shared-components'
import { Line as _Line } from './../GDPR/common-styled'
import { Button } from '@editor/containers/edit/containers/settings/styled'
import { styleByLang } from '@editor/common/utils/style-by-lang'

const customDistance = {
  lineMarginTop: '35px',
  categoryFormPadding: '0 5px 25px'
}

const stylesObj = {
  ru: customDistance,
  es: customDistance,
  pt: customDistance,
  fr: customDistance,
  de: customDistance,
  default: {
    lineMarginTop: '25px',
    categoryFormPadding: '0 5px 10px'
  }
}
const getStylesByLang = styleByLang(stylesObj)

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 768px) {
    overflow-y: auto;
    box-sizing: border-box;
  }
`
export const ContentTitle = styled.p`
  font-size: 20px;
  font-weight: 600;
  color: ${secondary.Dark};

  @media only screen and (max-width: 768px) {
    font-size: 16px;
  }
`

export const Label = styled(_Description)`
  max-width: 100%;
  padding: 15px 0 10px;
  color: ${secondary.Dark};
`

export const MainContent = styled.div`
  display: flex;
  height: 100%;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`

export const EditableFields = styled.div`
  width: 50%;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`

export const SettingsPreviewContainer = styled.div`
  max-height: 80vh;
  display: flex;
  flex-direction: column;
  border-radius: inherit;
  box-shadow: 0 0 9px #0052e033;
  z-index: 1;
`
export const SettingsPreviewWrapper = styled.div`
  transform: scale(0.57) translateY(-32%);
  margin: -5px -18% -45% -14%;
  padding: 10px;
  height: 100%;
  border-radius: 10px;
  background-color: ${primary.ExtraLight};
  pointer-events: none;
  user-select: none;

  @media only screen and (max-width: 520px) {
    margin: -26% -17% -26% -17%;
    transform: scale(0.71);
  }

  @media (min-width: 630px) and (max-width: 768px) {
    margin: -3% auto -45% auto;
  }
`

export const Line = styled(_Line)`
  margin-top: 25px;

  @media only screen and (max-width: 568px) {
    margin-top: ${getStylesByLang('lineMarginTop')};
  }
`

export const ContentContainer = styled.div`
  ${scrollBarStyles};

  padding: 20px;

  @media only screen and (min-width: 768px) {
    padding: 30px;
  }
  box-sizing: border-box;
  overflow-y: scroll;
  height: calc(100% - 65px);
`

export const CookieContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const CategoryFormContainer = styled.div`
  padding: 5px 30px 25px 30px;

  @media only screen and (max-width: 768px) {
    padding: 0 5px 10px;

    @media only screen and (max-width: 568px) {
      padding: ${getStylesByLang('categoryFormPadding')};
    }
  }
`

export const CategoryContainer = styled.div`
  margin-top: 10px;
`

export const DiscardButton = styled(Button)`
  color: ${primary.Dark};
  background: ${white};
  &:hover {
    background: #eef5ff;
    transition: all 0.2s ease-out;
  }
`
export const SaveButton = styled(Button)`
  color: ${white};
  background: transparent
    linear-gradient(180deg, ${primary.SemiDark} 0%, ${primary.Dark} 100%) 0% 0%
    no-repeat padding-box;
  &:hover {
    background: transparent
      linear-gradient(180deg, #6ea0ff 0%, ${primary.SemiDark} 100%) 0% 0%
      no-repeat padding-box;
    box-shadow: 0px 3px 9px #0052e080;
    transition: all 0.2s ease-out;
  }
`

export const Footer = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  height: 65px;
  padding: 12px 12px 12px 30px;
  display: flex;
  justify-content: flex-start;
  box-sizing: border-box;
  background: ${white};
  border-radius: 0 0 10px 10px;
  box-shadow: 5px -3px 12px #0052e01a;

  @media only screen and (max-width: 830px) {
    justify-content: center;
  }
`
