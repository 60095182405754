// @flow

import React, { useCallback, useRef, useState } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { connect } from 'react-redux'

import * as Styled from './styled'
import type { TSettingsProps } from './types'
import SettingsContainer from './SettingsContainer'
import { SettingsLoader, RfLoader } from '@editor/common/components/Loaders'
import {
  selectIsCustomDomainSaving,
  selectIsLoadingConfigured,
  selectIsSettingsLoading,
  selectIsSubdomainSaving,
  selectPossibleLanguagesDataLoadingStatus,
  selectIsCookiesLoading,
  selectSemrushLoginInfo
} from '@selectors'
import { useHistoryWithSearch, usePopupCustomMouseDown } from '@hooks'
import { useDiscardDialog } from '@hooks/useDiscardDialog'
import { PAGE_BASE } from '@editor/conf/routes'

const Settings = ({
  isCookiesLoading,
  isSettingsLoading,
  isCustomDomainSaving,
  isSubdomainSaving,
  isConfiguredLoading,
  isPossibleLanguagesDataLoading,
  isSemrushLoginInfoLoading
}: TSettingsProps) => {
  const [hasChangesInTab, setChangesState] = useState(false)

  const { url } = useRouteMatch(PAGE_BASE)
  const history = useHistoryWithSearch()
  const popupRef = useRef(null)
  const isSaving =
    isSettingsLoading ||
    isCustomDomainSaving ||
    isSubdomainSaving ||
    isCookiesLoading

  const isLoading =
    isConfiguredLoading ||
    isPossibleLanguagesDataLoading ||
    isSemrushLoginInfoLoading

  const closePopup = useCallback(() => {
    history.push(url)
  }, [url])

  const onClose = useDiscardDialog(closePopup, {
    id: 'cookies',
    hasChanges: hasChangesInTab,
    watchedContentId: 'customization'
  })

  usePopupCustomMouseDown(popupRef, '.settings-popup', onClose)

  return (
    <>
      <Styled.SettingsPopup
        isOpen={true}
        onClose={onClose}
        isMobileFullScreen
        className="settings-popup"
      >
        <SettingsContainer
          containerRef={popupRef}
          hasChangesInTab={hasChangesInTab}
          closeSettingsPopup={onClose}
          setChangesState={setChangesState}
        >
          {isSaving && <SettingsLoader />}
          <RfLoader isVisible={isLoading} className="loader" />
        </SettingsContainer>
      </Styled.SettingsPopup>
    </>
  )
}

const mapStateToProps = state => ({
  isCookiesLoading: selectIsCookiesLoading(state),
  isSettingsLoading: selectIsSettingsLoading(state),
  isCustomDomainSaving: selectIsCustomDomainSaving(state),
  isSubdomainSaving: selectIsSubdomainSaving(state),
  isConfiguredLoading: selectIsLoadingConfigured(state),
  isPossibleLanguagesDataLoading:
    selectPossibleLanguagesDataLoadingStatus(state),
  isSemrushLoginInfoLoading: selectSemrushLoginInfo(state).isLoading
})

export default connect(mapStateToProps)(Settings)
