// @flow
import React from 'react'
import { connect } from 'react-redux'

import type { TAdSenseProps } from './types'
import Card from '../Card'
import {
  selectGoogleAdSenseId,
  selectGoogleAdSenseSuccessMessage
} from '@selectors'
import { setGoogleAdsenseId } from '@actions/meta'

const AdSense = ({
  value,
  setValue,
  googleAdSenseId,
  setGoogleAdsenseId,
  successMessage,
  ...rest
}: TAdSenseProps) => {
  const hasChanges = value !== googleAdSenseId
  const isConnected = !!googleAdSenseId

  return (
    <Card
      inputValue={value}
      isConnected={isConnected}
      hasChanges={hasChanges}
      successMessage={successMessage}
      setId={setGoogleAdsenseId}
      setInputValue={setValue}
      {...rest}
    />
  )
}

const mapStateToProps = state => ({
  googleAdSenseId: selectGoogleAdSenseId(state),
  successMessage: selectGoogleAdSenseSuccessMessage(state)
})

const mapDispatchToProps = { setGoogleAdsenseId }

export default connect(mapStateToProps, mapDispatchToProps)(AdSense)
