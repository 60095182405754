import React from 'react'

export const UPGRADE_INFO = 'Upgrade to add'
export const UPGRADE_BANNER_INFO_LIST = [
  'Google Analytics',
  'Google AdSense',
  'Google Tag Manager'
]

export const MARKETING_INTEGRATIONS_KEYS = [
  'googleAnalyticsId',
  'yandexMetricaId',
  'googleTagManagerId',
  'googleAdSenseId',
  'authDigitalSellersContent',
  'facebookPixelCode'
]

export const ANALYTICS_UPGRADE_IMAGE =
  'https://static.rfstat.com/renderforest/images/website_maker_images/analytics_upgrade_image.png'
export const ANALYTICS_UPGRADE_HOVER_IMAGE =
  'https://static.rfstat.com/renderforest/images/website_maker_images/analytics_upgrade_hover_image.png'
