// @flow
import React, { memo, useCallback, useEffect, useRef } from 'react'
import {
  useRouteMatch,
  useLocation,
  Switch,
  Route,
  Redirect
} from 'react-router-dom'
import { translate } from '@editor/common/utils/translations'

import * as Styled from './styled'
import type { TSettingsContainerProps } from './types'
import { TABS_DATA, LEAVE_TAB_DIALOG_BUTTONS } from './consts'
import { General, Domain, Integrations, Cookies, Languages } from './contents'
import { Tabs } from '@editor/common/components/Tabs'
import HorizontalScrollWrapper from '@editor/common/components/HorizontalScrollWrapper'
import { useHistoryWithSearch } from '@hooks'
import { useDiscardDialog } from '@hooks/useDiscardDialog'

const getActiveTab = pathname => {
  return pathname.split('/')[6]
}

const SettingsContainer = ({
  children,
  containerRef,
  hasChangesInTab,
  setChangesState,
  closeSettingsPopup
}: TSettingsContainerProps) => {
  const history = useHistoryWithSearch()
  const { path, url } = useRouteMatch()
  const { pathname, search } = useLocation()
  const activeTab = getActiveTab(pathname)
  const contentRef = useRef(null)
  const activeTabToRedirect = activeTab ? `/${activeTab}` : '/general'

  const setActiveTab = useCallback(
    activeTab => {
      history.push({
        pathname: `${url}/${activeTab}`,
        search
      })
    },
    [url]
  )

  const onTabChange = useDiscardDialog(
    setActiveTab,
    {
      id: 'cookies',
      hasChanges: hasChangesInTab,
      watchedContentId: 'customization'
    },
    {
      title: translate('settings_tab_changing_popup_label'),
      buttonTexts: LEAVE_TAB_DIALOG_BUTTONS
    }
  )

  const handleTabChange = useCallback(
    active => {
      if (active === activeTab) {
        return
      }
      onTabChange(active)
    },
    [onTabChange, activeTab]
  )

  useEffect(() => {
    const { current: elem } = contentRef

    elem &&
      elem.scrollTo({
        top: 0
      })
  }, [contentRef, activeTab])

  return (
    <Styled.SettingsContainer ref={containerRef}>
      <Styled.Title>{translate('website_settings_label')}</Styled.Title>
      <Styled.TabsWrapper>
        <HorizontalScrollWrapper scrollToId={activeTab}>
          <Tabs
            className="setting-tabs"
            activeTab={activeTab}
            data={TABS_DATA}
            setActiveTab={handleTabChange}
            hasChangesInTab={hasChangesInTab}
          />
        </HorizontalScrollWrapper>
      </Styled.TabsWrapper>
      <Styled.Content ref={contentRef}>
        <Switch>
          <Route
            exact
            path={`${path}/general`}
            render={props => (
              <General
                {...props}
                closeSettingsPopup={closeSettingsPopup}
                setChangesState={setChangesState}
              />
            )}
          />
          <Route
            exact
            path={`${path}/domain`}
            render={props => (
              <Domain {...props} setChangesState={setChangesState} />
            )}
          />
          <Route
            path={`${path}/integrations`}
            render={props => (
              <Integrations
                {...props}
                closeSettingsPopup={closeSettingsPopup}
                setChangesState={setChangesState}
              />
            )}
          />
          <Route
            path={`${path}/cookies`}
            render={props => (
              <Cookies
                {...props}
                closeSettingsPopup={closeSettingsPopup}
                setChangesState={setChangesState}
                hasChangesInTab={hasChangesInTab}
              />
            )}
          />
          <Route
            path={`${path}/languages`}
            render={props => (
              <Languages
                {...props}
                closeSettingsPopup={closeSettingsPopup}
                setChangesState={setChangesState}
              />
            )}
          />
          <Redirect to={`${path}${activeTabToRedirect}${search}`} />
        </Switch>
      </Styled.Content>
      {children}
    </Styled.SettingsContainer>
  )
}

export default memo(SettingsContainer)
