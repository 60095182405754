import styled from 'styled-components'

import Accordion from '@renderforest/rf-ui-library/molecules/Accordion'

export const CardsWrapper = styled.div`
  position: relative;
  padding: 30px 5px 20px;
  overflow-y: scroll;
`

export const CardWrapper = styled.div`
  position: relative;
  .overlay {
    position: absolute;
    height: 65px;
    width: 100%;
    border-radius: 10px;
    cursor: pointer;
  }
`

export const Card = styled(Accordion)`
  background-color: ${({ isSelected, isAlwaysOpen }) =>
    isSelected || isAlwaysOpen ? '#EAF2FF' : '#ffffff'};
  padding: 5px;
  margin-bottom: 15px;

  & > div {
    background-color: #ffffff;
    opacity: ${({ disabled, showDisabledStyles }) =>
      disabled && showDisabledStyles ? '0.5' : '1'};

    &:last-child {
      border: ${({ isSelected, isAlwaysOpen }) =>
        `1px solid ${isSelected || isAlwaysOpen ? '#387DFF' : '#d2e0fc'}`};
    }

    & > button {
      padding: 18px 0;
      font-size: 16px;
      color: #363f5a;
      font-weight: 500;
    }
    div[role='region'] {
      & > div {
        border-top: 1px solid #dee3f0;
        padding: 0;
      }
    }
  }
`

export const UpgradeLabel = styled.img`
  position: absolute;
  z-index: 1;
  top: 29px;
  right: 25px;
`
