import styled, { css } from 'styled-components'
import { secondary } from '@website/common/styles/colors'
import { styleByLang } from '@editor/common/utils/style-by-lang'

const lineCustomMargin = {
  marginTop: '40px'
}

const stylesObj = {
  ru: lineCustomMargin,
  es: lineCustomMargin,
  pt: lineCustomMargin,
  fr: lineCustomMargin,
  de: lineCustomMargin,
  default: {
    marginTop: '30px'
  }
}
const getStylesByLang = styleByLang(stylesObj)

export const CustomizeButton = styled.div`
  position: relative;
  width: fit-content;

  button {
    background: #eef5ff;
    margin-top: 30px;
    padding: 11px 10px;

    @media only screen and (max-width: 568px) {
      margin-top: ${getStylesByLang('marginTop')};
    }

    &:hover {
      background: #387dff;
      svg {
        fill: white;
      }
      p {
        color: white;
      }
    }
  }
`

export const ButtonText = styled.p`
  font-size: 14px;
  color: #3271e6;
`

export const DisabledOverlay = styled.div`
  ${({ disabled }) =>
    disabled
      ? css`
          width: 100%;
          z-index: 1;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          border-radius: 10px;
          opacity: 50%;
          background-color: ${secondary.UltraLight};
        `
      : ''}
`
