import React, { memo, useMemo, useCallback, useState, useEffect } from 'react'
import * as Styled from './styled'
import * as CommonStyled from '../GDPR/common-styled'
import { translate } from '@editor/common/utils/translations'
import {
  transformTextVariables,
  capitalizeFirstLetter
} from '@src/editor/common/utils/text-transformation'

const lang = window.CURRENT_LANGUAGE
const CategoryForm = ({
  type,
  data: { cookieData, defaultData },
  handleCategoryDataChange
}) => {
  const handleCategoryTitleChange = useCallback(
    value => {
      handleCategoryDataChange(value, type, 'title')
    },
    [handleCategoryDataChange]
  )

  const handleCategoryDescriptionChange = useCallback(
    value => {
      handleCategoryDataChange(value, type, 'description')
    },
    [handleCategoryDataChange]
  )

  const data = useMemo(
    () => ({
      cookieData: cookieData.find(item => item.type === type),
      defaultCookieData: defaultData.find(item => item.type === type)
    }),
    [cookieData, defaultData, type]
  )

  const title = data.cookieData?.title
  const description = data.cookieData?.description
  const defaultTitle = data.defaultCookieData?.title
  const defaultDescription = data.defaultCookieData?.description

  return (
    <Styled.CategoryFormContainer>
      <Styled.Label>
        {transformTextVariables(translate('title_for_cookies_label'), [
          {
            value: ['jp', 'tr'].includes(lang)
              ? capitalizeFirstLetter(type)
              : type
          }
        ])}
      </Styled.Label>
      <CommonStyled.Input
        isRequired
        count={30}
        defaultValue={defaultTitle}
        value={title}
        onChange={handleCategoryTitleChange}
      />
      <CommonStyled.InputLabel>
        {translate('description_label')}
      </CommonStyled.InputLabel>
      <CommonStyled.Input
        isTextArea
        isRequired
        defaultValue={defaultDescription}
        count={300}
        value={description}
        onChange={handleCategoryDescriptionChange}
      />
    </Styled.CategoryFormContainer>
  )
}

export default memo(CategoryForm)
