// @flow
import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'

import { Container } from '../shared-components/styled'
import type { TGeneralProps } from './types'
import {
  WebsiteName,
  Favicon,
  SocialImage,
  WebsiteEmail,
  Sitemap
} from './components'
import { Footer } from '../shared-components'
import { selectGeneralSettings, selectSiteName } from '@selectors'
import { changeGeneralSettings } from '@actions/meta'
import { isValidEmail } from '@website/common/utils'
import { ImageUploadInfo } from '@editor/common/utils/image'

const General = ({
  generalSettings,
  siteName,
  setChangesState,
  changeGeneralSettings,
  closeSettingsPopup
}: TGeneralProps) => {
  const { favicon, socialImage, email } = generalSettings
  const faviconWithUploadState = new ImageUploadInfo({
    failed: false,
    isLoading: false,
    url: favicon
  })
  const socialImageWithUploadState = new ImageUploadInfo({
    failed: false,
    isLoading: false,
    url: socialImage
  })

  const [websiteNameValue, setWebsiteNameValue] = useState(siteName)
  const [websiteFavicon, setWebsiteFavicon] = useState(faviconWithUploadState)
  const [websiteSocialImageData, setWebsiteSocialImageData] = useState({
    socialImage: socialImageWithUploadState,
    socialImageWidth: 0,
    socialImageHeight: 0
  })
  const [websiteEmail, setWebsiteEmail] = useState(email)
  const websiteSocialImage = websiteSocialImageData.socialImage

  const websiteFaviconUrl = websiteFavicon.toString()
  const websiteSocialImageUrl = websiteSocialImage.toString()

  const hasWebsiteNameChanges = websiteNameValue !== siteName
  const hasFaviconChanges = websiteFaviconUrl !== favicon
  const hasSocialImageChanges = websiteSocialImageUrl !== socialImage
  const hasWebsiteEmailChanges = websiteEmail !== email

  const hasSettingsChanges =
    hasFaviconChanges || hasWebsiteEmailChanges || hasSocialImageChanges
  const hasChanges = hasWebsiteNameChanges || hasSettingsChanges

  useEffect(() => {
    setChangesState(hasChanges)
  }, [hasChanges])

  const isSaveButtonDisabled =
    websiteNameValue === '' ||
    !hasChanges ||
    (hasWebsiteEmailChanges && websiteEmail && !isValidEmail(websiteEmail)) ||
    websiteFavicon.isLoading ||
    websiteFavicon.failed ||
    websiteSocialImage.isLoading ||
    websiteSocialImage.failed

  const onSave = useCallback(() => {
    if (isSaveButtonDisabled) {
      return
    }
    const settings = {
      ...websiteSocialImageData,
      socialImage: websiteSocialImageUrl,
      favicon: websiteFaviconUrl,
      email: websiteEmail
    }

    changeGeneralSettings(
      settings,
      hasSettingsChanges,
      websiteNameValue,
      hasWebsiteNameChanges
    )
  }, [
    websiteNameValue,
    websiteEmail,
    websiteFavicon,
    websiteSocialImageData,
    hasSettingsChanges,
    hasWebsiteNameChanges,
    isSaveButtonDisabled
  ])

  return (
    <>
      <Container>
        <WebsiteName
          value={websiteNameValue}
          setValue={setWebsiteNameValue}
          siteName={siteName}
        />
        <Sitemap />
        <Favicon favicon={websiteFavicon} setFavicon={setWebsiteFavicon} />
        <SocialImage
          socialImageData={websiteSocialImageData}
          setSocialImageData={setWebsiteSocialImageData}
        />
        <WebsiteEmail email={websiteEmail} setEmail={setWebsiteEmail} />
      </Container>
      <Footer
        isSaveButtonDisabled={isSaveButtonDisabled}
        onDiscard={closeSettingsPopup}
        onSave={onSave}
      />
    </>
  )
}

const mapStateToProps = state => ({
  generalSettings: selectGeneralSettings(state),
  siteName: selectSiteName(state)
})

export default connect(mapStateToProps, { changeGeneralSettings })(General)
