//@flow
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { connect } from 'react-redux'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { TouchBackend } from 'react-dnd-touch-backend'

import * as Styled from './styled'
import type { TLanguagesForSubscribersProps } from './types'
import { getWarningPopupProps, addOrdersToSecondaryLanguages } from './utils'
import PrimaryItem from './PrimaryItem'
import SuccessPopup from './SucessPopup'
import SecondaryLanguagesList from './SecondaryLanguagesList'
import { TitleContainer as Title, Footer } from '../shared-components'
import { WEBSITE_LANGUAGES_IMAGE } from './consts'
import { MobileDetectContext } from '@contexts'
import { ConfirmDialogContext } from '@contexts/ConfirmDialog.context'
import { translate } from '@editor/common/utils/translations'
import { filterLanguagesDataBeforePatch } from '@editor/common/utils/multilingual'
import {
  selectIsSuccessPopupOpen,
  selectIsWebsiteMultilingual,
  selectPossibleLanguagesData,
  selectWebsiteLanguagesData
} from '@selectors'
import { fetchPossibleLanguagesData } from '@actions'
import {
  closeSuccessPopup,
  patchWebsiteLanguagesData
} from '@actions/website-languages'

const LanguagesForSubscribers = ({
  websiteLanguagesData,
  isWebsiteMultilingual,
  isSuccessPopupOpen,
  possibleLanguagesData,
  closeSettingsPopup,
  setChangesState,
  closeSuccessPopup,
  fetchPossibleLanguagesData,
  patchWebsiteLanguagesData
}: TLanguagesForSubscribersProps) => {
  const initialPrimaryLanguage = isWebsiteMultilingual
    ? websiteLanguagesData.find(language => language.isPrimary)
    : null
  const initialSecondaryLanguages = websiteLanguagesData.length
    ? websiteLanguagesData.filter(language => !language.isPrimary)
    : []
  const [primaryLanguage, setPrimaryLanguage] = useState(initialPrimaryLanguage)
  const [secondaryLanguages, setSecondaryLanguages] = useState(
    initialSecondaryLanguages
  )

  useEffect(() => {
    const primaryLanguage = isWebsiteMultilingual
      ? websiteLanguagesData.find(language => language.isPrimary)
      : null
    const secondaryLanguages = websiteLanguagesData.length
      ? websiteLanguagesData.filter(language => !language.isPrimary)
      : []

    setPrimaryLanguage(primaryLanguage)
    setSecondaryLanguages(secondaryLanguages)
  }, [websiteLanguagesData, isWebsiteMultilingual])

  const isMobile = useContext(MobileDetectContext)
  const { setProps } = useContext(ConfirmDialogContext)

  const hasPrimaryDropdownAnimation =
    !primaryLanguage && secondaryLanguages.length > 0
  const hasSecondaryDropdownAnimation =
    primaryLanguage && secondaryLanguages.length === 0

  const websiteLanguagesDraft = useMemo(
    () =>
      !primaryLanguage
        ? secondaryLanguages
        : [primaryLanguage, ...secondaryLanguages],
    [primaryLanguage, secondaryLanguages]
  )

  const _websiteLanguagesData = isWebsiteMultilingual
    ? websiteLanguagesData
    : []

  const hasChanges =
    JSON.stringify(websiteLanguagesDraft) !==
    JSON.stringify(_websiteLanguagesData)

  const isSaveButtonDisabled = !hasChanges || websiteLanguagesDraft.length < 2

  useEffect(() => {
    setChangesState(hasChanges)
  }, [hasChanges])

  useEffect(() => {
    fetchPossibleLanguagesData()
  }, [])

  const possibleLanguages = useMemo(
    () =>
      possibleLanguagesData.filter(
        langData =>
          websiteLanguagesDraft.findIndex(
            ({ lang }) => langData.name === lang.name
          ) === -1
      ),
    [possibleLanguagesData, websiteLanguagesDraft]
  )

  const onSave = useCallback(() => {
    const secondaryLanguagesWithOrder =
      addOrdersToSecondaryLanguages(secondaryLanguages)
    const languages = filterLanguagesDataBeforePatch([
      primaryLanguage,
      ...secondaryLanguagesWithOrder
    ])

    const areThereDeletedLanguages = secondaryLanguages.some(
      language => language.removed
    )

    if (areThereDeletedLanguages) {
      const areAllLanguagesDeleted = secondaryLanguages.every(
        language => language.removed
      )

      const onConfirm = () => {
        patchWebsiteLanguagesData(languages)
        setProps({ isOpen: false })
      }

      setProps({
        ...getWarningPopupProps(areAllLanguagesDeleted),
        onConfirm
      })

      return
    }

    patchWebsiteLanguagesData(languages)
  }, [
    secondaryLanguages,
    isWebsiteMultilingual,
    closeSettingsPopup,
    primaryLanguage
  ])

  return (
    <>
      <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
        <Styled.Container>
          <Styled.TitleContainer>
            <Title
              title={translate('website_languages_label')}
              tooltipText={translate('website_languages_tooltip_text')}
              imgSrc={WEBSITE_LANGUAGES_IMAGE}
              hasTooltipContent
            />
            <Styled.Description>
              {translate('localize_your_website_label')}
            </Styled.Description>
          </Styled.TitleContainer>
          <Styled.MainContainer>
            <Styled.PrimaryLanguageContainer>
              <Styled.BlockTitle>
                {translate('primary_language_label')}
              </Styled.BlockTitle>
              <Styled.BlockDescription>
                {isWebsiteMultilingual
                  ? translate(
                      'primary_language_description_already_multilingual'
                    )
                  : translate('primary_language_description')}
              </Styled.BlockDescription>
              <PrimaryItem
                data={primaryLanguage}
                possibleLanguages={possibleLanguages}
                isWebsiteMultilingual={isWebsiteMultilingual}
                isDeleteButtonVisible={!secondaryLanguages.length}
                hasDropdownAnimation={hasPrimaryDropdownAnimation}
                setData={setPrimaryLanguage}
              />
            </Styled.PrimaryLanguageContainer>
            <Styled.SecondaryLanguagesContainer>
              <Styled.BlockTitle>
                {translate('secondary_languages_label')}
              </Styled.BlockTitle>
              <Styled.BlockDescription>
                {translate('secondary_languages_description')}
              </Styled.BlockDescription>
              <SecondaryLanguagesList
                languages={secondaryLanguages}
                possibleLanguages={possibleLanguages}
                allPossibleLanguages={possibleLanguagesData}
                primaryLanguage={primaryLanguage}
                isWebsiteMultilingual={isWebsiteMultilingual}
                isPrimaryDropdownVisible={!primaryLanguage}
                hasDropdownAnimation={hasSecondaryDropdownAnimation}
                setLanguages={setSecondaryLanguages}
                setPrimaryLanguage={setPrimaryLanguage}
              />
            </Styled.SecondaryLanguagesContainer>
          </Styled.MainContainer>
        </Styled.Container>
      </DndProvider>
      <Footer
        isSaveButtonDisabled={isSaveButtonDisabled}
        onSave={onSave}
        onDiscard={closeSettingsPopup}
      />
      <SuccessPopup isOpen={isSuccessPopupOpen} onClose={closeSuccessPopup} />
    </>
  )
}

const mapStateToProps = state => ({
  websiteLanguagesData: selectWebsiteLanguagesData(state),
  isWebsiteMultilingual: selectIsWebsiteMultilingual(state),
  isSuccessPopupOpen: selectIsSuccessPopupOpen(state),
  possibleLanguagesData: selectPossibleLanguagesData(state)
})

const mapDispatchToProps = {
  fetchPossibleLanguagesData,
  patchWebsiteLanguagesData,
  closeSuccessPopup
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LanguagesForSubscribers)
