import styled from 'styled-components'

import Icon from '@renderforest/rf-ui-library/atoms/Icon'
import { Input } from '@renderforest/rf-ui-library/molecules/Input'
import {
  primary,
  secondary,
  warningColor,
  accentRed
} from '@website/common/styles/colors'
import { CustomButton, getDisabledBtnStyles } from '../../styled'
import { scrollBarStyles } from '@editor/common/styles'

export const CustomDomainContent = styled.div`
  ${scrollBarStyles};
  position: relative;
  height: 100%;
  overflow-y: scroll;
`

export const CustomDomainMainContent = styled.div`
  padding: 30px;
  @media only screen and (max-width: 889px) {
    padding: 0 0 20px;
  }
  .domain-upgrade-banner {
    margin: 30px 10px 0;
    @media only screen and (min-width: 663px) and (max-width: 680px) {
      margin: 30px 0 0 0;
    }
  }
`

export const Img = styled.img`
  width: 90px;
  height: 107px;
  display: block;
  margin: 5px auto 10px;
`

export const ContentTitleWrapper = styled.span`
  color: #363f5a;
  font-weight: 500;
  display: inline-block;
  @media only screen and (max-width: 889px) {
    padding: 0 20px;
  }
`

export const MyDomains = styled.span`
  margin-left: 5px;
  font-weight: 500;
  color: #387dff;
  cursor: pointer;
  text-decoration: underline;
`

export const CustomDomainContentTextWrapper = styled.div`
  margin-top: 10px;
  width: 90%;
  @media only screen and (max-width: 889px) {
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
  }
`
export const CustomDomainContentText = styled.span`
  color: #545f7e;
  font-size: 14px;
`

export const CustomDomainForm = styled.div`
  display: flex;
  align-items: center;
  padding-top: 30px;
  @media only screen and (max-width: 889px) {
    flex-direction: column;
    align-items: center;
    padding: 30px 20px;
  }
`
export const CustomDomainInputWrapper = styled.div`
  position: relative;
  background-color: ${primary.Light};
  border-radius: 5px;
  display: flex;
  margin-right: 11px;
  padding: 3px 3px 3px 0;
  align-items: center;
  width: 100%;
  @media only screen and (max-width: 889px) {
    margin-right: 0;
  }
`

export const CustomDomainInput = styled(Input)`
  input {
    height: 31px;
    border-radius: 5px;
    border: none;
    padding: 10px 15px;
    border: ${({ showErrorBorder, showWarningMessage }) =>
      showErrorBorder
        ? `1px solid ${accentRed}`
        : showWarningMessage
        ? `1px solid ${warningColor}`
        : 'none'};
    ::placeholder {
      font-size: 14px;
      color: #9aa6c8;
    }
    color: ${({ disabled }) => disabled && `${secondary.Dark}`};
  }
`

export const ConnectBtn = styled(CustomButton)`
  ${getDisabledBtnStyles};
  @media only screen and (max-width: 889px) {
    margin-top: 20px;
  }
`
export const MessageWrapper = styled.div`
  width: 100%;
`

export const HttpText = styled.p`
  font-size: 14px;
  color: #7683a8;
  margin: 0 11px;
  min-width: 50px;
`

export const SuccessIcon = styled(Icon)`
  position: absolute;
  right: 10px;
`

export const ConfirmPopupContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
`

export const ConfirmingPopupContent = styled.div`
  text-align: center;
  max-width: 445px;
  padding: 50px 30px 40px 30px;
  @media only screen and (max-width: 768px) {
    padding: 20px 20px 30px 20px;
  }
`

export const ConfirmingQuestion = styled.h4`
  margin: 0 auto;
  max-width: 415px;
  font-size: 20px;
  line-height: 25px;
  font-weight: 600;
`

export const InfoText = styled.p`
  font-size: 16px;
  color: ${secondary.Dark};
  padding-top: 30px;
  margin: 0 auto;
  max-width: 358px;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
  .footer-btn {
    margin: 0 10px;
    font-weight: 500;
  }
`

export const BoldText = styled.span`
  font-weight: 600;
`

export const WWWOptionDescription = styled.div`
  background-color: #f8fbff;
  border-radius: 10px;
  margin: 20px auto 10px;
  @media only screen and (max-width: 889px) {
    margin: 0 20px 20px;
  }
  padding: 20px;
`

export const WWWOptionsTitle = styled.span`
  color: #363f5a;
  font-size: 16px;
  font-weight: 600;
`

export const WWWOptionsDescription = styled.div`
  color: #363f5a;
  font-size: 14px;
  margin-top: 10px;
`
