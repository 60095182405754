import styled, { css } from 'styled-components'
import { globalColors } from '@renderforest/rf-ui-library/consts/colors'

const getActiveCornerStyles = ({ isActive }) =>
  isActive
    ? css`
        display: block;
        &:first-child {
          display: none;
        }
      `
    : ''

export const Container = styled.div`
  width: 100%;
  min-height: 200px;
  display: flex;
  margin: 15px 0;
`

export const TabsWrapper = styled.div`
  max-width: 300px;
  width: 40%;
  display: flex;
  flex-direction: column;
`

export const Content = styled.div`
  width: 60%;
  border-radius: 15px;
  border: 1px solid #387dff;
  z-index: 1;
  background-color: white;
  min-height: 300px;
  ${({ isFirstActive }) =>
    isFirstActive
      ? css`
          border-top-left-radius: 0;
        `
      : ''}
`

export const CornerWrapper = styled.div`
  display: none;
`
export const Image = styled.img`
  position: absolute;

  ${({ isInTop }) =>
    isInTop
      ? css`
          right: 0;
          top: -12px;
          transform: rotate(270deg);
        `
      : css`
          right: 0;
          bottom: -12px;
          transform: rotate(180deg);
        `}
`

const getActibeTabsStyles = ({ isActive }) =>
  isActive
    ? css`
        color: ${globalColors.secondaryExtraDark};
        border: 1px solid #387dff;
        z-index: 2;
        border-right: 0;
        margin-right: -2px;
        &:first-child {
          & ${Image}:first-child {
            display: none;
          }
        }
        .vertical-tabs-title {
          .tooltip-wrapper {
            margin-right: -4px;
          }
        }
        & ${CornerWrapper} {
          display: block;
        }
      `
    : ''

export const Tab = styled.div`
  border-radius: 15px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  margin-right: -12px;
  padding: 20px 15px;
  background-color: white;
  position: relative;
  color: ${globalColors.secondarySemiDark};
  border: 1px solid #dde3f0;
  cursor: pointer;
  &:not(:first-child) {
    margin-top: -2px;
  }
  .vertical-tabs-title {
    .tooltip-wrapper {
      margin: 0 5px 0 auto;
    }
  }

  ${getActibeTabsStyles}
`
