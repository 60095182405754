import styled, { css } from 'styled-components'
import { secondary } from '@website/common/styles/colors'

const textStyles = css`
  font-size: 12px;
  font-weight: 600;
  color: ${secondary.SemiDark};
`

const disabledStyles = css`
  opacity: 0.5;
  pointer-events: none;
`

export const NotificationBanner = styled.div`
  display: flex;
  background-color: #fef7e4;
  border-radius: 5px;
  border: 1px solid ${secondary.Light};
  padding: 10px 15px;
  margin-top: 10px;
  font-size: 14px;
  color: ${secondary.SemiDark};

  & > svg {
    fill: #9e6c01;
    flex-shrink: 0;
    margin-right: 15px;
    margin-top: 5px;
  }
`

export const SwitcherWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0 5px;

  .switcher {
    zoom: 0.8;
    margin-right: 5px;
    background-color: ${({ isSwitcherChecked }) =>
      isSwitcherChecked ? '#3271E6' : '#8493BD'};
  }

  & > span {
    ${textStyles};
    margin-left: 5px;
  }
    
    ${({ isSwitcherDisabled }) =>
      isSwitcherDisabled
        ? css`
            ${disabledStyles};
          `
        : ''}}
`

export const TranslatingLoader = styled.span`
  display: flex;
  align-items: center;
  ${textStyles};
  padding: 0 5px;

  & > span {
    display: inline-block;
    width: 16px;
    height: 16px;
    border: 2px solid #c6c7dd;
    border-radius: 50%;
    box-sizing: border-box;
    border-top-color: white;
    animation: spin 0.8s ease-in-out infinite;
    margin-right: 7px;

    @keyframes spin {
      to {
        transform: rotate(360deg);
      }
    }
  }
`

export const TranslatedLabel = styled.span`
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  color: #2a8735;
  padding: 0 5px;

  & > svg {
    fill: #2a8735;
    margin-right: 3px;
  }
`

export const MobileActionIconWrapper = styled.div`
  display: flex;

  & > svg {
    ${({ isActive }) =>
      isActive
        ? css`
            fill: #3271e6;
          `
        : css`
            fill: #8493bd;
          `}
  }

    ${({ isDisabled }) =>
      isDisabled
        ? css`
            ${disabledStyles};
          `
        : ''}}
`
