// @flow
import React, { memo, useCallback, useContext, useState } from 'react'
import { connect } from 'react-redux'
import { translate } from '@editor/common/utils/translations'

import * as Styled from './styled'
import { SIMPLE, GDPR } from './consts'
import Simple from './containers/Simple'
import { AccordionTitle } from './components'
import { SingleAccordion } from '@editor/common/lib/Accordion'
import { UpgradePopupContext } from '@contexts'
import TooltipWrapper from '@website/common/components/TooltipWrapper'
import { isSubscriber } from '@selectors'
import { LEAVE_TAB_DIALOG_BUTTONS } from '@editor/containers/edit/containers/settings/consts'
import { useDiscardDialog } from '@hooks/useDiscardDialog'
import GDPRComp from './containers/GDPR'
import { GDPRCookieContext, SimpleCookieContext } from './store'

const WithTooltip = memo(({ children, isDisabled }) =>
  isDisabled ? (
    <TooltipWrapper text={translate('disabled_gdpr_banner_tooltip')}>
      {children}
    </TooltipWrapper>
  ) : (
    children
  )
)

const CookiesMobile = ({ isSubscriber, hasChangesInTab, isSimpleDisabled }) => {
  const initialActive = isSubscriber ? GDPR : SIMPLE
  const [activeAccordion, setActiveAccordion] = useState(initialActive)

  const { setUpgradePopupState } = useContext(UpgradePopupContext)
  const {
    actions: { resetGDPRSettings }
  } = useContext(GDPRCookieContext)

  const {
    actions: { resetSimpleSettings }
  } = useContext(SimpleCookieContext)

  const onDiscard = tab => {
    setActiveAccordion(tab)
    tab === 'left' ? resetGDPRSettings() : resetSimpleSettings()
  }

  const onAccordionChange = useDiscardDialog(
    onDiscard,
    {
      id: 'cookies',
      hasChanges: hasChangesInTab
    },
    {
      title: translate('settings_tab_changing_popup_label'),
      buttonTexts: LEAVE_TAB_DIALOG_BUTTONS
    }
  )

  const onExpanderClick = useCallback(
    active => {
      if (active === activeAccordion) {
        return
      }

      if (!isSubscriber && active === GDPR) {
        setUpgradePopupState({
          isOpen: true
        })
        return
      }

      onAccordionChange(active)
    },
    [isSubscriber, onAccordionChange, activeAccordion]
  )

  return (
    <Styled.CookieAccordionsWrapper activeAccordion={activeAccordion}>
      <SingleAccordion
        hideArrow
        id={GDPR}
        title={
          <AccordionTitle
            title={translate('gdpr_banner_label')}
            showUpgradeLabel={!isSubscriber}
          />
        }
        maxHeight={1600}
        className="cookie-accordion"
        isOpen={activeAccordion === GDPR}
        onExpanderClick={onExpanderClick}
      >
        <GDPRComp />
      </SingleAccordion>
      <WithTooltip isDisabled={isSimpleDisabled}>
        <SingleAccordion
          hideArrow
          id={SIMPLE}
          title={<AccordionTitle title={translate('cookie_banner_label')} />}
          className="cookie-accordion"
          disabledClassName="disabled-cookie-accordion"
          isOpen={activeAccordion === SIMPLE}
          disabled={isSimpleDisabled}
          maxHeight={625}
          onExpanderClick={onExpanderClick}
        >
          <Simple />
        </SingleAccordion>
      </WithTooltip>
    </Styled.CookieAccordionsWrapper>
  )
}

const mapStateToProps = state => ({
  isSubscriber: isSubscriber(state)
})

export default connect(mapStateToProps)(CookiesMobile)
