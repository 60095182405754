import React from 'react'
import AcceptBtn from './AcceptBtn'
import DeclineBtn from './DeclineBtn'
import CookieSettings from './CookieSettingsBtn'
import PrivacyPolicyBtn from './PrivacyPolicyBtn'
import { TitleContainer as Title } from '../../../shared-components'
import { translate } from '@editor/common/utils/translations'
import { getLocalizedURL } from '@editor/common/utils/translations'

export const gdprAccordionTabs = [
  {
    type: 'acceptBtn',
    title: translate('accept_btn_text'),
    maxHeight: 220,
    content: <AcceptBtn />
  },
  {
    type: 'privacyPolicyBtn',
    title: (
      <Title
        title={translate('privacy_policy_btn_text')}
        tooltipText={translate('privacy_policy_btn_tooltip')}
      />
    ),
    maxHeight: 700,
    content: <PrivacyPolicyBtn />
  },
  {
    type: 'settingsBtn',
    title: (
      <Title
        title={translate('settings_btn_text')}
        tooltipText={translate('settings_btn_tooltip')}
      />
    ),
    content: <CookieSettings />,
    maxHeight: 320
  },
  {
    type: 'declineBtn',
    title: translate('decline_btn_text'),
    content: <DeclineBtn />,
    maxHeight: 240
  }
]

export const CONSENT_TOOLTIP_IMAGE =
  'https://static.rfstat.com/renderforest/images/website_maker_images/gdpr_compliance_tooltip.jpg'

export const DEFAULT_CONSENT_TEXT =
  'This website uses cookies to improve your experience. By using our website you agree to the use of cookies.'

export const GDPR_BUTTONS_INPUT_CLASSNAME = 'gdpr-buttons-input'

export const NEED_HELP_LINK = `https://www.renderforest.com/${getLocalizedURL(
  '/website-maker/gdpr'
)}`
