/* @flow */
import React from 'react'
import { connect } from 'react-redux'
import { translate } from '@editor/common/utils/translations'
import { selectSitePublishUrl, selectSitePublishedState } from '@selectors'
import { saveSubdomain, setSubdomainErrorMessage } from '@actions/editor'

import * as Styled from './styled'
import { ViewMyWebsite } from '../'
import { TSubdomainContentProps } from './types'
import { SUBDOMAIN_IMG } from '../../consts'

import { useResponsive } from '@shared/hooks'
import { TitleContainer } from '../../../shared-components'
import SubdomainInput from '@editor/common/components/SubdomainInput'

const SubdomainContent = ({
  value,
  isSitePublished,
  sitePublishUrl,
  setValue
}: TSubdomainContentProps) => {
  const isSmallSizes = useResponsive(889)

  return (
    <Styled.SubdomainContent>
      {isSmallSizes && <Styled.Img src={SUBDOMAIN_IMG} alt="subdomain" />}
      <Styled.SubdomainMainContent isPublished={isSitePublished}>
        {isSmallSizes ? (
          <Styled.Title>{translate('enter_your_subdomain_label')}</Styled.Title>
        ) : (
          <TitleContainer
            title={translate('enter_your_subdomain_label')}
            tooltipText={translate('enter_your_subdomain_tooltip_text')}
          />
        )}
        <Styled.ContentDescription>
          {translate('enter_your_subdomain_text')}
        </Styled.ContentDescription>
        <Styled.Form>
          <SubdomainInput value={value} setValue={setValue} />
        </Styled.Form>
      </Styled.SubdomainMainContent>
      {isSitePublished && (
        <ViewMyWebsite siteUrl={sitePublishUrl} isInSubdomain />
      )}
    </Styled.SubdomainContent>
  )
}

const mapStateToProps = state => ({
  isSitePublished: selectSitePublishedState(state),
  sitePublishUrl: selectSitePublishUrl(state)
})

export default connect(mapStateToProps, {
  saveSubdomain,
  setSubdomainErrorMessage
})(SubdomainContent)
