//@flow
import React, { memo, useCallback } from 'react'

import * as Styled from './styled'
import { ItemLanguageBlock, ItemActionsBlock, Button } from '../styled'
import { TPrimaryItemProps } from './types'
import LanguagesDropdown from '../LanguagesDropdown'
import PrimaryIcon from '@editor/common/assets/svgr-icons/Flag.svg'
import DeleteIcon from '@editor/common/assets/svgr-icons/Delete_icon.svg'
import { translate } from '@editor/common/utils/translations'
import { getFlagUrl } from '@website/common/utils/website-languages'

const PrimaryItem = ({
  data,
  possibleLanguages,
  isWebsiteMultilingual,
  isDeleteButtonVisible,
  hasDropdownAnimation,
  setData
}: TPrimaryItemProps) => {
  const { lang: { name, flagCode } = {} } = data || {}

  const onDropdownItemClick = useCallback(
    value => {
      const selectingLanguage = possibleLanguages.find(
        language => `${language.code}-${language.flagCode}` === value
      )

      setData({
        lang: selectingLanguage,
        smLangId: selectingLanguage.id,
        isPrimary: true,
        order: 1,
        status: 'active',
        added: true,
        autoTranslate: selectingLanguage.isAutoTranslateSupported
      })
    },
    [possibleLanguages]
  )

  const deleteLanguage = useCallback(() => {
    setData(null)
  }, [])

  return isWebsiteMultilingual || data ? (
    <Styled.PrimaryItem>
      <ItemLanguageBlock>
        <img src={getFlagUrl(flagCode)} alt={name} />
        <p>{name}</p>
      </ItemLanguageBlock>
      <ItemActionsBlock>
        {isDeleteButtonVisible ? (
          <Button onClick={deleteLanguage}>
            <DeleteIcon />
            <span>{translate('delete_label')}</span>
          </Button>
        ) : (
          <Styled.PrimaryButton>
            <PrimaryIcon />
            <span>{translate('primary_label')}</span>
          </Styled.PrimaryButton>
        )}
      </ItemActionsBlock>
    </Styled.PrimaryItem>
  ) : (
    <LanguagesDropdown
      onClick={onDropdownItemClick}
      possibleLanguages={possibleLanguages}
      hasAnimation={hasDropdownAnimation}
    />
  )
}

export default memo(PrimaryItem)
