// @flow
import React from 'react'
import { connect } from 'react-redux'

import type { TGoogleTagManagerProps } from './types'
import Card from '../Card'
import {
  selectGoogleTagManagerSuccessMessage,
  selectGoogleTagManagerId
} from '@selectors'
import { setGoogleTagManagerId } from '@actions/meta'

const GoogleTagManager = ({
  value,
  setValue,
  googleTagManagerId,
  setGoogleTagManagerId,
  successMessage,
  ...rest
}: TGoogleTagManagerProps) => {
  const hasChanges = value !== googleTagManagerId
  const isConnected = !!googleTagManagerId

  return (
    <Card
      id={googleTagManagerId}
      inputValue={value}
      isConnected={isConnected}
      hasChanges={hasChanges}
      successMessage={successMessage}
      setId={setGoogleTagManagerId}
      setInputValue={setValue}
      {...rest}
    />
  )
}

const mapStateToProps = state => ({
  googleTagManagerId: selectGoogleTagManagerId(state),
  successMessage: selectGoogleTagManagerSuccessMessage(state)
})

const mapDispatchToProps = { setGoogleTagManagerId }

export default connect(mapStateToProps, mapDispatchToProps)(GoogleTagManager)
