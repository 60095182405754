import { translate } from '@editor/common/utils/translations'

export const INITIAL_SIMPLE_COOKIES = {
  isVisible: false,
  consentText:
    'This website uses cookies to improve your experience. By using our website you agree to the use of cookies.',
  acceptBtn: {
    name: 'Accept'
  }
}

export const INITIAL_GDPR_SETTINGS = {
  isVisible: false,
  consentText:
    'This website uses cookies to improve your experience. By using our website you agree to the use of cookies.',
  acceptBtn: {
    name: 'Accept'
  },
  declineBtn: {
    name: 'Decline all',
    isVisible: false
  },
  settingsBtn: {
    name: 'Settings',
    isVisible: true
  },
  privacyPolicyBtn: {
    name: 'Privacy Policy',
    isVisible: true,
    link: '/',
    showInFooter: false
  },
  settings: {
    title: 'Cookie Settings',
    description:
      'Our website uses various cookies, each of which has a specific function. You can manage them below.',
    confirmBtnName: 'CONFIRM',
    categories: [
      {
        type: 'essential',
        title: 'Essential cookies',
        description:
          'Essential cookies provide the basic functions of the website such as security, network management, and accessibility.'
      },
      {
        type: 'analytics',
        title: 'Analytics Cookies',
        description:
          'These cookies collect information about how you use and interact with our website, to improve the website functions. '
      },
      {
        type: 'marketing',
        title: 'Marketing cookies',
        description:
          'These cookies are used to track your online activity and deliver advertisements more relevant to you and your interests.'
      },
      {
        type: 'functional',
        title: 'Functional Cookies',
        description:
          'These cookies allow our website to remember choices you have made in the past so that you have a more efficient experience. '
      }
    ]
  }
}

export const PRIVACY_POLICY_LINK_TYPES = {
  EXTERNAL: 'external',
  INTERNAL: 'internal'
}
