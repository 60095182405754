//@flow
import React, { memo, useCallback, useContext } from 'react'

import { Button } from '../../styled'
import { TSetPrimaryButtonProps } from './types'
import PrimaryIcon from '@editor/common/assets/svgr-icons/Flag.svg'
import { ConfirmDialogContext } from '@contexts/ConfirmDialog.context'
import { translate } from '@editor/common/utils/translations'
import { transformTextVariables } from '@editor/common/utils/text-transformation'
import { BoldComp } from '@editor/common/styled-components/simple-tags'

const SetPrimaryButton = ({
  language,
  isDisabled,
  onConfirm
}: TSetPrimaryButtonProps) => {
  const { setProps } = useContext(ConfirmDialogContext)

  const _onConfirm = useCallback(() => {
    onConfirm()
    setProps({ isOpen: false })
  }, [onConfirm])

  const onClick = useCallback(() => {
    if (isDisabled) return

    setProps({
      isOpen: true,
      title: transformTextVariables(
        translate('primary_language_change_confirm_label'),
        [
          {
            value: language,
            Comp: BoldComp
          }
        ]
      ),
      content: transformTextVariables(
        translate('primary_language_change_confirm_description'),
        [
          {
            value: `${language} ${translate('language_label_lowercase')}`,
            Comp: BoldComp
          }
        ]
      ),
      buttonTexts: {
        confirm: translate('change_label'),
        cancel: translate('cancel_label')
      },
      onConfirm: _onConfirm
    })
  }, [onConfirm])

  return (
    <Button onClick={onClick} disabled={isDisabled}>
      <PrimaryIcon />
      <span>{translate('set_primary_label')}</span>
    </Button>
  )
}

export default memo(SetPrimaryButton)
