// @flow
import React from 'react'
import moment from 'moment'

export const getDateExpiry = (endDate: string) => {
  return moment(endDate).diff(undefined, 'days')
}

export const isValidSearchString = (str: string): boolean => {
  const regExp = new RegExp(/^$|^[a-zA-Z0-9-.]+$/)
  return regExp.test(str)
}
