/* @flow */

import React, { useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import { translate } from '@editor/common/utils/translations'
import { BoldComp } from '@editor/common/styled-components/simple-tags'
import { transformTextVariables } from '@src/editor/common/utils/text-transformation'

import * as Styled from './styled'
import {
  ButtonsWrapper,
  ConfirmingPopupContent,
  ConfirmingQuestion
} from '../../styled'
import { TSSLPopupProps } from './types'
import { FOUR_HOURS_IN_MS } from '../../consts'
import { getSiteData, removeHttpsConfigs } from '@actions/editor'
import SemrushDependedPopup from '@editor/common/components/SemrushDependedPopup'
import Button from '@renderforest/rf-ui-library/atoms/Button'

const SSLPopup = ({
  isOpen,
  onClose,
  removeHttpsConfigs,
  sslHours,
  sslDatesDiffInMs,
  getSiteData
}: TSSLPopupProps) => {
  useEffect(() => {
    getSiteData()
  }, [sslDatesDiffInMs])

  const onDisableBtnClick = useCallback(() => {
    removeHttpsConfigs()
    onClose()
  }, [removeHttpsConfigs, onClose])
  const showInfo = sslDatesDiffInMs < FOUR_HOURS_IN_MS

  return (
    <SemrushDependedPopup isOpen={isOpen} onClose={onClose}>
      <ConfirmingPopupContent>
        <ConfirmingQuestion>
          {translate('remove_free_ssl_cert_label')}
        </ConfirmingQuestion>
        {showInfo && (
          <Styled.SSLInfoTextWrapper>
            <Styled.SSLInfoText>
              {transformTextVariables(
                translate('after_removing_certificate_you_can_add_label'),
                [
                  {
                    value: translate('please_note_label'),
                    Comp: BoldComp
                  },
                  {
                    value: `${sslHours}.`,
                    Comp: BoldComp
                  }
                ]
              )}
            </Styled.SSLInfoText>
          </Styled.SSLInfoTextWrapper>
        )}
        <ButtonsWrapper infoText={translate('ssl_popup_info_label')}>
          <Button
            onClick={onClose}
            type="transparent"
            size="small"
            className="footer-btn"
          >
            {translate('cancel_label')}
          </Button>
          <Button
            onClick={onDisableBtnClick}
            type="secondary"
            size="small"
            className="footer-btn"
          >
            {translate('remove_label', true)}
          </Button>
        </ButtonsWrapper>
      </ConfirmingPopupContent>
    </SemrushDependedPopup>
  )
}

export default connect(null, { removeHttpsConfigs, getSiteData })(SSLPopup)
