//@flow
import React, { memo, useCallback, useContext } from 'react'

import { MobileDetectContext } from '@contexts'
import EyeIcon from '@editor/common/assets/svgr-icons/Preview_icon.svg'
import Switcher from '@editor/common/lib/Switcher/Switcher'
import { translate } from '@editor/common/utils/translations'
import * as Styled from './styled'
import { TLanguageVisibilitySwitcherProps } from './types'

const LanguageVisibilitySwitcher = ({
  isLanguageVisible,
  isDisabled,
  setLanguageStatus,
  changeLanguageVisibility
}: TLanguageVisibilitySwitcherProps) => {
  const isMobile = useContext(MobileDetectContext)

  const toggleVisibility = useCallback(() => {
    if (isDisabled) return

    const status = isLanguageVisible ? 'hidden' : 'active'
    setLanguageStatus(status)
    changeLanguageVisibility(status)
  }, [isLanguageVisible, changeLanguageVisibility, isDisabled])

  return isMobile ? (
    <Styled.MobileActionIconWrapper
      isActive={isLanguageVisible}
      onClick={toggleVisibility}
      isDisabled={isDisabled}
    >
      <EyeIcon />
    </Styled.MobileActionIconWrapper>
  ) : (
    <Styled.SwitcherWrapper
      onClick={toggleVisibility}
      isSwitcherChecked={isLanguageVisible}
      isSwitcherDisabled={isDisabled}
    >
      <Switcher
        className="switcher"
        size="small"
        isChecked={isLanguageVisible}
      />
      {!isMobile ? (
        isLanguageVisible ? (
          <span>{translate('visible_label')}</span>
        ) : (
          <span>{translate('hidden_label')}</span>
        )
      ) : null}
    </Styled.SwitcherWrapper>
  )
}

export default memo(LanguageVisibilitySwitcher)
