import styled from 'styled-components'

import { Button } from '../styled'
import { secondary, white } from '@website/common/styles/colors'

export const PrimaryItem = styled.div`
  position: relative;
  height: 38px;
  padding: 0 10px 0 15px;
  border: 1px solid ${secondary.Light};
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${white};
`

export const PrimaryButton = styled(Button)`
  color: #3271e6;
  pointer-events: none;
  cursor: default;

  & > svg {
    fill: #3271e6;
  }
`
