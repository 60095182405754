import styled, { css } from 'styled-components'
import { primary, white } from '@website/common/styles/colors'

export const getDisabledBtnStyles = ({ disabled }) =>
  disabled
    ? css`
        opacity: 0.7;
        box-shadow: none;
        cursor: default;
        border-color: gray;
        pointer-events: none;
      `
    : ''

export const Container = styled.div`
  padding: 30px 40px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @media only screen and (max-width: 768px) {
    padding: 30px 20px;
  }
`

export const CustomButton = styled.button`
  display: flex;
  align-items: center;
  padding: 9px 20px 10px;
  cursor: pointer;
  background-color: ${primary.Light};
  border: none;
  border-radius: 5px;
  outline: none;
  color: ${primary.Dark};
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  &:hover {
    background-color: ${primary.Dark};
    color: ${white};
    box-shadow: 0 3px 9px #387dff4d;
  }
`

export const PleaseNote = styled.span`
  color: #545f7e;
  font-size: 14px;
  font-weight: 600;
`
