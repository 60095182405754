//@flow
import React, { useCallback, useState, useContext } from 'react'
import { connect } from 'react-redux'
import * as Styled from './styled'
import * as CommonStyled from '../common-styled'
import { translate } from '@editor/common/utils/translations'

import CustomizeIcon from '@editor/common/assets/svgr-icons/Customize_icon.svg'
import { ButtonWithIcon } from '@editor/containers/edit/containers/widgets/contents/styled'
import { openGdprSettingsCustomizationWindow } from '@actions/editor'
import { TCookieSettingsProps } from './types'
import { GDPRCookieContext, INITIAL_GDPR_SETTINGS } from '../../../store'

const checkboxItem = {
  label: translate('settings_checkbox_label')
}

const CookieSettingsBtn = ({
  openGdprSettingsCustomizationWindow
}: TCookieSettingsProps) => {
  const {
    state: {
      settingsBtn: { name, isVisible }
    },
    actions: { setSettingsBtnName, setSettingsBtnVisibility }
  } = useContext(GDPRCookieContext)
  const handleVisibilityChange = useCallback(() => {
    setSettingsBtnVisibility(!isVisible)
  }, [isVisible])

  return (
    <CommonStyled.Container>
      <CommonStyled.Label>
        {translate('settings_btn_description')}
      </CommonStyled.Label>
      <CommonStyled.Checkbox
        isChecked={isVisible}
        className="gdpr-settings-checkbox"
        onChange={handleVisibilityChange}
        item={checkboxItem}
      />
      <CommonStyled.InputLabel>
        {translate('settings_btn_label')}
      </CommonStyled.InputLabel>
      <CommonStyled.Input
        isRequired
        count={19}
        defaultValue={INITIAL_GDPR_SETTINGS.settingsBtn.name}
        value={name}
        disabled={!isVisible}
        onChange={setSettingsBtnName}
      />
      <Styled.CustomizeButton>
        <Styled.DisabledOverlay disabled={!isVisible} />
        <ButtonWithIcon onClick={openGdprSettingsCustomizationWindow}>
          <CustomizeIcon />
          <Styled.ButtonText>
            {translate('customize_settings_checkbox_label')}
          </Styled.ButtonText>
        </ButtonWithIcon>
      </Styled.CustomizeButton>
    </CommonStyled.Container>
  )
}

const mapDispatchToProps = {
  openGdprSettingsCustomizationWindow
}

export default connect(null, mapDispatchToProps)(CookieSettingsBtn)
