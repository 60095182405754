import styled from 'styled-components'
import { scrollBarStyles } from '@editor/common/styles'

export const SubdomainContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  overflow-y: auto;
  ${scrollBarStyles};
`

export const SubdomainMainContent = styled.div`
  padding: 30px;
  @media only screen and (max-width: 889px) {
    padding: 0 10px 40px;
  }
`

export const Img = styled.img`
  width: 90px;
  height: 107px;
  display: block;
  margin: 5px auto 10px;
`

export const Title = styled.p`
  color: #363f5a;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
`

export const ContentDescription = styled.p`
  font-size: 14px;
  color: #545f7e;
  margin-top: 10px;
  @media only screen and (max-width: 889px) {
    text-align: center;
  }
`
export const Form = styled.div`
  display: flex;
  align-items: center;
  padding-top: 30px;
  @media only screen and (min-width: 768px) and (max-width: 889px) {
    padding-top: 20px;
    justify-content: center;
  }
  @media only screen and (max-width: 767px) {
    display: block;
  }
`

export const InputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-grow: 1;
`
