// @flow

import React, { memo, useContext, useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import * as CommonStyles from '../common-styled'
import * as Styled from './styled'
import Radio from '@renderforest/rf-ui-library/molecules/Radio'
import PageChooseDropdown from '@editor/common/components/PageChooserDropdown'
import { GDPRCookieContext } from '../../../store/Provider'
import { selectHasFooterInProject } from '@selectors'
import { isValidUrl } from '@website/common/utils'
import { translate } from '@editor/common/utils/translations'

import {
  EXTERNAL_INPUT_PLACEHOLDER,
  DEFAULT_EXTERNAL_URL,
  DEFAULT_INTERNAL_URL
} from './consts'
import {
  INITIAL_GDPR_SETTINGS,
  PRIVACY_POLICY_LINK_TYPES
} from '../../../store/consts'
import { GDPR_BUTTONS_INPUT_CLASSNAME } from '../consts'

const INVALID_URL_MESSAGE = translate('enter_valid_url_label')

const checkboxItem = {
  label: translate('privacy_policy_checkbox_label')
}

const footerCheckboxItem = {
  label: translate('privacy_policy_add_to_footer_label')
}

const EXTERNAL_RADIO_LIST = [
  {
    label: translate('privacy_policy_web_url_label'),
    value: PRIVACY_POLICY_LINK_TYPES.EXTERNAL
  }
]
const INTERNAL_RADIO_LIST = [
  {
    label: translate('privacy_policy_page_from_website_label'),
    value: PRIVACY_POLICY_LINK_TYPES.INTERNAL
  }
]

const PrivacyPolicyBtn = ({}) => {
  const [linkErrorMessage, setLinkErrorMessage] = useState('')

  const {
    state: { privacyPolicyBtn },
    actions: {
      setPrivacyPolicyBtnName,
      setPrivacyPolicyLink,
      setPrivacyPolicyBtnVisibility,
      setPrivacyPolicyBtnVisibilityInFooter
    },
    privacyPolicyLinkType,
    setPrivacyPolicyLinkType
  } = useContext(GDPRCookieContext)

  const hasFooterInProject = useSelector(selectHasFooterInProject)

  const isInternalLink =
    privacyPolicyLinkType === PRIVACY_POLICY_LINK_TYPES.INTERNAL
  const isContentDisabled = !privacyPolicyBtn.isVisible

  const handleVisibilityChange = useCallback(() => {
    const newValue = !privacyPolicyBtn.isVisible
    setPrivacyPolicyBtnVisibility(newValue)
    !newValue && setPrivacyPolicyBtnVisibilityInFooter(false)
  }, [privacyPolicyBtn.isVisible])

  const setLinkTypeAsExternal = useCallback(() => {
    setPrivacyPolicyLink(DEFAULT_EXTERNAL_URL)
    setPrivacyPolicyLinkType(PRIVACY_POLICY_LINK_TYPES.EXTERNAL)
  }, [])

  const setLinkTypeAsInternal = useCallback(() => {
    setPrivacyPolicyLink(DEFAULT_INTERNAL_URL)
    setPrivacyPolicyLinkType(PRIVACY_POLICY_LINK_TYPES.INTERNAL)
  }, [])

  const handleVisibilityChangeInFooter = useCallback(() => {
    setPrivacyPolicyBtnVisibilityInFooter(!privacyPolicyBtn.showInFooter)
  }, [privacyPolicyBtn.showInFooter])

  const handleExternalUrlChange = useCallback(
    value => {
      if (linkErrorMessage) {
        setLinkErrorMessage('')
      }
      setPrivacyPolicyLink(value)
    },
    [linkErrorMessage]
  )

  const validateExternalUrl = useCallback(e => {
    const { value } = e.target
    if (!isValidUrl(value)) {
      setLinkErrorMessage(INVALID_URL_MESSAGE)
    }
  }, [])

  return (
    <CommonStyles.Container>
      <CommonStyles.Label>
        {translate('privacy_policy_btn_description')}
      </CommonStyles.Label>
      <CommonStyles.Checkbox
        isChecked={privacyPolicyBtn.isVisible}
        onChange={handleVisibilityChange}
        item={checkboxItem}
      />
      <CommonStyles.DisablingWrapper disabled={isContentDisabled}>
        <CommonStyles.InputLabel>
          {translate('privacy_policy_btn_label')}
        </CommonStyles.InputLabel>
        <CommonStyles.Input
          isRequired
          count={19}
          defaultValue={INITIAL_GDPR_SETTINGS.privacyPolicyBtn.name}
          className={GDPR_BUTTONS_INPUT_CLASSNAME}
          disabled={isContentDisabled}
          value={privacyPolicyBtn.name}
          onChange={setPrivacyPolicyBtnName}
        />
        <Styled.Line />
        <CommonStyles.InputLabel>
          {translate('privacy_policy_link_label')}
        </CommonStyles.InputLabel>
        <Styled.LinkContainer>
          <div>
            <Radio
              onChange={setLinkTypeAsExternal}
              checked={privacyPolicyLinkType}
              list={EXTERNAL_RADIO_LIST}
            />
            <CommonStyles.Input
              isRequired
              errorMessage={linkErrorMessage}
              onBlur={validateExternalUrl}
              defaultValue={DEFAULT_EXTERNAL_URL}
              disabled={isInternalLink || isContentDisabled}
              value={isInternalLink ? '' : privacyPolicyBtn.link}
              onChange={handleExternalUrlChange}
              placeholder={EXTERNAL_INPUT_PLACEHOLDER}
            />
          </div>
          <div>
            <Radio
              onChange={setLinkTypeAsInternal}
              checked={privacyPolicyLinkType}
              list={INTERNAL_RADIO_LIST}
            />
            <PageChooseDropdown
              key={!isInternalLink}
              disabled={!isInternalLink || isContentDisabled}
              onChange={setPrivacyPolicyLink}
              value={isInternalLink ? privacyPolicyBtn.link : '/'}
            />
          </div>
        </Styled.LinkContainer>
        <Styled.Line />
        <Styled.TooltipWrapper
          text={
            !isContentDisabled && !hasFooterInProject
              ? translate('privacy_policy_add_to_footer_tooltip')
              : ''
          }
          position="top"
        >
          <CommonStyles.Checkbox
            disabled={!isContentDisabled && !hasFooterInProject}
            isChecked={privacyPolicyBtn.showInFooter}
            onChange={handleVisibilityChangeInFooter}
            item={footerCheckboxItem}
          />
        </Styled.TooltipWrapper>
        <Styled.AddLinkInFooterDescription
          disabled={!isContentDisabled && !hasFooterInProject}
        >
          {translate('privacy_policy_add_to_footer_description')}
        </Styled.AddLinkInFooterDescription>
      </CommonStyles.DisablingWrapper>
    </CommonStyles.Container>
  )
}

export default memo(PrivacyPolicyBtn)
