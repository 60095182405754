import styled, { css } from 'styled-components'
import {
  Description as _Description,
  Input as _Input
} from '../../../shared-components'
import LibCheckbox from '@renderforest/rf-ui-library/molecules/Checkbox'

export const Container = styled.div`
  width: 100%;
  height: auto;
`
export const Label = styled(_Description)`
  padding: 0;
`
export const InputLabel = styled(_Description)`
  padding: 20px 0 10px 0;
  @media only screen and (max-width: 768px) {
    padding: 20px 0 10px;
    margin-top: 15px;
  }
`

export const Input = styled(_Input)`
  width: 100%;
  color: #363f5a;
`

export const Checkbox = styled(LibCheckbox)`
  position: relative;
  margin: 20px 0 0;
  & div {
    margin-right: 10px;
    font-weight: 600;
    font-size: 14px;
    &:first-child {
      min-width: 18px;
    }
  }
  ${({ disabled }) =>
    disabled
      ? css`
          opacity: 0.6;
          pointer-events: none;
          cursor: default;
        `
      : ''}
`

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background: #dde3f0;
  margin: 10px 0;
`

export const DisablingWrapper = styled.div`
  ${({ disabled }) =>
    disabled
      ? css`
          pointer-events: none;
          cursor: default;
          opacity: 0.6;
        `
      : ''}
`
