// @flow
import React, { memo, useState, useContext } from 'react'
import { connect } from 'react-redux'

import * as Styled from './styled'
import type { TDomainMobileProps } from './types'
import TooltipWrapper from '@website/common/components/TooltipWrapper'
import { translate } from '@editor/common/utils/translations'

import { ACCORDION_TYPES } from '../../consts'
import { UPGRADE_LABEL } from '../../consts'
import { UpgradePopupContext } from '@contexts'
import { CustomDomainContent, SubdomainContent } from '../index'
import {
  isSubscriber,
  selectDomain,
  selectSubdomainErrorMessage
} from '@selectors'
import { setSubdomainErrorMessage } from '@actions/editor'

const WithTooltip = memo(({ children, isDisabled }) =>
  isDisabled ? (
    <TooltipWrapper
      position="top"
      text={translate('disabled_subdomain_tooltip_text')}
      color="#363F5A"
      className="card-tooltip"
    >
      {children}
    </TooltipWrapper>
  ) : (
    children
  )
)

const WithUpgradeOverlay = memo(({ children, isSubscriber, handleClick }) =>
  !isSubscriber ? (
    <Styled.CardWrapper>
      <div className="overlay" onClick={handleClick} />
      {children}
    </Styled.CardWrapper>
  ) : (
    children
  )
)

const DomainMobile = ({
  isSubscriber,
  domain,
  subdomainValue,
  customDomainValue,
  subdomainErrorMessage,
  setSubdomainErrorMessage,
  setSubdomainValue,
  setCustomDomainValue
}: TDomainMobileProps) => {
  const initialActive = isSubscriber
    ? ACCORDION_TYPES.customDomain
    : ACCORDION_TYPES.subdomain
  const [active, setActive] = useState(initialActive)
  const { setUpgradePopupState } = useContext(UpgradePopupContext)

  const handleChange = type => value => {
    if (isSubscriber && !domain && value === null) {
      if (type === ACCORDION_TYPES.customDomain) {
        setActive(ACCORDION_TYPES.subdomain)
      }

      if (type === ACCORDION_TYPES.subdomain) {
        setActive(ACCORDION_TYPES.customDomain)
      }
      return
    }

    if (value === null) {
      setActive(null)
      return
    }

    setActive(type)

    if (subdomainErrorMessage) {
      setSubdomainErrorMessage(null)
      setSubdomainValue('')
    }
  }

  return (
    <Styled.CardsWrapper>
      {!isSubscriber ? (
        <Styled.UpgradeLabel src={UPGRADE_LABEL} alt="upgrade" />
      ) : null}
      <WithUpgradeOverlay
        isSubscriber={isSubscriber}
        handleClick={() =>
          setUpgradePopupState({
            isOpen: true
          })
        }
      >
        <Styled.Card
          items={[
            {
              label: translate('add_custom_domain_label'),
              value: (
                <CustomDomainContent
                  value={customDomainValue}
                  setValue={setCustomDomainValue}
                />
              )
            }
          ]}
          disabled={!isSubscriber}
          isAlwaysOpen={isSubscriber && !!domain}
          isSelected={active === ACCORDION_TYPES.customDomain}
          value={active === ACCORDION_TYPES.customDomain ? 0 : null}
          onChange={handleChange(ACCORDION_TYPES.customDomain)}
        />
      </WithUpgradeOverlay>
      <WithTooltip isDisabled={!!domain}>
        <Styled.Card
          items={[
            {
              label: translate('use_free_domain_label'),
              value: (
                <SubdomainContent
                  value={subdomainValue}
                  setValue={setSubdomainValue}
                />
              )
            }
          ]}
          disabled={!!domain}
          showDisabledStyles
          isAlwaysOpen={!isSubscriber}
          isSelected={active === ACCORDION_TYPES.subdomain}
          value={active === ACCORDION_TYPES.subdomain ? 0 : null}
          onChange={handleChange(ACCORDION_TYPES.subdomain)}
        />
      </WithTooltip>
    </Styled.CardsWrapper>
  )
}

const mapStateToProps = state => ({
  isSubscriber: isSubscriber(state),
  domain: selectDomain(state),
  subdomainErrorMessage: selectSubdomainErrorMessage(state)
})

export default connect(mapStateToProps, { setSubdomainErrorMessage })(
  DomainMobile
)
