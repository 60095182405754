import styled from 'styled-components'
import LibButton from '@editor/common/lib/Button'

import { InputWrapper as _InputWrapper } from '../../styled'

export const Button = styled(LibButton)`
  white-space: nowrap;
`

export const InputWrapper = styled(_InputWrapper)`
  .ads-textarea {
    textarea {
      height: 100px;
      border-radius: 5px;
    }
  }
`
