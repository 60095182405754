// @flow
import React, { useCallback } from 'react'
import { connect } from 'react-redux'
import { translate } from '@editor/common/utils/translations'

import * as Styled from './styled'
import { TNoDomainsProps } from './types'
import { openDomainPage } from '@actions/editor'
import { NO_DOMAINS_IMG_PATH } from '../../consts'
import { TextIconButton } from '@renderforest/rf-ui-library/molecules/TextIconButton'

const NoDomains = ({ openDomainPage, onClose }: TNoDomainsProps) => {
  const onBuyDomainBtnClick = useCallback(() => {
    openDomainPage()
    onClose()
  }, [openDomainPage, onClose])
  return (
    <Styled.NoDomainsWrapper>
      <Styled.NoDomainsText size="large" color="secondaryDark" weight={600}>
        {translate('you_have_no_domain_label')}
      </Styled.NoDomainsText>
      <Styled.NoDomainsImage src={NO_DOMAINS_IMG_PATH} alt="noDomains" />
      <TextIconButton
        iconProps={{
          color: 'white',
          name: 'add',
          size: 'small'
        }}
        isIconLeft
        onClick={onBuyDomainBtnClick}
        size="small"
        textProps={{
          color: 'white',
          size: 'small'
        }}
        type="secondary"
      >
        {translate('buy_domain_label')}
      </TextIconButton>
    </Styled.NoDomainsWrapper>
  )
}

export default connect(null, { openDomainPage })(NoDomains)
