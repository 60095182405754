//@flow
import React, { memo, useCallback, useContext, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import { MobileDetectContext } from '@contexts'
import CheckIcon from '@editor/common/assets/svgr-icons/circle_check.svg'
import { transformTextVariables } from '@editor/common/utils/text-transformation'
import { BoldComp } from '@editor/common/styled-components/simple-tags'
import Switcher from '@editor/common/lib/Switcher/Switcher'
import { translate } from '@editor/common/utils/translations'
import { TRANSLATED, TRANSLATING } from '@editor/conf/consts'
import AutoTranslationIcon from '@editor/common/assets/svgr-icons/AutoTranslation.svg'
import { useDidUpdateEffect } from '@hooks/useDidUpdateEffect'
import { selectLanguageTranslationState } from '@selectors'
import TooltipWrapper from '@website/common/components/TooltipWrapper'
import { Divider } from '../styled'
import * as Styled from './styled'
import { TAutoTranslationSwitcherProps } from './types'

const AutoTranslationSwitcher = ({
  language,
  languageCode,
  isNewAddedLanguage,
  isEnabled,
  isAutoTranslateSupported,
  setIsAutoTranslationEnabled,
  autoTranslateLanguage
}: TAutoTranslationSwitcherProps) => {
  const isMobile = useContext(MobileDetectContext)
  const languageTranslationState = useSelector(state =>
    selectLanguageTranslationState(state, languageCode)
  )

  const isTranslating = languageTranslationState === TRANSLATING
  const isTranslated = languageTranslationState === TRANSLATED
  const isChecked = isAutoTranslateSupported && isEnabled

  const [isTranslatedLabelVisible, setTranslatedLabelVisibility] =
    useState(false)

  const timeoutRef = useRef(null)

  useDidUpdateEffect(() => {
    if (isTranslated) {
      setTranslatedLabelVisibility(true)
      timeoutRef.current = setTimeout(
        () => setTranslatedLabelVisibility(false),
        7000
      )
    } else {
      setTranslatedLabelVisibility(false)
    }

    return () => {
      clearTimeout(timeoutRef.current)
    }
  }, [isTranslated])

  const toggle = useCallback(() => {
    if (!isAutoTranslateSupported) return

    setIsAutoTranslationEnabled(!isEnabled)
    autoTranslateLanguage(!isEnabled)
  }, [isEnabled])

  return (
    <>
      {isTranslating ? (
        <Styled.TranslatingLoader>
          <span />
          {translate('translating_label')}
        </Styled.TranslatingLoader>
      ) : isTranslatedLabelVisible ? (
        <Styled.TranslatedLabel>
          <CheckIcon />
          {translate('translated_label')}
        </Styled.TranslatedLabel>
      ) : isNewAddedLanguage ? (
        <TooltipWrapper
          text={
            isAutoTranslateSupported
              ? translate('auto_translation_description')
              : transformTextVariables(
                  translate('auto_translation_not_supported_message'),
                  [
                    {
                      value: language,
                      Comp: BoldComp
                    }
                  ]
                )
          }
        >
          {isMobile ? (
            <Styled.MobileActionIconWrapper
              isActive={isChecked}
              isDisabled={!isAutoTranslateSupported}
              onClick={toggle}
            >
              <AutoTranslationIcon />
            </Styled.MobileActionIconWrapper>
          ) : (
            <Styled.SwitcherWrapper
              onClick={toggle}
              isSwitcherChecked={isChecked}
              isSwitcherDisabled={!isAutoTranslateSupported}
            >
              <Switcher
                className="switcher"
                size="small"
                isChecked={isChecked}
              />
              <span>{translate('auto_translation_label')}</span>
            </Styled.SwitcherWrapper>
          )}
        </TooltipWrapper>
      ) : null}
      {isTranslating || isTranslatedLabelVisible || isNewAddedLanguage ? (
        <Divider />
      ) : null}
    </>
  )
}

export default memo(AutoTranslationSwitcher)
