/* @flow */
import React, { useState, useMemo } from 'react'
import { connect } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'

import * as Styled from './styled'
import { PleaseNote } from '../../../../styled'
import { TDNSDetailsProps } from './types'
import {
  DNS_TEXT,
  NEED_HELP_LINK,
  CNAME_DESCRIPTION_STEPS,
  ARECORD_DESCRIPTION_STEPS,
  WWW_OPTIONS_IMG_SRC
} from './consts'
import { RENDERFOREST_IPS, RENDERFOREST_SITES } from '../../consts'
import { SmallTable, Table } from './Table'
import Icon from '@website/common/components/Icon'
import { Tabs } from '@editor/common/components/Tabs'
import { primary } from '@website/common/styles/colors'
import InfoIcon from '@editor/common/assets/svgr-icons/Info_icon.svg'
import {
  TitleContainer,
  TooltipContent
} from '@editor/containers/edit/containers/settings/contents/shared-components'
import { TooltipWrapper } from '@website/common/components/TooltipWrapper'
import { useResponsive } from '@shared/hooks'
import { getInitialSubdomain, getSiteData, selectDomain } from '@selectors'
import {
  replaceVariableWithStyles,
  transformTextVariables
} from '@src/editor/common/utils/text-transformation'
import { translate } from '@editor/common/utils/translations'
import { BoldComp } from '@editor/common/styled-components/simple-tags'

const dns_details_text = transformTextVariables(translate('dns_details_text'), [
  {
    value: translate('page_settings_note_label_tr1'),
    Comp: BoldComp
  },
  { value: 'DNS' },
  {
    value: `48 ${translate('hours')}`,
    Comp: BoldComp
  }
])

const tabsData = [
  {
    label: 'CNAME',
    type: 'CNAME',
    tooltipText: translate('cname_tooltip_text'),
    descriptionSteps: CNAME_DESCRIPTION_STEPS
  },
  {
    label: 'A record',
    type: 'A',
    descriptionSteps: ARECORD_DESCRIPTION_STEPS
  }
]

const DNSDetails = ({ subdomain, isRfDomain, domain }: TDNSDetailsProps) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const isSmallSizes = useResponsive(889)

  const {
    params: { siteId }
  } = useRouteMatch()

  const getTabHeaderItems = () => {
    return tabsData.map((tab, i) => {
      return {
        label: (
          <Styled.TabLabelWrapper
            isActive={tabsData[activeTabIndex].label === tab.label}
          >
            {tab.label}
            {tab.tooltipText && (
              <TooltipWrapper
                className="tooltip"
                position="right"
                text={tab.tooltipText}
                color="#363F5A"
              >
                <InfoIcon className="info-icon" />
              </TooltipWrapper>
            )}
          </Styled.TabLabelWrapper>
        ),
        value: i
      }
    })
  }

  const getRecordValue = () => {
    if (tabsData[activeTabIndex].type === 'A') {
      const idx = siteId % 2
      return RENDERFOREST_IPS[idx]
    }
    return `${subdomain}${RENDERFOREST_SITES}`
  }

  return (
    <Styled.DNSContainer>
      <TitleContainer
        title={transformTextVariables(translate('dns_details_label'), [
          {
            value: 'DNS',
            Comp: BoldComp
          }
        ])}
        tooltipText={translate('dns_details_tooltip_text')}
      />
      <Styled.DNSTextWrapper>
        <Styled.DNSText>{dns_details_text}</Styled.DNSText>
      </Styled.DNSTextWrapper>
      <Styled.TabsWrapper>
        <Styled.TabsHeader>
          <Tabs
            data={getTabHeaderItems()}
            activeTab={activeTabIndex}
            setActiveTab={index => setActiveTabIndex(index)}
          />
          <Styled.NeedHelpLink href={NEED_HELP_LINK} target="_blank">
            <Icon
              name="help"
              color={primary.Dark}
              size={isSmallSizes ? 'large' : 'normal'}
            />
            {!isSmallSizes && translate('need_help_label')}
          </Styled.NeedHelpLink>
        </Styled.TabsHeader>
        <Styled.TabTitle>
          {translate('configure_your_own_domain_label')}
        </Styled.TabTitle>
        {tabsData[activeTabIndex].descriptionSteps.map(
          ({ text, tooltip }, index) => (
            <Styled.DescriptionWrapper key={index}>
              <Styled.GreenCircle />
              <Styled.Description>{text}</Styled.Description>
              {!isRfDomain && tooltip ? (
                <Styled.WWWOptionTooltipWrapper
                  position="right"
                  content={
                    <TooltipContent
                      text={tooltip}
                      imgSrc={WWW_OPTIONS_IMG_SRC}
                    />
                  }
                  color="#363F5A"
                >
                  <InfoIcon className="info-icon" />
                </Styled.WWWOptionTooltipWrapper>
              ) : null}
            </Styled.DescriptionWrapper>
          )
        )}
        {isSmallSizes ? (
          <SmallTable
            type={tabsData[activeTabIndex].type}
            value={getRecordValue()}
          />
        ) : (
          <Table
            type={tabsData[activeTabIndex].type}
            value={getRecordValue()}
          />
        )}
        <Styled.DescriptionWrapper>
          <Styled.GreenCircle />
          <Styled.Description>
            {translate('save_your_changes_label')}
          </Styled.Description>
        </Styled.DescriptionWrapper>
      </Styled.TabsWrapper>
    </Styled.DNSContainer>
  )
}

const mapStateToProps = state => ({
  domain: selectDomain(state),
  subdomain: getInitialSubdomain(state),
  isRfDomain: getSiteData(state).isRfDomain
})

export default connect(mapStateToProps)(DNSDetails)
