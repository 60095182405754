import styled from 'styled-components'
import { scrollBarStyles } from '@editor/common/styles'
import {
  Description as _Description,
  TitleContainer as SharedTitle
} from '../../../shared-components'

export const Container = styled.div`
  ${scrollBarStyles};
  width: 100%;
  height: 100%;
  padding: 30px;
  box-sizing: border-box;
  overflow-y: auto;

  .simple-cookie-input {
    width: 500px;
  }

  @media only screen and (max-width: 768px) {
    padding: 0 10px 20px 10px;

    .simple-cookie-input {
      width: 100%;
      margin-bottom: 20px;
    }
  }
`

export const Title = styled(SharedTitle)`
  max-width: 265px;
`

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;

  div[role='switch'] {
    margin-left: 15px;
  }
`

export const Description = styled(_Description)`
  padding: 20px 0 10px 0;
`

export const InputLabel = styled(_Description)`
  padding: 20px 0 10px 5px;
`

export const Image = styled.img`
  display: none;

  @media only screen and (max-width: 768px) {
    display: block;
    width: 90px;
    height: 107px;
    margin: 0 auto 15px;
  }
`
