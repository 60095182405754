//@flow
import React, { useCallback, useState, useRef } from 'react'
import { connect } from 'react-redux'
import { translate } from '@editor/common/utils/translations'
import { FlyOut } from '@editor/common/lib/FlyOutMenu'
import MoreIcon from '@editor/common/assets/svgr-icons/More_icon.svg'
import PinIcon from '@editor/common/assets/svgr-icons/semrush-pin_icon.svg'
import UnpinIcon from '@editor/common/assets/svgr-icons/semrush-unpin_icon.svg'
import SemrushDisconnectIcon from '@editor/common/assets/svgr-icons/semrush-disconnect.svg'
import SemrushLogo from '@editor/common/assets/svgr-icons/semrush_logo.svg'
import { useResponsive } from '@shared/hooks'

import {
  pinOrUnpinSemrushSidebar,
  openOrCloseSemrushKeywordsPopup
} from '@actions/editor'
import { logOutSemrushUser } from '@actions/semrush'
import { selectSemrushSidebarPinnedState } from '@selectors'
import { TConnectCardProps } from './types.js'
import * as Styled from './styled'
import { ConnectCardWrapper } from './styled'

const ConnectedCard = ({
  primaryText,
  secondaryText,
  isSemrushSidebarPinned,
  logOutSemrushUser,
  pinOrUnpinSemrushSidebar,
  openOrCloseSemrushKeywordsPopup
}: TConnectCardProps) => {
  const [isMoreFlyoutOpened, setMoreFlyoutOpenedState] = useState(false)
  const moreFlyoutRef = useRef(null)
  const isMobile = useResponsive(1024)

  const toggleMoreFlyout = useCallback(() => {
    setMoreFlyoutOpenedState(currentState => !currentState)
  }, [])

  const closeMoreFLyout = useCallback(() => {
    setMoreFlyoutOpenedState(false)
  }, [])

  const handleDisconnectClick = useCallback(() => {
    closeMoreFLyout()
    logOutSemrushUser()
  }, [closeMoreFLyout, logOutSemrushUser])

  const pinUnpinSidebarToEditor = useCallback(() => {
    closeMoreFLyout()
    pinOrUnpinSemrushSidebar(!isSemrushSidebarPinned)
  }, [closeMoreFLyout, isSemrushSidebarPinned])

  const openSemrushKeywordsPopup = useCallback(() => {
    openOrCloseSemrushKeywordsPopup(true)
  }, [])

  return (
    <>
      <Styled.GlobalStyles />
      <Styled.ConnectedCardWrapper>
        <Styled.ConnectedCard>
          <Styled.TextsWrapper>
            <Styled.PrimaryText>{primaryText}</Styled.PrimaryText>
            <Styled.SecondaryText>{secondaryText}</Styled.SecondaryText>
          </Styled.TextsWrapper>
          <Styled.ButtonsWrapper>
            <Styled.Button type="tertiary" onClick={openSemrushKeywordsPopup}>
              {translate('get_keyword')}
            </Styled.Button>
            {isMobile ? (
              <Styled.DisconnectButton onClick={handleDisconnectClick}>
                <SemrushDisconnectIcon />
              </Styled.DisconnectButton>
            ) : (
              <>
                <Styled.MoreFlyoutButton
                  ref={moreFlyoutRef}
                  onClick={toggleMoreFlyout}
                  className="more-flyout-button"
                >
                  <MoreIcon />
                </Styled.MoreFlyoutButton>
                <FlyOut
                  className="more-options-flyout"
                  animationPrefix="more-options-flyout"
                  alignment="right"
                  isOpen={isMoreFlyoutOpened}
                  anchorEl={moreFlyoutRef.current}
                  onClose={closeMoreFLyout}
                  backdropDisablingSelectors={['.more-flyout-button']}
                >
                  <Styled.FlyOutOption onClick={handleDisconnectClick}>
                    <SemrushDisconnectIcon />
                    <Styled.OptionText>
                      {translate('disconnect_label')}
                    </Styled.OptionText>
                  </Styled.FlyOutOption>
                  <Styled.FlyOutOption onClick={pinUnpinSidebarToEditor}>
                    {isSemrushSidebarPinned ? (
                      <>
                        <UnpinIcon />
                        <Styled.OptionText>
                          {translate('unpin_from_editor')}
                        </Styled.OptionText>
                      </>
                    ) : (
                      <>
                        <PinIcon />
                        <Styled.OptionText>
                          {translate('pin_to_editor')}
                        </Styled.OptionText>
                      </>
                    )}
                  </Styled.FlyOutOption>
                </FlyOut>
              </>
            )}
          </Styled.ButtonsWrapper>
        </Styled.ConnectedCard>
        <Styled.CardLogo>
          <SemrushLogo />
        </Styled.CardLogo>
      </Styled.ConnectedCardWrapper>
    </>
  )
}

const mapStateToProps = state => ({
  isSemrushSidebarPinned: selectSemrushSidebarPinnedState(state)
})

export default connect(mapStateToProps, {
  openOrCloseSemrushKeywordsPopup,
  pinOrUnpinSemrushSidebar,
  logOutSemrushUser
})(ConnectedCard)
