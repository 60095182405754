// @flow
import moment from 'moment'

export const getSSLDatesDiffInMs = (lastTimeSslOnDate: string) => {
  const lastTime = moment(lastTimeSslOnDate)
    .utcOffset(moment().format('Z'))
    .format('MMM D, YYYY hh:mm A')

  return moment().diff(lastTime, 'miliseconds')
}

export const msToHours = (ms: number) => {
  return Math.floor((ms / (1000 * 60 * 60)) % 24)
}
