import styled from 'styled-components'

import { scrollBarStyles } from '@editor/common/styles'
import { Container } from '../shared-components/styled'
import { secondary, white } from '@website/common/styles/colors'
import LibNotificationMessage from '@editor/common/lib/NotificationMessage'

export const IntegrationsContainer = styled(Container)`
  height: 100%;
  background-color: #f8fbff;
  border-radius: 0 0 10px 10px;
  overflow: hidden;
  padding: 0;

  @media only screen and (max-width: 768px) {
    padding: 0;
  }
`

export const IntegrationsWrapper = styled.div`
  ${scrollBarStyles};
  height: 100%;
  padding: 30px 40px;
  box-sizing: border-box;
  overflow-y: scroll;

  & > div:not(:first-child) {
    margin-top: 40px;
  }

  @media only screen and (max-width: 768px) {
    padding: 30px 20px;
  }
`

export const UpgradeBannerWrapper = styled.div`
  .upgrade-banner {
    max-height: 190px;
    img {
      top: 35px;
      @media only screen and (max-width: 665px) {
        top: 30px;
      }
    }
    @media only screen and (max-width: 665px) {
      max-height: 300px;
    }
  }
`

export const NotificationMessage = styled(LibNotificationMessage)`
  width: 100%;
  box-sizing: border-box;
  margin-top: 20px !important;

  svg {
    width: 14px;
  }
`

export const CardWrapper = styled.div`
  position: relative;
  margin-top: 20px;
  margin-bottom: 20px;
`

export const Card = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${white};
  border-radius: 10px;
  border: 1px solid ${secondary.ExtraLight};
  padding: 50px 20px 0;
  box-sizing: border-box;

  & > div {
    border-bottom: 0;
    margin-bottom: 10px;
  }

  @media only screen and (max-width: 768px) {
    padding: 50px 20px 0;
  }
`

export const Logo = styled.div`
  width: 50px;
  height: 50px;
  position: absolute;
  top: -20px;
  left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${white};
  border-radius: 10px;
  border: 1px solid ${secondary.ExtraLight};
  box-sizing: border-box;
  img {
    width: 30px;
    height: 30px;
  }
`

export const InputWrapper = styled.div`
  display: flex;
  align-items: flex-start;

  & > div {
    width: 540px;
  }

  & > button {
    margin-left: 10px;
  }

  @media only screen and (max-width: 490px) {
    flex-direction: column;

    & > div {
      width: 100%;

      input {
        width: 100%;
      }
    }

    & > button {
      width: 100%;
      margin-left: 0;
      margin-top: 20px;
    }
  }

  @media only screen and (min-width: 769px) and (max-width: 889px) {
    & > div {
      width: 100%;
    }
  }
`

export const SuccessMessage = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  bottom: -6px;

  svg {
    fill: #2a8735;
    color: ${white};
  }

  p {
    color: ${secondary.SemiDark};
    font-size: 14px;
    margin-left: 5px;
  }
`

export const Line = styled.div`
  height: 1px;
  width: 100%;
  background-color: #dee3f0;
  margin-top: 20px !important;
  z-index: 2;
`
