import styled, { css } from 'styled-components'

import { primary, secondary } from '@website/common/styles/colors'
import { Container as CommonContainer } from '../shared-components'
import { styleByLang } from '@editor/common/utils/style-by-lang'
import { getDisabledBtnStyles } from '@containers/edit/containers/settings/contents/Domain/styled'

const stylesObj = {
  fr: {
    width: '645px'
  },
  jp: {
    width: '645px'
  },
  default: {
    width: '640px'
  }
}

const getUpgradeBannerWidth = styleByLang(stylesObj)

export const Container = styled(CommonContainer)`
  padding: 0;

  @media only screen and (max-width: 768px) {
    padding: 0;
  }
`

export const MainContainer = styled.div`
  padding: 20px 40px;

  @media only screen and (max-width: 768px) {
    padding: 20px;
  }
`

export const PrimaryLanguageContainer = styled.div``

export const SecondaryLanguagesContainer = styled.div`
  margin-top: 30px;

  .search-dropdown-wrapper {
    margin-top: 10px;
  }
`

export const TitleContainer = styled.div`
  background-color: #f8fbff;
  border-bottom: 1px solid ${secondary.Light};
  padding: 20px 40px;

  @media only screen and (max-width: 768px) {
    padding: 20px;
  }
`

export const Description = styled.p`
  font-size: 14px;
  color: ${secondary.ExtraDark};
  margin-top: 10px;
`

export const BlockTitle = styled.h2`
  font-size: 14px;
  color: ${secondary.ExtraDark};
  font-weight: 600;
`

export const BlockDescription = styled.p`
  font-size: 14px;
  color: ${secondary.SemiDark};
  margin: 5px 0 20px;
`

export const UpgradeBannerWrapper = styled.div`
  padding: 30px 40px;

  .upgrade-banner {
    margin-top: 0;
    background-size: cover;

    & > img {
      width: 111px;
      height: 121px;
    }

    @media only screen and (min-width: 769px) {
      width: ${getUpgradeBannerWidth('width')};
    }

    @media only screen and (max-width: 665px) {
      background-size: unset;

      & > img {
        top: 35px;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    padding: 30px 20px;
  }
`

export const ItemLanguageBlock = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: ${secondary.SemiDark};
  user-select: none;
  opacity: ${({ hasOpacity }) => (hasOpacity ? 0.5 : 1)};

  & > img {
    width: 20px;
    height: 20px;
    border-radius: 5px;
    margin-right: 10px;
    border: 1px solid #dee3f0;
  }

  @media only screen and (max-width: 450px) {
    & > p {
      max-width: 145px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`

export const ItemActionsBlock = styled.div`
  display: flex;
  align-items: center;
  cursor: default;

  ${({ isDisabled }) =>
    isDisabled
      ? css`
          pointer-events: none;
          opacity: 0.5;
        `
      : ''}
`

export const Button = styled.button`
  height: 30px;
  display: flex;
  align-items: center;
  outline: none;
  border: none;
  border-radius: 5px;
  color: #8493bd;
  font-size: 12px;
  font-weight: 600;
  background-color: transparent;
  padding: 0 10px 0 5px;
  box-sizing: border-box;
  cursor: pointer;

  & > span {
    margin-left: 5px;
  }

  & > svg {
    fill: #8493bd;
  }

  ${getDisabledBtnStyles};

  @media only screen and (min-width: 769px) {
    &:hover {
      color: ${secondary.ExtraDark};
      background-color: ${primary.Light};

      & > svg {
        fill: ${secondary.ExtraDark};
      }
    }
  }

  @media only screen and (max-width: 768px) {
    padding: 0;
    & > span {
      display: none;
    }
  }
`
