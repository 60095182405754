// @flow

export const simpleCookieActionGetter = (dispatch, defaultState) => {
  const setSimpleCookieVisibility = (isVisible: boolean): void => {
    dispatch({
      type: 'SET_SIMPLE_COOKIE_VISIBILITY',
      value: isVisible
    })
  }

  const setCookieConsentText = (text: string): void => {
    dispatch({
      type: 'SET_COOKIE_CONSENT_TEXT',
      value: text
    })
  }

  const setAcceptBtnName = (name: string): void => {
    dispatch({
      type: 'SET_ACCEPT_BUTTON_NAME',
      value: name
    })
  }

  const resetSimpleSettings = (): void => {
    dispatch({
      type: 'RESET_SIMPLE_DATA',
      value: defaultState
    })
  }

  return {
    setSimpleCookieVisibility,
    setAcceptBtnName,
    setCookieConsentText,
    resetSimpleSettings
  }
}

export const gdprCookieActionGetter = (dispatch, defaultState, callback) => {
  const setGDPRVisibility = (isVisible: boolean): void => {
    dispatch({
      type: 'SET_GDPR_VISIBILITY',
      value: isVisible
    })

    callback()
  }

  const setCookieConsentText = (text: string): void => {
    dispatch({
      type: 'SET_COOKIE_CONSENT_TEXT',
      value: text
    })
  }

  const setAcceptBtnName = (name: string): void => {
    dispatch({
      type: 'SET_ACCEPT_BUTTON_NAME',
      value: name
    })
  }

  const setDeclineBtnName = (name: string): void => {
    dispatch({
      type: 'SET_DECLINE_BUTTON_NAME',
      value: name
    })
  }

  const setSettingsBtnName = (name: string): void => {
    dispatch({
      type: 'SET_SETTINGS_BUTTON_NAME',
      value: name
    })
  }

  const setSettingsBtnVisibility = (value: boolean): void => {
    dispatch({
      type: 'SET_SETTINGS_BUTTON_VISIBILITY',
      value
    })
  }

  const setDeclineBtnVisibility = (state: boolean): void => {
    dispatch({
      type: 'SET_DECLINE_BTN_VISIBILITY',
      value: state
    })
  }

  const setPrivacyPolicyBtnName = (name: string): void => {
    dispatch({
      type: 'SET_PRIVACY_POLICY_BUTTON_NAME',
      value: name
    })
  }

  const setPrivacyPolicyLink = (link: string): void => {
    dispatch({
      type: 'SET_PRIVACY_POLICY_LINK',
      value: link
    })
  }

  const setPrivacyPolicyBtnVisibility = (isVisible: boolean): void => {
    dispatch({
      type: 'SET_PRIVACY_POLICY_BUTTON_VISIBILITY',
      value: isVisible
    })
  }

  const setPrivacyPolicyBtnVisibilityInFooter = (isVisible: boolean): void => {
    dispatch({
      type: 'SET_PRIVACY_POLICY_BUTTON_VISIBILITY_IN_FOOTER',
      value: isVisible
    })
  }

  const setCookiesMainTitle = (value: string): void => {
    dispatch({
      type: 'SET_COOKIES_MAIN_TITLE',
      value
    })
  }

  const setCookiesMainDescription = (value: string): void => {
    dispatch({
      type: 'SET_COOKIES_MAIN_DESCRIPTION',
      value
    })
  }

  const setCookiesConfirmBtnText = (value: string): void => {
    dispatch({
      type: 'SET_COOKIES_CONFIRM_BTN_TEXT',
      value
    })
  }

  const setGDPRSettings = (state: Object): void => {
    dispatch({
      type: 'SET_GDPR_SETTINGS',
      value: state
    })
  }

  const resetGDPRSettings = (): void => {
    dispatch({
      type: 'RESET_GDPR_DATA',
      value: defaultState
    })
  }

  return {
    setGDPRVisibility,
    setCookieConsentText,
    setAcceptBtnName,
    setDeclineBtnName,
    setSettingsBtnName,
    setDeclineBtnVisibility,
    setPrivacyPolicyBtnName,
    setPrivacyPolicyLink,
    setPrivacyPolicyBtnVisibility,
    setPrivacyPolicyBtnVisibilityInFooter,
    setSettingsBtnVisibility,
    setGDPRSettings,
    resetGDPRSettings
  }
}
