// @flow
import React, { useCallback, memo, useContext } from 'react'
import { translate } from '@editor/common/utils/translations'

import type { TCardProps } from './types'
import { InputContainer, Description } from '../../../shared-components/styled'
import {
  CardWrapper,
  Card as StyledCard,
  Logo,
  InputWrapper,
  SuccessMessage
} from '../../styled'
import { TitleContainer, SuccessPopup } from '../../../shared-components'
import Input from '@website/common/components/Input'
import { secondary } from '@website/common/styles/colors'
import SuccessIcon from '@editor/common/assets/svgr-icons/circle_check.svg'
import { MobileDetectContext } from '@contexts'

import * as Styled from './styled'

const Card = ({
  inputValue,
  isConnected,
  hasChanges,
  title,
  logo,
  inputLabel,
  placeholder,
  tooltipText,
  tooltipImageSrc,
  successMessage,
  formatEmbed,
  setId,
  setInputValue
}: TCardProps) => {
  const isMobile = useContext(MobileDetectContext)

  const handleChange = useCallback(e => {
    setInputValue(e.target.value)
  }, [])

  const handleBlur = useCallback(() => {
    if (!hasChanges) {
      return
    }

    if (!inputValue) {
      setId('', false)
    }
  }, [inputValue, hasChanges])

  const onRemoveIconClick = useCallback(() => {
    setInputValue('')
  }, [])

  const handleClick = useCallback(() => {
    if (isConnected && !hasChanges) {
      setId('', false)
      setInputValue('')
      return
    }

    if (!hasChanges) {
      return
    }

    setId(inputValue)
  }, [inputValue, isConnected, hasChanges])

  const onKeyPress = useCallback(
    e => {
      if (e.key === 'Enter') {
        handleClick()
        e.target.blur()
      }
    },
    [handleClick]
  )

  const onPaste = useCallback(
    e => {
      e.preventDefault()
      const copiedText = e.clipboardData.getData('text')
      const selected = document.getSelection().toString()
      const formattedText = formatEmbed && formatEmbed(copiedText)
      const _value = formattedText || copiedText

      const currentText = selected
        ? inputValue.replace(selected, _value)
        : `${inputValue || ''}${_value}`

      setInputValue(currentText)
    },
    [inputValue]
  )

  return (
    <>
      <CardWrapper>
        <StyledCard>
          <InputContainer>
            <TitleContainer
              title={title}
              tooltipText={tooltipText}
              imgSrc={tooltipImageSrc}
              hasTooltipContent
            />
            <Description>{inputLabel}</Description>
            <InputWrapper>
              <Input
                value={inputValue}
                placeholder={placeholder}
                placeholderColor={secondary.SemiLight}
                onChange={handleChange}
                onKeyPress={onKeyPress}
                onBlur={handleBlur}
                onRemoveIconClick={onRemoveIconClick}
                onPaste={onPaste}
              />
              <Styled.Button
                type={!isConnected || hasChanges ? 'tertiary' : 'secondary'}
                disabled={!inputValue}
                onClick={handleClick}
              >
                {!isConnected || hasChanges
                  ? translate('connect_label')
                  : translate('disconnect_label')}
              </Styled.Button>
            </InputWrapper>
            {successMessage && !isMobile ? (
              <SuccessMessage>
                <SuccessIcon />
                <p>{successMessage}</p>
              </SuccessMessage>
            ) : null}
          </InputContainer>
        </StyledCard>
        <Logo>
          <img src={logo} alt={title} />
        </Logo>
      </CardWrapper>
      {isMobile ? (
        <SuccessPopup
          successMessage={successMessage}
          primaryMessage={
            isConnected
              ? translate('connected_label')
              : translate('disconnected_label')
          }
        />
      ) : null}
    </>
  )
}

export default memo(Card)
