import React, { useState, useContext, useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import * as Styled from './styled'
import {
  COOKIES_LABEL,
  COOKIES_TITLE,
  NEED_HELP_LINK,
  getVerticalTabsData
} from './consts'
import { GDPRCookieContext, INITIAL_GDPR_SETTINGS } from './../../store'
import * as CommonStyled from '../GDPR/common-styled'
import NeedHelpLink from '../GDPR/NeedHelpLink'
import PopupContent from '@website/common/components/CookieBanner/PopupContent'
import AdaptiveVerticalTabs from '@editor/common/lib/VerticalTabs'
import { closeGdprSettingsCustomizationWindow } from '@actions/editor'
import { useDiscardDialog } from '@hooks/useDiscardDialog'
import { translate } from '@editor/common/utils/translations'

const CustomizationWindowContent = ({
  isMobile,
  closeGdprSettingsCustomizationWindow
}) => {
  const {
    state: { settings },
    actions: { setGDPRSettings }
  } = useContext(GDPRCookieContext)
  const { categories, title, description, confirmBtnName } = settings
  const [mainTitle, setMainTitle] = useState(title)
  const [mainDescription, setMainDescription] = useState(description)
  const [buttonText, setButtonText] = useState(confirmBtnName)
  const [categoriesData, setCategoriesData] = useState(categories)
  const [activeTab, setActiveTab] = useState('essential')

  const settingsLocalState = {
    title: mainTitle,
    description: mainDescription,
    confirmBtnName: buttonText,
    categories: categoriesData
  }

  const hasChanges =
    JSON.stringify(settingsLocalState) !== JSON.stringify({ ...settings })

  const updateCategoryData = (value, type, dataType) => {
    return categoriesData.reduce((acc, category) => {
      const data = { ...category }

      if (category.type === type) {
        data[dataType] = value
      }

      acc.push(data)
      return acc
    }, [])
  }

  useEffect(
    () => () => {
      closeGdprSettingsCustomizationWindow()
    },
    []
  )

  const onClose = useDiscardDialog(closeGdprSettingsCustomizationWindow, {
    id: 'customization',
    wrapperId: 'cookies',
    hasChanges
  })

  const handleCategoryDataChange = (value, type, dataType) => {
    const newCategoriesData = updateCategoryData(value, type, dataType)
    setCategoriesData(newCategoriesData)
  }

  const onSave = () => {
    setGDPRSettings(settingsLocalState)
    closeGdprSettingsCustomizationWindow()
  }

  const handleTabChange = useCallback(
    tab => {
      setActiveTab(tab)
    },
    [setActiveTab]
  )

  const popupPreiview = (
    <Styled.SettingsPreviewWrapper>
      <Styled.SettingsPreviewContainer>
        <PopupContent
          disabled
          mainContent={{
            title: mainTitle,
            buttonText,
            description: mainDescription
          }}
          categories={categoriesData}
        />
      </Styled.SettingsPreviewContainer>
    </Styled.SettingsPreviewWrapper>
  )

  return (
    <Styled.Container>
      <Styled.ContentContainer>
        <div>
          <Styled.ContentTitle>
            {translate('customize_cookie_popup_title')}
          </Styled.ContentTitle>
          <Styled.Label>
            {translate('customize_cookie_popup_description')}
          </Styled.Label>
          <NeedHelpLink link={NEED_HELP_LINK} />
        </div>
        <CommonStyled.Line />
        <Styled.CookieContainer>
          <Styled.MainContent>
            <Styled.EditableFields>
              <CommonStyled.InputLabel>
                {translate('title_label')}
              </CommonStyled.InputLabel>
              <CommonStyled.Input
                isRequired
                count={60}
                defaultValue={title}
                value={mainTitle}
                onChange={setMainTitle}
              />
              <CommonStyled.InputLabel>
                {translate('description_label')}
              </CommonStyled.InputLabel>
              <CommonStyled.Input
                isTextArea
                isRequired
                count={200}
                defaultValue={description}
                value={mainDescription}
                onChange={setMainDescription}
              />
              <CommonStyled.InputLabel>
                {translate('confirm_btn_text')}
              </CommonStyled.InputLabel>
              <CommonStyled.Input
                isRequired
                count={19}
                defaultValue={confirmBtnName}
                value={buttonText}
                onChange={setButtonText}
              />
              <Styled.Line />
            </Styled.EditableFields>
            {!isMobile ? popupPreiview : null}
          </Styled.MainContent>
          <Styled.CategoryContainer>
            <Styled.ContentTitle>
              {translate('cookie_categories_title')}
            </Styled.ContentTitle>
            <Styled.Label>
              {translate('cookie_categories_description')}
            </Styled.Label>
            <AdaptiveVerticalTabs
              data={getVerticalTabsData({
                handleCategoryDataChange,
                data: {
                  cookieData: categoriesData,
                  defaultData: INITIAL_GDPR_SETTINGS.settings.categories
                }
              })}
              activeTab={activeTab}
              onChange={handleTabChange}
            />
          </Styled.CategoryContainer>
          {isMobile ? popupPreiview : null}
        </Styled.CookieContainer>
      </Styled.ContentContainer>
      <Styled.Footer>
        <Styled.SaveButton onClick={onSave} disabled={!hasChanges}>
          {translate('apply_label', true)}
        </Styled.SaveButton>
        <Styled.DiscardButton onClick={onClose}>
          {translate('discard_label')}
        </Styled.DiscardButton>
      </Styled.Footer>
    </Styled.Container>
  )
}

export default connect(null, { closeGdprSettingsCustomizationWindow })(
  CustomizationWindowContent
)
