//@flow

import React, { memo, useState, useCallback } from 'react'
import { TitleContainer } from '@editor/containers/edit/containers/settings/contents/shared-components/'
import CORNER_ICON from '@editor/common/assets/corner.svg'
import { TVerticalTabsProps } from './types'

import * as Styled from './styled'

const VerticalTabs = ({
  data,
  activeTab,
  onChange,
  defaultActiveTab = data[0].type
}: TVerticalTabsProps) => {
  if (!Array.isArray(data) || !data.length) {
    return null
  }

  const isFirstActive = activeTab === data[0].type

  const onTabClick = key => {
    typeof onChange === 'function' && onChange(key)
  }

  const content = data.find(item => item.type === activeTab).content

  return (
    <Styled.Container>
      <Styled.TabsWrapper>
        {data.map((tab, idx) => {
          const { type, title, tooltipText } = tab
          const isActive = type === activeTab

          return (
            <Styled.Tab
              key={type}
              isActive={isActive}
              onClick={() => onTabClick(type)}
            >
              <TitleContainer
                className="vertical-tabs-title"
                title={title}
                tooltipText={tooltipText}
              />
              <Styled.CornerWrapper>
                <Styled.Image src={CORNER_ICON} alt="corner" isInTop />
                <Styled.Image src={CORNER_ICON} alt="corner" />
              </Styled.CornerWrapper>
            </Styled.Tab>
          )
        })}
      </Styled.TabsWrapper>
      <Styled.Content isFirstActive={isFirstActive}>{content}</Styled.Content>
    </Styled.Container>
  )
}

export default memo(VerticalTabs)
