import styled, { css } from 'styled-components'
import { secondary } from '@website/common/styles/colors'

const PADDING = 15

export const Expander = styled.div`
  border-radius: 10px;
  border: 1px solid #dee3f0;
  position: relative;
`

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 13px 15px;
  color: ${secondary.Dark};
  font-size: 16px;
  font-weight: 600;
  box-sizing: border-box;
  cursor: pointer;

  ${({ disabled }) =>
    disabled
      ? css`
          color: ${secondary.Dark};
          opacity: 0.5;
        `
      : ''};
`

export const ArrowButton = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  background: transparent;
  overflow: hidden;

  ${({ disabled }) =>
    disabled
      ? css`
          cursor: default;
          pointer-events: none;
          opacity: 0.7;
        `
      : ''};

  & > svg {
    transition: transform 0.3s ease-out;
    transform: rotate(${({ isExpanded }) => (isExpanded ? '0' : '180deg')});
    fill: #363f5a;
  }
`

export const Content = styled.div`
  ${({ isOpen, maxHeight }) =>
    isOpen
      ? css`
          padding: 0 ${PADDING}px ${PADDING}px;
          max-height: ${maxHeight}px;
        `
      : css`
          padding: 0 ${PADDING}px;
          max-height: 0px;
        `};
  transition: all 300ms ease;
  overflow: hidden;
`

export const DisabledOverlay = styled.div`
  ${({ disabled }) =>
    disabled
      ? css`
          width: 100%;
          z-index: 1;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          border-radius: 10px;
          opacity: 50%;
          background-color: #edeff6;
        `
      : ''}
`
