import { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
  .animation-wrapper {
    width: 244px;
    height: 218px;
    display: flex;
    margin: auto;
    justify-content: center;
    position: relative;
    animation: rotate 0.9s ease-in forwards;
}

@media only screen and (max-width: 650px) {
    .animation-wrapper {
        zoom: 0.7;
    }
}

.circle {
    width: 160px;
    height: 160px;
    margin: 30px auto auto auto;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url('https://static.rfstat.com/renderforest/images/v2/thank-you-pages/circle-background.svg') no-repeat 100% center/100% 100%;
}

.check {
    width: 70px;
    height: 70px;
    overflow: hidden;
    margin-right: 8px;
    animation: drawCheck 0.5s steps(43) forwards;
    background-image: url('https://static.rfstat.com/renderforest/images/v2/thank-you-pages/animated-check.png');
    background-repeat: no-repeat;
    background-size: 5500%;
    background-position: left;
}

.animate .circle {
    animation: scale 0.8s ease-in forwards;
}

.icon {
    position: absolute;
    opacity: 0;
    top: 50%;
}

.icon-1 {
    width: 32px;
    right: 50%;
}

.icon-2 {
    width: 10px;
    right: 50%;
}

.icon-3 {
    width: 13px;
    right: 50%;
}

.icon-4 {
    width: 8px;
    right: 50%;
}

.icon-5 {
    width: 7px;
    right: 50%;
}

.icon-6 {
    width: 8px;
    right: 50%;
}

.icon-7 {
    width: 11px;
    right: 50%;
}

.icon-8 {
    width: 34px;
    right: 50%;
}

.icon-9 {
    width: 12px;
    right: 50%;
}

.icon-10 {
    width: 8px;
    right: 50%;
}

.icon-11 {
    width: 7px;
    right: 50%;
}

.icon-12 {
    width: 34px;
    right: 50%;
}

.icon-13 {
    width: 7px;
    left: 50%;
}

.icon-14 {
    width: 10px;
    left: 50%;
}

.icon-15 {
    width: 12px;
    left: 50%;
}

.icon-16 {
    width: 10px;
    left: 50%;
}

.icon-17 {
    width: 8px;
    left: 50%;
}

.icon-18 {
    width: 30px;
    left: 50%;
}

.icon-19 {
    width: 8px;
    left: 50%;
}

.icon-20 {
    width: 8px;
    left: 50%;
}

.icon-21 {
    width: 11px;
    right: 50%;
}

.icon-22 {
    width: 7px;
    right: 50%;
}

.animate .icon-1 {
    animation: animate-icon-1 0.8s ease-in forwards;
}

.animate .icon-2 {
    animation: animate-icon-2 0.9s ease-in forwards;
}

.animate .icon-3 {
    animation: animate-icon-3 0.7s ease-in forwards;
}

.animate .icon-4 {
    animation: animate-icon-4 0.8s ease-in forwards;
}

.animate .icon-5 {
    animation: animate-icon-5 0.9s ease-in forwards;
}

.animate .icon-6 {
    animation: animate-icon-6 0.7s ease-in forwards;
}

.animate .icon-7 {
    animation: animate-icon-7 0.8s ease-in forwards;
}

.animate .icon-8 {
    animation: animate-icon-8 0.9s ease-in forwards;
}
.animate .icon-9 {
    animation: animate-icon-9 0.7s ease-in forwards;
}

.animate .icon-10 {
    animation: animate-icon-10 0.8s ease-in forwards;
}

.animate .icon-11 {
    animation: animate-icon-11 0.9s ease-in forwards;
}

.animate .icon-12 {
    animation: animate-icon-12 0.7s ease-in forwards;
}
.animate .icon-13 {
    animation: animate-icon-13 0.8s ease-in forwards;
}

.animate .icon-14 {
    animation: animate-icon-14 0.9s ease-in forwards;
}

.animate .icon-15 {
    animation: animate-icon-15 0.7s ease-in forwards;
}

.animate .icon-16 {
    animation: animate-icon-16 0.8s ease-in forwards;
}

.animate .icon-17 {
    animation: animate-icon-17 0.9s ease-in forwards;
}

.animate .icon-18 {
    animation: animate-icon-18 0.7s ease-in forwards;
}

.animate .icon-19 {
    animation: animate-icon-19 0.8s ease-in forwards;
}

.animate .icon-20 {
    animation: animate-icon-20 0.9s ease-in forwards;
}

.animate .icon-21 {
    animation: animate-icon-21 0.7s ease-in forwards;
}

.animate .icon-22 {
    animation: animate-icon-22 0.8s ease-in forwards;
}

@keyframes rotate {
    50% {
        transform: rotate(4deg);
    }
    100% {
        transform: rotate(0);
    }
  }


@keyframes scale {
    0% {
        transform: scale(0.2);
        opacity: 0;
    }

    50% {
        opacity: 1;
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
  }


@keyframes drawCheck {
    from {
        background-position: left;
    }
    to {
        background-position: right;
    }
}  

@keyframes animate-icon-1 {
    50% {
        opacity: 0;
    }

    80% {
        top: 0px;
        right: 20px;
        opacity: 0;
    }

    100% {
        opacity: 1;
        top: 10px;
        right: 40px;
    }
  }

@keyframes animate-icon-2 {
    50% {
        opacity: 0;
    }

    80%{
        top: 34px;
        right: 4px;    
    }

    100% {
        opacity: 1;
        top: 48px;
        right: 14px;    
    }
  }

@keyframes animate-icon-3 {
    50% {
        opacity: 0;
    }

    80%{
        top: 70px;
        right: 4px;    
    }

    100% {
        opacity: 1;
        top: 83px;
        right: 24px;    
    }
  }

@keyframes animate-icon-4 {
    50% {
        opacity: 0;
    }

    80%{
        top: 105px;
        right: -10px;    
    }

    100% {
        opacity: 1;
        top: 105px;
        right: 2px;    
    }
  }

@keyframes animate-icon-5 {
    50% {
        opacity: 0;
    }

    80%{
        top: 145px;
        right: 10px;    
    }

    100% {
        opacity: 1;
        top: 132px;
        right: 25px;

    }
  }

@keyframes animate-icon-6 {
    50% {
        opacity: 0;
    }

    80%{
        top: 176px;
        right: -8px;    
    }

    100% {
        opacity: 1;
        top: 168px;
        right: 4px;    
    }
  }

@keyframes animate-icon-7 {
    50% {
        opacity: 0;
    }

    80%{
        top: 170px;
        right: 25px;    
    }

    100% {
        opacity: 1;
        top: 160px;
        right: 40px;    
    }
  }

@keyframes animate-icon-8 {
    50% {
        opacity: 0;
    }

    80%{
        top: 185px;
        right: 18px;    
    }

    100% {
        opacity: 1;
        top: 176px;
        right: 30px;    
    }
  }

@keyframes animate-icon-9 {
    50% {
        opacity: 0;
    }

    80%{
        top: 210px;
        right: 68px;    
    }

    100% {
        opacity: 1;
        top: 195px;
        right: 80px;    
    }
  }

@keyframes animate-icon-10 {
    50% {
        opacity: 0;
    }

    80%{
        top: 220px;
        right: 114px;    
    }

    100% {
        opacity: 1;
        top: 210px;
        right: 114px;    
    }
  }

@keyframes animate-icon-11 {
    50% {
        opacity: 0;
    }

    80%{
        top: 210px;
        right: 154px;    
    }

    100% {
        opacity: 1;
        top: 190px;
        right: 140px;    
    }
  }

@keyframes animate-icon-12 {
    50% {
        opacity: 0;
    }

    80%{
        top: 175px;
        right: 164px;    
    }

    100% {
        opacity: 1;
        top: 165px;
        right: 165px;    
    }
  }

@keyframes animate-icon-13 {
    50% {
        opacity: 0;
    }

    80%{
        top: 178px;
        left: 10px;    
    }

    100% {
        opacity: 1;
        top: 168px;
        left: 20px;    
    }
  }

@keyframes animate-icon-14 {
    50% {
        opacity: 0;
    }

    80%{
        top: 164px;
        left: 10px;    
    }

    100% {
        opacity: 1;
        top: 145px;
        left: 34px;    
    }
  }

@keyframes animate-icon-15 {
    50% {
        opacity: 0;
    }

    80%{
        top: 118px;
        left: 4px;    
    }

    100% {
        opacity: 1;
        top: 118px;
        left: 18px;    
    }
  }

@keyframes animate-icon-16 {
    50% {
        opacity: 0;
    }

    80%{
        top: 75px;
        left: -8px;    
    }

    100% {
        opacity: 1;
        top: 90px;
        left: 2px;    
    }
  }

@keyframes animate-icon-17 {
    50% {
        opacity: 0;
    }

    80% {
        top: 68px;
        left: 10px;    
    }

    100% {
        opacity: 1;
        top: 82px;
        left: 36px;    
    }
  }

@keyframes animate-icon-18 {
    50% {
        opacity: 0;
    }

    80% {
        top: 14px;
        left: 12px;    
    }

    100% {
        opacity: 1;
        top: 24px;
        left: 24px;    
    }
  }

@keyframes animate-icon-19 {
    50% {
        opacity: 0;
    }

    80% {
        top: 6px;
        left: 45px;    
    }

    100% {
        opacity: 1;
        top: 12px;
        left: 64px;    
    }
  }

@keyframes animate-icon-20 {
    50% {
        opacity: 0;
    }

    80% {
        top: 14px;
        left: 80px;    
    }

    100% {
        opacity: 1;
        top: 28px;
        left: 96px;    
    }
  }

@keyframes animate-icon-21 {
    50% {
        opacity: 0;
    }

    80% {
        top: -10px;
        right: 125px;    
    }

    100% {
        opacity: 1;
        top: 0px;
        right: 125px;    
    }
  }

@keyframes animate-icon-22 {
    50% {
        opacity: 0;
    }

    80%{
        top: 10px;
        right: 84px;    
    }

    100% {
        opacity: 1;
        top: 20px;
        right: 96px;    
    }
  }
`
