//@flow
import React, { memo } from 'react'
import { usePreview } from 'react-dnd-preview'

import ListItem from '../ListItem'
import type { MobileDragPreviewProps } from './types'

const MobileDragPreview = ({ languages }: MobileDragPreviewProps) => {
  const { display, item, style } = usePreview()
  if (!display) {
    return null
  }

  const language = languages.find(({ lang }) => lang.name === item.id)

  return (
    <div className="item-list__item" style={style}>
      <ListItem data={language} isInDragPreview />
    </div>
  )
}

export default memo(MobileDragPreview)
