// @flow
import React, { memo } from 'react'

import * as Styled from './styled'
import type { TViewMyWebsiteProps } from './types'
import { openUrl } from '@website/common/utils'
import { primary } from '@website/common/styles/colors'
import { translate } from '@editor/common/utils/translations'

const ViewMyWebsite = ({ siteUrl, isInSubdomain }: TViewMyWebsiteProps) => {
  return (
    <Styled.ViewMyWebsite isInSubdomain={isInSubdomain}>
      <Styled.ViewMyWebsiteBtn onClick={() => openUrl(siteUrl, true)}>
        <Styled.BtnText>{translate('view_my_website_label')}</Styled.BtnText>
        <Styled.BtnIcon
          name="open_in_new"
          color={primary.Dark}
          size="xNormal"
        />
      </Styled.ViewMyWebsiteBtn>
    </Styled.ViewMyWebsite>
  )
}

export default memo(ViewMyWebsite)
