//@flow
import React, { useCallback } from 'react'
import { connect } from 'react-redux'
import { translate } from '@editor/common/utils/translations'
import { SEMRUSH_MAGIC_TOOL_IMG_SRC } from './consts'
import { TConnectCardProps } from './types.js'
import * as Styled from './styled'
import { setSemrushLoginWindowState } from '@actions'

const ConnectCard = ({
  primaryText,
  secondaryText,
  setSemrushLoginWindowState
}: TConnectCardProps) => {
  const openSemrushLoginWindow = useCallback(() => {
    setSemrushLoginWindowState(true)
  }, [])

  return (
    <Styled.ConnectCard>
      <Styled.ConnectImageWrapper>
        <Styled.Image src={SEMRUSH_MAGIC_TOOL_IMG_SRC} />
      </Styled.ConnectImageWrapper>
      <Styled.ContentWrapper>
        <Styled.ConnectTextsWrapper>
          <Styled.PrimaryText>{primaryText}</Styled.PrimaryText>
          <Styled.ConnectSecondaryText>
            {secondaryText}
          </Styled.ConnectSecondaryText>
        </Styled.ConnectTextsWrapper>
        <Styled.ConnectButton type="tertiary" onClick={openSemrushLoginWindow}>
          {translate('connect_label')}
        </Styled.ConnectButton>
      </Styled.ContentWrapper>
    </Styled.ConnectCard>
  )
}

export default connect(null, {
  setSemrushLoginWindowState
})(ConnectCard)
