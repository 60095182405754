// @flow
import React, { useCallback, useContext, useEffect } from 'react'
import { connect } from 'react-redux'
import { translate } from '@editor/common/utils/translations'

import * as Styled from './styled'
import type { TAuthDigitalSellerProps } from './types'
import { InputContainer, Description } from '../../../shared-components/styled'
import { TitleContainer, Input, SuccessPopup } from '../../../shared-components'
import { CardWrapper, Card, Logo, SuccessMessage } from '../../styled'
import SuccessIcon from '@editor/common/assets/svgr-icons/circle_check.svg'
import {
  selectAuthDigitalSellersContent,
  selectAuthDigitalSellersSuccessMessage
} from '@selectors'
import { setAuthDigitalSellersContent } from '@actions/meta'
import { MobileDetectContext } from '@contexts'

const AuthDigitalSellers = ({
  value,
  setValue,
  title,
  logo,
  inputLabel,
  placeholder,
  tooltipText,
  tooltipImageSrc,
  authDigitalSellersContent,
  successMessage,
  setAuthDigitalSellersContent
}: TAuthDigitalSellerProps) => {
  const isMobile = useContext(MobileDetectContext)
  const isConnected = !!authDigitalSellersContent
  const hasChanges = value !== authDigitalSellersContent

  const handleBlur = useCallback(() => {
    if (!hasChanges) {
      return
    }

    if (!value) {
      setAuthDigitalSellersContent('')
    }
  }, [value, hasChanges])

  const handleClick = useCallback(() => {
    if (isConnected && !hasChanges) {
      setAuthDigitalSellersContent('')
      setValue('')
      return
    }

    if (!hasChanges) {
      return
    }

    setAuthDigitalSellersContent(value)
  }, [value, isConnected, hasChanges])

  const onKeyPress = useCallback(
    e => {
      if (e.key === 'Enter') {
        handleClick()
        e.target.blur()
      }
    },
    [handleClick]
  )

  return (
    <>
      <CardWrapper>
        <Card>
          <InputContainer>
            <TitleContainer
              title={title}
              tooltipText={tooltipText}
              imgSrc={tooltipImageSrc}
              hasTooltipContent
              isTooltipFromRight
            />
            <Description>{inputLabel}</Description>
            <Styled.InputWrapper>
              <Input
                isTextArea
                value={value}
                placeholder={placeholder}
                onChange={setValue}
                onKeyPress={onKeyPress}
                onBlur={handleBlur}
                className="ads-textarea"
              />
              <Styled.Button
                type="tertiary"
                disabled={!hasChanges}
                onClick={handleClick}
              >
                {!isConnected || hasChanges
                  ? translate('save_label')
                  : translate('saved_label')}
              </Styled.Button>
            </Styled.InputWrapper>
            {successMessage && !isMobile ? (
              <SuccessMessage>
                <SuccessIcon />
                <p>{successMessage}</p>
              </SuccessMessage>
            ) : null}
          </InputContainer>
        </Card>
        <Logo>
          <img src={logo} alt={title} />
        </Logo>
      </CardWrapper>
      {isMobile ? (
        <SuccessPopup
          successMessage={successMessage}
          primaryMessage={translate('saved_label')}
        />
      ) : null}
    </>
  )
}

const mapStateToProps = state => ({
  authDigitalSellersContent: selectAuthDigitalSellersContent(state),
  successMessage: selectAuthDigitalSellersSuccessMessage(state)
})

const mapDispatchToProps = { setAuthDigitalSellersContent }

export default connect(mapStateToProps, mapDispatchToProps)(AuthDigitalSellers)
