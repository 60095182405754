import styled, { css } from 'styled-components'
import { Line as CommonLine } from '../common-styled'
import { secondary } from '@website/common/styles/colors'
import LibTooltipWrapper from '@website/common/components/TooltipWrapper'
import { styleByLang } from '@editor/common/utils/style-by-lang'

const customDistance = {
  lineMarginTop: '35px'
}

const stylesObj = {
  ru: customDistance,
  es: customDistance,
  pt: customDistance,
  fr: customDistance,
  de: customDistance,
  default: {
    lineMarginTop: '25px'
  }
}
const getStylesByLang = styleByLang(stylesObj)

export const Line = styled(CommonLine)`
  margin: 25px 0 0;

  @media only screen and (max-width: 568px) {
    margin-top: ${getStylesByLang('lineMarginTop')};
  }
`

export const LinkContainer = styled.div`
  display: flex;
  & .label-container {
    font-size: 14px;
  }
  & > div {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    &:first-child {
      margin-right: 30px;
    }
  }
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    & > div {
      &:first-child {
        margin-right: 0;
      }
      &:last-child {
        margin-top: 10px;
      }
    }
  }
`

export const TooltipWrapper = styled(LibTooltipWrapper)`
  width: fit-content;
  @media only screen and (max-width: 768px) {
    width: auto;
  }
`

export const AddLinkInFooterDescription = styled.p`
  ${({ disabled }) =>
    disabled
      ? css`
          opacity: 0.6;
          pointer-events: none;
          cursor: default;
        `
      : ''};
  margin-top: 10px;
  font-size: 14px;
  color: ${secondary.SemiDark};
  line-height: 18px;
`
