/* @flow */
import React, { useMemo } from 'react'
import moment from 'moment'
import { translate } from '@editor/common/utils/translations'

import * as Styled from './styled'
import { Text } from '@renderforest/rf-ui-library/atoms/Text'
import { getDateExpiry } from '../../../../utils'

declare type Props = {
  endDate: string
}

const ExpireDate = React.memo<Props>(({ endDate }: Props) => {
  const timeLeft = getDateExpiry(endDate)
  const expiresInTwoWeeks = timeLeft >= 0 && timeLeft < 14
  const expired = timeLeft < 0

  const color = useMemo(() => {
    if (expired) {
      return 'red'
    } else if (expiresInTwoWeeks) {
      return 'warningColor'
    } else {
      return 'secondary'
    }
  }, [expired, expiresInTwoWeeks])

  return (
    <Styled.ExpiresDate>
      <Styled.ExpiresText color={color} size="xSmall">
        {expired ? translate('expired_label') : translate('expires_label')}:
      </Styled.ExpiresText>
      <Text color="secondary" size="xSmall" weight={600}>{`${moment(
        endDate
      ).format('DD.MM.YYYY')}`}</Text>
    </Styled.ExpiresDate>
  )
})

export default ExpireDate
