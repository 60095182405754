import React from 'react'
import { translate } from '@editor/common/utils/translations'
import CategoryForm from './CategoryForm'
import { getLocalizedURL } from '@editor/common/utils/translations'

export const NEED_HELP_LINK = `https://www.renderforest.com/${getLocalizedURL(
  '/website-maker/gdpr'
)}`

const cookieCategories = ['essential', 'analytics', 'marketing', 'functional']

export const getVerticalTabsData = contentProps => [
  {
    type: cookieCategories[0],
    title: translate('essential_cookies_label'),
    tooltipText: translate('essential_cookies_tooltip'),
    content: (
      <CategoryForm
        key={cookieCategories[0]}
        type={cookieCategories[0]}
        {...contentProps}
      />
    )
  },
  {
    type: cookieCategories[1],
    title: translate('analytics_cookies_label'),
    tooltipText: translate('analytics_cookies_tooltip'),
    content: (
      <CategoryForm
        key={cookieCategories[1]}
        type={cookieCategories[1]}
        {...contentProps}
      />
    )
  },
  {
    type: cookieCategories[2],
    title: translate('marketing_cookies_label'),
    tooltipText: translate('marketing_cookies_tooltip'),
    content: (
      <CategoryForm
        key={cookieCategories[2]}
        type={cookieCategories[2]}
        {...contentProps}
      />
    )
  },
  {
    type: cookieCategories[3],
    title: translate('functional_cookies_label'),
    tooltipText: translate('functional_cookies_tooltip'),
    content: (
      <CategoryForm
        key={cookieCategories[3]}
        type={cookieCategories[3]}
        {...contentProps}
      />
    )
  }
]
