import styled from 'styled-components'
import {
  accentGreen,
  primary,
  secondary,
  white
} from '@website/common/styles/colors'
import { TooltipWrapper } from '@website/common/components/TooltipWrapper'

import { styleByLang } from '@editor/common/utils/style-by-lang'

const copyBtnCustomStyle = {
  maxWidth: '200px'
}

const stylesObj = {
  fr: copyBtnCustomStyle,
  ru: copyBtnCustomStyle,
  de: copyBtnCustomStyle,
  jp: copyBtnCustomStyle,
  default: {
    maxWidth: '100%'
  }
}
const getStylesByLang = styleByLang(stylesObj)


export const DNSContainer = styled.div`
  background-color: #ffffff;
  @media only screen and (max-width: 889px) {
    background-color: #f5fafe;
    & > div:first-child {
      padding: 20px 20px 0;
    }
  }
`

export const DNSTextWrapper = styled.div`
  margin-top: 10px;
  width: 90%;
  @media only screen and (max-width: 889px) {
    width: 100%;
    margin: 0;
    padding: 10px 20px 0;
    box-sizing: border-box;
  }
`

export const DNSText = styled.span`
  font-size: 14px;
  color: #545f7e;
`

export const TabsWrapper = styled.div`
  width: 93%;
  background-color: ${primary.ExtraLight};
  border-radius: 10px;
  box-sizing: border-box;
  padding: 20px 0 5px;
  margin-top: 20px;
  ul {
    display: flex;
    justify-content: flex-start;
    padding: 0 20px 0 15px;
  }
  li {
    margin-right: 30px;
    & > :nth-child(1) {
      margin: 0 5px 5px;
    }
    & > :nth-child(2) {
      width: 95%;
      height: 5px;
      margin: 0 auto;
    }
  }
  @media only screen and (max-width: 889px) {
    width: 100%;
    margin: 0;
    padding: 25px 0 5px;
  }
`

export const TabsHeader = styled.div`
  display: flex;
  border-bottom: 1px solid ${primary.SemiLight};
`

export const NeedHelpLink = styled.a`
  display: flex;
  align-items: center;
  padding: 0 20px 10px 0;
  font-size: 14px;
  font-weight: 500;
  color: ${primary.Dark};
  text-decoration: none;
  & > div {
    margin-right: 6px;
    @media only screen and (max-width: 889px) {
      margin-right: 20px;
    }
  }
  &:hover {
    text-decoration: underline;
    text-decoration-color: ${primary.Dark};
  }
  @media only screen and (max-width: 889px) {
    align-items: stretch;
    border-radius: 0;
    padding: 0;
  }
`
export const TabTitle = styled.h4`
  font-size: 14px;
  padding: 20px;
`
export const DescriptionWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 20px 15px;
`
export const GreenCircle = styled.div`
  width: 6px;
  height: 6px;
  flex-shrink: 0;
  background-color: ${accentGreen};
  border-radius: 100%;
`
export const Description = styled.p`
  @media only screen and (max-width: 440px) {
    min-width: 260px;
  }
  font-size: 14px;
  color: ${secondary.SemiDark};
  margin-left: 10px;
`

export const Table = styled.table`
  background-color: ${primary.SemiLight};
  border-radius: 10px;
  margin: 0 20px 20px 20px;
  width: 555px;
  .first-block {
    background-color: ${primary.Light};
    font-size: 10px;
    font-weight: 600;
    color: ${secondary.Dark};
    td {
      padding: 10px;
    }
    td:first-child {
      border-top-left-radius: 10px;
    }
    td:last-child {
      border-top-right-radius: 10px;
    }
  }
  .second-block {
    background-color: ${white};
    font-size: 12px;
    font-weight: 500;
    padding: 15px 10px;
    color: ${secondary.SemiDark};
    td {
      padding: 15px 20px 15px 10px;
    }
    td:first-child {
      border-bottom-left-radius: 10px;
      min-width: 47px;
    }
    td:nth-child(2) {
      display: flex;
      p {
        color: ${secondary.SemiLight};
        margin: 0 10px;
      }
    }
    td:last-child {
      border-bottom-right-radius: 10px;
      box-sizing: border-box;
    }
  }
  .copy-btn-wrapper {
    display: flex;
    justify-content: space-between;
  }
  .copy-btn-text {
    max-width: ${getStylesByLang('maxWidth')};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .copy-btn {
    display: flex;
    align-items: center;
    color: ${primary.Dark};
    font-weight: 500;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 0 0 0 10px;
    & > div {
      margin-right: 6px;
    }
  }
`
export const TabLabelWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: ${({ isActive }) => (isActive ? primary.Dark : secondary.SemiLight)};
  & > div {
    margin-left: 5px;
  }
  .info-icon {
    fill: #9aa6c8;
    display: flex;
    cursor: pointer;
  }
  .tooltip {
    position: absolute;
    right: -25px;
  }
`

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: ${({ infoText }) => (infoText ? '30px 0 20px 0' : '40px 0 20px 0')};
  .footer-btn {
    margin: 0 10px;
    font-weight: 500;
  }
`

export const SmallTable = styled.table`
  background-color: ${primary.SemiLight};
  border-radius: 10px;
  margin: 0 20px 20px 35px;
  .first-block {
    background-color: ${primary.Light};
    font-size: 10px;
    font-weight: 600;
    color: ${secondary.Dark};
    td {
      padding: 10px;
    }
    td:first-child {
      border-top-left-radius: 10px;
    }
    td:last-child {
      border-top-right-radius: 10px;
    }
  }
  .second-block {
    background-color: ${white};
    font-size: 12px;
    font-weight: 500;
    padding: 15px 10px;
    color: ${secondary.SemiDark};
    td {
      padding: 13px 20px 8px 10px;
    }
    td:first-child {
      min-width: 47px;
    }
    td:nth-child(2) {
      display: flex;
      p {
        color: ${secondary.SemiLight};
        margin: 0 10px;
      }
    }
  }
  .third-block {
    background-color: ${primary.Light};
    font-size: 10px;
    font-weight: 600;
    color: ${secondary.Dark};
    td {
      padding: 10px;
    }
  }
  .fourth-block {
    background-color: ${white};
    font-size: 12px;
    font-weight: 500;
    padding: 15px 10px;
    color: ${secondary.SemiDark};
    td {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      padding: 14px 10px;
    }
  }
  .copy-btn-wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .copy-btn-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @media only screen and (max-width: 450px) {
      max-width: 225px;
    }
  }
  .copy-btn {
    display: flex;
    align-items: center;
    color: ${primary.Dark};
    font-weight: 500;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 0 10px;
    & > div {
      margin-right: 6px;
    }
    @media only screen and (max-width: 889px) {
      padding: 10px 0 0;
    }
  }
`

export const TooltipContent = styled.div`
  max-width: 280px;
  background-color: #363f5a;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 3px 6px #252e484d;
  box-sizing: border-box;
  p {
    width: 100%;
    font-size: 14px;
    color: #ffffff;
    padding-top: 10px;
  }
  img {
    width: 100%;
  }
`

export const WWWOptionTooltipWrapper = styled(TooltipWrapper)`
  .info-icon {
    fill: #9aa6c8;
    display: flex;
    cursor: pointer;
  }
`
