// @flow
import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useMemo
} from 'react'
import { connect } from 'react-redux'
import { translate } from '@editor/common/utils/translations'

import * as Styled from './styled'
import { SUBDOMAIN, CUSTOM_DOMAIN } from './consts'
import { SUBDOMAIN_IMG, CUSTOM_DOMAIN_IMG } from './consts'
import type { TDomainProps } from './types'

import {
  Card,
  SubdomainContent,
  CustomDomainContent,
  DomainMobile
} from './components'
import MyDomain from '@editor/common/components/MyDomain'
import SettingsTabs from '@editor/common/components/SettingsTabs'

import { isSubscriber, selectDomain, selectSubdomainValue } from '@selectors'
import { UpgradePopupContext } from '@contexts'
import { useResponsive } from '@shared/hooks'
import { TabContent } from '../shared-components'

const Domain = ({
  isSubscriber,
  subdomain,
  domain,
  setChangesState
}: TDomainProps) => {
  const initialActiveTab = isSubscriber ? 'right' : 'left'

  const [activeTab, setActiveTab] = useState(initialActiveTab)
  const [subdomainValue, setSubdomainValue] = useState(subdomain || '')
  const [customDomainValue, setCustomDomainValue] = useState(domain)

  const { setUpgradePopupState } = useContext(UpgradePopupContext)

  const tabsData = useMemo(
    () => ({
      left: {
        tab: (
          <TabContent
            imgSrc={SUBDOMAIN_IMG}
            title={translate('use_free_domain_label')}
            description={translate('use_free_domain_text')}
          />
        ),
        content: (
          <SubdomainContent
            value={subdomainValue}
            setValue={setSubdomainValue}
          />
        ),
        isDisabled: !!domain,
        tooltipText: !!domain
          ? translate('disabled_subdomain_tooltip_text')
          : ''
      },
      right: {
        tab: (
          <TabContent
            imgSrc={CUSTOM_DOMAIN_IMG}
            title={translate('add_custom_domain_label')}
            description={translate('add_custom_domain_text')}
            showUpgradeLabel={!isSubscriber}
          />
        ),
        content: (
          <CustomDomainContent
            value={customDomainValue}
            setValue={setCustomDomainValue}
          />
        )
      }
    }),
    [subdomainValue, customDomainValue, isSubscriber, domain]
  )

  const hasChanges =
    (activeTab === 'left' && subdomainValue !== subdomain) ||
    (activeTab === 'right' && customDomainValue !== domain)

  const isSmallSizes = useResponsive(889)

  useEffect(() => {
    setSubdomainValue(subdomain)
  }, [subdomain, activeTab])

  useEffect(() => {
    setCustomDomainValue(domain)
  }, [domain])

  useEffect(() => {
    setChangesState(hasChanges)
  }, [hasChanges])

  const onTabClick = useCallback(
    active => {
      if (active === activeTab) {
        return
      }

      if (!isSubscriber && active === 'right') {
        setUpgradePopupState({
          isOpen: true,
          isOpenFromPopup: true
        })
        return
      }

      setActiveTab(active)
    },
    [isSubscriber, activeTab]
  )

  return (
    <>
      <MyDomain />
      {isSmallSizes ? (
        <DomainMobile
          subdomainValue={subdomainValue}
          customDomainValue={customDomainValue}
          setSubdomainValue={setSubdomainValue}
          setCustomDomainValue={setCustomDomainValue}
        />
      ) : (
        <Styled.Container>
          <SettingsTabs
            activeTab={activeTab}
            onTabClick={onTabClick}
            tabsData={tabsData}
          />
        </Styled.Container>
      )}
    </>
  )
}

const mapStateToProps = state => ({
  isSubscriber: isSubscriber(state),
  domain: selectDomain(state),
  subdomain: selectSubdomainValue(state)
})

export default connect(mapStateToProps)(Domain)
