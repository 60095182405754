// @flow
import React, { useContext, useEffect, useCallback } from 'react'
import { connect } from 'react-redux'

import * as Styled from './styled'
import { TCookiesProps } from './types'
import CookiesDesktop from './CookiesDesktop'
import CookiesMobile from './CookiesMobile'
import {
  GDPRCookieContext,
  INITIAL_SIMPLE_COOKIES,
  INITIAL_GDPR_SETTINGS,
  SimpleCookieContext
} from './store'
import { MobileDetectContext } from '@contexts'
import { patchCookieSettings } from '@actions/cookie-settings'
import {
  selectSimpleCookies,
  selectGDPRCookies
} from '@selectors'

import { isValidUrl } from '@website/common/utils'
import { LINK_TYPES } from './containers/GDPR/PrivacyPolicyBtn/consts'

const CookiesContainer = ({
  simpleCookiesFromStore,
  GDPRCookiesFromStore,
  closeSettingsPopup,
  setChangesState,
  patchCookieSettings,
  hasChangesInTab,
  isSimpleDisabled
}: TCookiesProps) => {
  const isMobile = useContext(MobileDetectContext)
  const { state: simpleCookies } = useContext(SimpleCookieContext)
  const { state: gdprCookies, privacyPolicyLinkType } =
    useContext(GDPRCookieContext)

  const _simpleCookies = simpleCookiesFromStore || INITIAL_SIMPLE_COOKIES
  const _gdprCookies = GDPRCookiesFromStore || INITIAL_GDPR_SETTINGS

  const isPrivacyPolicyLinkValid =
    privacyPolicyLinkType === LINK_TYPES.EXTERNAL
      ? isValidUrl(gdprCookies.privacyPolicyBtn.link)
      : true

  const hasChanges =
    JSON.stringify({ simple: _simpleCookies, gdpr: _gdprCookies }) !==
    JSON.stringify({ simple: simpleCookies, gdpr: gdprCookies })
  const hasChangesInGDPR =
    JSON.stringify(_gdprCookies) !== JSON.stringify(gdprCookies)
  const isGDPRSettingsExcluded = !GDPRCookiesFromStore && !hasChangesInGDPR

  useEffect(() => {
    setChangesState(hasChanges)
  }, [hasChanges])

  const onSave = useCallback(() => {
    const data = {
      cookieSettings: {
        simple: simpleCookies,
        ...(!isGDPRSettingsExcluded && { gdpr: gdprCookies })
      }
    }

    patchCookieSettings(data)
  }, [simpleCookies, gdprCookies, patchCookieSettings])

  return (
    <>
      {isMobile ? (
        <CookiesMobile
          hasChangesInTab={hasChangesInTab}
          isSimpleDisabled={isSimpleDisabled}
        />
      ) : (
        <CookiesDesktop
          hasChangesInTab={hasChangesInTab}
          isSimpleDisabled={isSimpleDisabled}
        />
      )}
      <Styled.Footer
        isSaveButtonDisabled={!hasChanges || !isPrivacyPolicyLinkValid}
        hasBorder={isMobile}
        onDiscard={closeSettingsPopup}
        onSave={onSave}
      />
    </>
  )
}

const mapStateToProp = state => ({
  simpleCookiesFromStore: selectSimpleCookies(state),
  GDPRCookiesFromStore: selectGDPRCookies(state)
})

export default connect(mapStateToProp, {
  patchCookieSettings
})(CookiesContainer)
