import styled, { css } from 'styled-components'

import { GDPR } from './consts'
import { Footer as CommonFooter } from '../shared-components'
import { primary, secondary, white } from '@website/common/styles/colors'

const activeAccordionStyles = css`
  border: 1px solid ${primary.Dark};

  & > div:nth-child(3) {
    border-top: 1px solid ${secondary.ExtraLight};

    @media only screen and (max-width: 768px) {
      padding: 0;
    }
  }

  &:before {
    content: '';
    width: calc(100% + 10px);
    height: calc(100% + 10px);
    position: absolute;
    left: -5px;
    top: -5px;
    z-index: -1;
    background-color: #eaf2ff;
    border-radius: 12px;
  }
`

export const CookieTabsWrapper = styled.div`
  padding: 30px 40px 0;
  flex-grow: 1;
  overflow: hidden;
`

export const CookieAccordionsWrapper = styled.div`
  box-sizing: border-box;
  overflow-y: scroll;
  padding: 30px 10px;
  flex-grow: 1;
  .cookie-accordion {
    margin: 20px 0;
    background-color: ${white};
    border-radius: 10px;

    & > div:nth-child(2) {
      padding: 0;
    }
  }

  .disabled-cookie-accordion {
    background-color: ${white};
  }

  ${({ activeAccordion }) =>
    activeAccordion === GDPR
      ? css`
          & > div:nth-child(1) {
            ${activeAccordionStyles}
          }
        `
      : css`
          & > div:nth-child(2) {
            ${activeAccordionStyles}
          }
        `}
`

export const Footer = styled(CommonFooter)`
  flex-shrink: 0;
  button:nth-child(2) {
    margin-right: 30px;
  }
`
