import React, { memo, useCallback, useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { translate } from '@editor/common/utils/translations'

import * as Styled from './styled'
import { Line } from './common-styled'
import {
  DEFAULT_CONSENT_TEXT,
  CONSENT_TOOLTIP_IMAGE,
  NEED_HELP_LINK,
  gdprAccordionTabs
} from './consts'
import { Input } from '../../../shared-components'
import MultipleAccordion from '@editor/common/lib/Accordion/MultipleAccordion'
import { Switcher } from '@renderforest/rf-ui-library/atoms/Switcher'
import NeedHelpLink from './NeedHelpLink'
import GDPRCookieImg from '../../gdpr-cookie.svg'
import { GDPRCookieContext } from '../../store'

const CONSENT_MAX_COUNT = 5000

const GDPR = () => {
  const {
    state: { isVisible, consentText },
    actions: { setGDPRVisibility, setCookieConsentText }
  } = useContext(GDPRCookieContext)

  const history = useHistory()
  const activeSetting = history.location.state?.activeSetting

  useEffect(() => {
    history.replace({ ...history.location, state: {} })
  }, [])

  const toggleSwitcher = useCallback(() => {
    setGDPRVisibility(!isVisible)
  }, [isVisible])

  const handleCookieConsentTextChange = useCallback(
    text => {
      setCookieConsentText(text)
    },
    [setCookieConsentText]
  )

  return (
    <Styled.Container>
      <Styled.ConsentSection>
        <Styled.Image src={GDPRCookieImg} alt="gdpr-cookie" />
        <div>
          <Styled.TitleContainer>
            <Styled.Title
              title={translate('enable_gdpr_label')}
              tooltipText={translate('gdpr_tooltip_text')}
              imgSrc={CONSENT_TOOLTIP_IMAGE}
              hasTooltipContent
            />
            <Switcher
              checked={isVisible}
              onClick={toggleSwitcher}
              size="medium"
            />
          </Styled.TitleContainer>
          <Styled.Description>
            {translate('gdpr_consent_descriptions')}
          </Styled.Description>
          <NeedHelpLink link={NEED_HELP_LINK} />
        </div>

        <Line />
        <Styled.InputLabel>
          {translate('consent_input_label')}
        </Styled.InputLabel>
        <Input
          isRequired
          isTextArea
          hideCount
          count={CONSENT_MAX_COUNT}
          value={consentText}
          defaultValue={DEFAULT_CONSENT_TEXT}
          placeholder={DEFAULT_CONSENT_TEXT}
          disabled={!isVisible}
          className="gdpr-consent-input"
          onChange={handleCookieConsentTextChange}
        />
      </Styled.ConsentSection>
      <MultipleAccordion
        className="gdpr-accordion-btns"
        data={gdprAccordionTabs}
        isDisabled={!isVisible}
        hasScrollableElement={!!activeSetting}
        initiallyExpandedKeys={[activeSetting]}
      />
    </Styled.Container>
  )
}

export default memo(GDPR)
