import produce from 'immer'

export const simpleCookieReducer = (state, action) =>
  produce(state, draft => {
    switch (action.type) {
      case 'SET_SIMPLE_COOKIE_VISIBILITY':
        draft.isVisible = action.value
        break
      case 'SET_COOKIE_CONSENT_TEXT':
        draft.consentText = action.value
        break
      case 'SET_ACCEPT_BUTTON_NAME':
        draft.acceptBtn.name = action.value
        break
      case 'RESET_SIMPLE_DATA':
        return action.value
      default:
        return draft
    }
  })

export const gdprCookieReducer = (state, action) =>
  produce(state, draft => {
    switch (action.type) {
      case 'SET_GDPR_VISIBILITY':
        draft.isVisible = action.value
        break
      case 'SET_COOKIE_CONSENT_TEXT':
        draft.consentText = action.value
        break
      case 'SET_ACCEPT_BUTTON_NAME':
        draft.acceptBtn.name = action.value
        break
      case 'SET_DECLINE_BUTTON_NAME':
        draft.declineBtn.name = action.value
        break
      case 'SET_SETTINGS_BUTTON_NAME':
        draft.settingsBtn.name = action.value
        break
      case 'SET_SETTINGS_BUTTON_VISIBILITY':
        draft.settingsBtn.isVisible = action.value
        break
      case 'SET_DECLINE_BTN_VISIBILITY':
        draft.declineBtn.isVisible = action.value
        break
      case 'SET_PRIVACY_POLICY_BUTTON_NAME':
        draft.privacyPolicyBtn.name = action.value
        break
      case 'SET_PRIVACY_POLICY_BUTTON_VISIBILITY':
        draft.privacyPolicyBtn.isVisible = action.value
        break
      case 'SET_PRIVACY_POLICY_BUTTON_VISIBILITY_IN_FOOTER':
        draft.privacyPolicyBtn.showInFooter = action.value
        break
      case 'SET_PRIVACY_POLICY_LINK':
        draft.privacyPolicyBtn.link = action.value
        break
      case 'SET_ESSENTIAL_TITLE':
        draft.settings.categories[0].title = action.value
        break
      case 'SET_ESSENTIAL_DESCRIPTION':
        draft.settings.categories[0].description = action.value
        break
      case 'SET_ANALYTICS_TITLE':
        draft.settings.categories[1].title = action.value
        break
      case 'SET_ANALYTICS_DESCRIPTION':
        draft.settings.categories[1].description = action.value
        break
      case 'SET_MARKETING_TITLE':
        draft.settings.categories[2].title = action.value
        break
      case 'SET_MARKETING_DESCRIPTION':
        draft.settings.categories[2].description = action.value
        break
      case 'SET_FUNCTIONAL_TITLE':
        draft.settings.categories[3].title = action.value
        break
      case 'SET_FUNCTIONAL_DESCRIPTION':
        draft.settings.categories[3].description = action.value
        break
      case 'SET_COOKIES_MAIN_TITLE':
        draft.settings.title = action.value
        break
      case 'SET_COOKIES_MAIN_DESCRIPTION':
        draft.settings.description = action.value
        break
      case 'SET_COOKIES_CONFIRM_BTN_TEXT':
        draft.settings.confirmBtnName = action.value
        break
      case 'SET_GDPR_SETTINGS':
        draft.settings = action.value
        break
      case 'RESET_GDPR_DATA':
        return action.value
      default:
        return draft
    }
  })
