// @flow

import React, { memo } from 'react'

import * as Styled from './styled'
import { TSuccessPopupProps } from './types'
import SuccessAnimation from '@editor/common/components/CongratsAnimation'
import { translate } from '@editor/common/utils/translations'

const SuccessPopup = ({ isOpen, onClose }: TSuccessPopupProps) => (
  <Styled.SuccessPopup isOpen={isOpen} onClose={onClose}>
    <Styled.AnimationContainer>
      <SuccessAnimation />
    </Styled.AnimationContainer>
    <Styled.Title>
      {translate('your_website_is_multilingual_now_label')}
    </Styled.Title>
    <Styled.Description>
      {translate('you_can_edit_and_translate_label')}
    </Styled.Description>
    <Styled.GotItButton type="tertiary" onClick={onClose}>
      {translate('got_it_label')}
    </Styled.GotItButton>
  </Styled.SuccessPopup>
)

export default memo(SuccessPopup)
