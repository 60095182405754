//@flow
import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'

import * as Styled from './styled'
import ListItem from '../ListItem'
import type { TSecondaryLanguagesListProps } from './types'
import { SECONDARY_LANGUAGES_MAX_COUNT } from '../consts'
import LanguagesDropdown from '../LanguagesDropdown'
import MobileDragPreview from './MobileDragPreview'
import { MobileDetectContext } from '@contexts'
import AddIcon from '@editor/common/assets/svgr-icons/Plus_icon.svg'
import { translate } from '@editor/common/utils/translations'

const SecondaryLanguagesList = ({
  languages,
  possibleLanguages,
  allPossibleLanguages,
  primaryLanguage,
  isWebsiteMultilingual,
  isPrimaryDropdownVisible,
  hasDropdownAnimation,
  setLanguages,
  setPrimaryLanguage
}: TSecondaryLanguagesListProps) => {
  const [dropdownValue, setDropdownValue] = useState('')
  const [isDropdownVisible, setDropdownVisibility] = useState(
    !isWebsiteMultilingual
  )
  const isMobile = useContext(MobileDetectContext)

  const isAddLanguageBtnHidden =
    (languages.length >= SECONDARY_LANGUAGES_MAX_COUNT - 1 &&
      isDropdownVisible) ||
    languages.length >= SECONDARY_LANGUAGES_MAX_COUNT
  const isAddLanguageBtnDisabled = isPrimaryDropdownVisible || isDropdownVisible

  const showDropdown = useCallback(() => {
    setDropdownVisibility(true)
    setDropdownValue('')
  }, [])

  // if deleting last secondary language show dropdown
  useEffect(() => {
    !languages.length && showDropdown()
  }, [languages, showDropdown])

  const moveItem = useCallback(
    (dragIndex, hoverIndex) => {
      const _languages = [...languages]

      _languages.splice(dragIndex, 1)
      _languages.splice(hoverIndex, 0, languages[dragIndex])

      setLanguages(_languages)
    },
    [languages]
  )

  const onDropdownItemClick = useCallback(
    value => {
      setDropdownValue(value)

      const selectingLanguage = possibleLanguages.find(
        language => `${language.code}-${language.flagCode}` === value
      )

      const newLanguage = {
        lang: selectingLanguage,
        smLangId: selectingLanguage.id,
        isPrimary: false,
        status: 'active',
        added: true,
        autoTranslate: selectingLanguage.isAutoTranslateSupported
      }

      setLanguages(languages => [...languages, newLanguage])
      setDropdownVisibility(false)
    },
    [possibleLanguages]
  )

  const changePrimary = useCallback(
    idx => () => {
      const newPrimaryLanguage = {
        ...languages[idx],
        isPrimary: true,
        order: 1,
        autoTranslate: allPossibleLanguages.find(
          lang => lang.code === languages[idx].lang.code
        )?.isAutoTranslateSupported
      }

      const _languages = !primaryLanguage
        ? []
        : [
            ...languages.slice(0, idx),
            { ...primaryLanguage, isPrimary: false },
            ...languages.slice(idx + 1)
          ]

      setPrimaryLanguage(newPrimaryLanguage)
      setLanguages(_languages)
    },
    [languages, primaryLanguage]
  )

  const changeLanguageVisibility = useCallback(
    idx => status => {
      const _languages = [
        ...languages.slice(0, idx),
        { ...languages[idx], status },
        ...languages.slice(idx + 1)
      ]

      setLanguages(_languages)
    },
    [languages]
  )

  const autoTranslateLanguage = useCallback(
    idx => autoTranslate => {
      setLanguages(prevLanguages => {
        if (prevLanguages[idx].autoTranslate === autoTranslate)
          return prevLanguages

        const updatedLanguages = [...prevLanguages]
        updatedLanguages[idx] = { ...updatedLanguages[idx], autoTranslate }
        return updatedLanguages
      })
    },
    []
  )

  const restoreLanguage = useCallback(
    name => () => {
      const _languages = languages.map(language => {
        const { removed, ...rest } = language
        return language.lang.name === name ? rest : language
      })

      setLanguages(_languages)
    },
    [languages]
  )

  const deleteLanguage = useCallback(
    name => () => {
      const deletingLanguage = languages.find(({ lang }) => lang.name === name)

      if (!deletingLanguage.added) {
        const _languages = languages.map(language => {
          const updatedLanguage = {
            ...language,
            removed: true
          }

          return language.lang.name === name ? updatedLanguage : language
        })

        setLanguages(_languages)

        return
      }

      setLanguages(languages =>
        languages.filter(({ lang }) => lang.name !== name)
      )
    },
    [languages]
  )

  return (
    <>
      <Styled.SecondaryLanguagesList>
        {languages.map((language, idx) => (
          <ListItem
            key={language.lang.name}
            idx={idx}
            data={language}
            moveItem={moveItem}
            isDraggable={languages.length > 1}
            isLanguageDeleted={language.removed}
            changePrimary={changePrimary}
            changeLanguageVisibility={changeLanguageVisibility}
            deleteLanguage={deleteLanguage}
            restoreLanguage={restoreLanguage}
            autoTranslateLanguage={autoTranslateLanguage}
          />
        ))}
        {isMobile ? <MobileDragPreview languages={languages} /> : null}
      </Styled.SecondaryLanguagesList>
      {isDropdownVisible ? (
        <LanguagesDropdown
          value={dropdownValue}
          onClick={onDropdownItemClick}
          possibleLanguages={possibleLanguages}
          hasAnimation={hasDropdownAnimation}
        />
      ) : null}
      {!isAddLanguageBtnHidden ? (
        <Styled.AddLanguageButton
          disabled={isAddLanguageBtnDisabled}
          onClick={showDropdown}
        >
          <AddIcon />
          {translate('add_language_label')}
        </Styled.AddLanguageButton>
      ) : null}
    </>
  )
}

export default memo(SecondaryLanguagesList)
