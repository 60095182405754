// @flow

import React, { memo, useContext } from 'react'
import VerticalTabs from './VerticalTabs'
import MultipleAccordion from './../Accordion'
import { MobileDetectContext } from '@contexts'
import type { TAdaptiveVerticalTabsProps } from './types'

const AdaptiveVerticalTabs = ({
  data,
  onChange,
  activeTab,
  children
}: TAdaptiveVerticalTabsProps) => {
  const isMobile = useContext(MobileDetectContext)
  const commonProps = { data, onChange, activeTab }
  const AdaptiveComponent = isMobile ? MultipleAccordion : VerticalTabs

  return <AdaptiveComponent {...commonProps}>{children}</AdaptiveComponent>
}

export default memo(AdaptiveVerticalTabs)
