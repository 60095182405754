// @flow
import React, { useContext } from 'react'
import { connect } from 'react-redux'
import { translate } from '@editor/common/utils/translations'

import { MobileDetectContext } from '@contexts'
import CustomizationWindow from '@editor/common/components/CustomizationWindow'
import { selectGdprSettingsCustomizationWindowState } from '@selectors'
import { closeGdprSettingsCustomizationWindow } from '@actions/editor'
import { selectGDPRCookies } from '@selectors/cookie-settings'
import CookieStoreContextProvider from './store/Provider'
import CustomizationWindowContent from './containers/CustomizationWindowContent'
import { TCookiesProps } from './types'
import CookiesContainer from './CookiesContainer'

const cookieSettingsDefaultData = {
  mainTitle: 'Cookie Settings',
  mainDescription:
    'Our website uses various cookies, each of which has a specific function. You can manage them below.',
  confirmButtonText: 'CONFIRM'
}

const Cookies = ({
  closeSettingsPopup,
  settingsCustomizationPopupState,
  setChangesState,
  closeGdprSettingsCustomizationWindow,
  hasChangesInTab,
  isGDPRVisible
}: TCookiesProps) => {
  const isMobile = useContext(MobileDetectContext)

  return (
    <CookieStoreContextProvider>
      <CustomizationWindow
        onClose={closeGdprSettingsCustomizationWindow}
        desktopProps={{ className: 'settings-customization-window' }}
        mobileProps={{
          className: 'settings-customization-window-mobile',
          title: translate('edit_cookie_settings_title')
        }}
        isOpen={settingsCustomizationPopupState}
      >
        <CustomizationWindowContent
          isMobile={isMobile}
          {...cookieSettingsDefaultData}
        />
      </CustomizationWindow>
      <CookiesContainer
        closeSettingsPopup={closeSettingsPopup}
        setChangesState={setChangesState}
        hasChangesInTab={hasChangesInTab}
        isSimpleDisabled={isGDPRVisible}
      />
    </CookieStoreContextProvider>
  )
}

const mapStateToProps = state => ({
  settingsCustomizationPopupState:
    selectGdprSettingsCustomizationWindowState(state),
  isGDPRVisible: selectGDPRCookies(state)?.isVisible
})

export default connect(mapStateToProps, {
  closeGdprSettingsCustomizationWindow
})(Cookies)
