import styled, { css } from 'styled-components'
import { accentGreen, primary, secondary } from '@website/common/styles/colors'

export const SSLContainer = styled.div`
  padding: 20px 0;
  margin: 20px 0;
  border-top: 1px solid #dee3f0;
  border-bottom: 1px solid #dee3f0;
  @media only screen and (max-width: 889px) {
    padding: 20px;
    margin: 10px 0 0;
  }
`

const getAnimationStyles = ({ isAnimationActive }) =>
  isAnimationActive
    ? css`
        & > div {
          animation: 1s disabledSwitcher;
        }
        @keyframes disabledSwitcher {
          50% {
            transform: translateX(15px);
          }
        }
      `
    : ''

export const SwitcherWrapper = styled.div`
  display: flex;
  align-items: center;
  .tooltip {
    display: flex;
  }
  div[role='switch'] {
    ${getAnimationStyles};
    background-color: ${({ isSSLEnabled, isAnimationActive }) =>
      isSSLEnabled || isAnimationActive ? accentGreen : secondary.Light};
  }
`

export const SSLText = styled.h4`
  color: #363f5a;
  font-weight: 500;
  padding-right: 10px;
`

export const LearnMoreWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-top: 10px;
  .info-icon {
    fill: #9aa6c8;
    display: flex;
    cursor: pointer;
  }
`
export const LearnMore = styled.p`
  font-size: 14px;
  color: #545f7e;
`
