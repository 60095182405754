import styled from 'styled-components'
import LibSimpleAccordion from './SingleAccordion'

export const Container = styled.div`
  height: auto;
`

export const SingleAccordion = styled(LibSimpleAccordion)`
  margin: 30px 0;
  @media only screen and (max-width: 768px) {
    margin: 15px 0;
  }
`
