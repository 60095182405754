// @flow
import React, { memo } from 'react'

import * as Styled from './styled'
import type { TTabProps } from './types'
import TooltipWrapper from '@website/common/components/TooltipWrapper'
import { secondary } from '@website/common/styles/colors'

const Tab = ({
  tabContent,
  isActive,
  isDisabled,
  tooltipText = '',
  onClick
}: TTabProps) => (
  <>
    <Styled.GlobalStyles />
    <TooltipWrapper
      position="bottom"
      text={tooltipText}
      color={secondary.Dark}
      className="tab-tooltip"
    >
      <Styled.TabWrapper isActive={isActive}>
        <Styled.Tab isActive={isActive} onClick={isDisabled ? null : onClick}>
          {tabContent}
          {isActive ? <Styled.Line /> : null}
        </Styled.Tab>
        {isDisabled ? <Styled.Overlay /> : null}
      </Styled.TabWrapper>
    </TooltipWrapper>
  </>
)

export default memo(Tab)
