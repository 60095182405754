// @flow
import React, { memo } from 'react'

import * as Styled from './styled'
import type { TAccordionTitleProps } from './types'
import { UPGRADE_LABEL } from '../../consts'

const AccordionTitle = ({ title, showUpgradeLabel }: TAccordionTitleProps) => (
  <Styled.AccordionTitleWrapper>
    <p>{title}</p>
    {showUpgradeLabel ? (
      <Styled.UpgradeLabel src={UPGRADE_LABEL} alt="upgrade" />
    ) : null}
  </Styled.AccordionTitleWrapper>
)

export default memo(AccordionTitle)
