//@flow
import React, { memo } from 'react'
import { primary } from '@website/common/styles/colors'
import Icon from '@website/common/components/Icon'
import { translate } from '@editor/common/utils/translations'

import * as Styled from './styled'

const NeedHelpLink = ({ link }) => (
  <Styled.NeedHelpLink href={link} target="_blank">
    <Icon name="help" color={primary.Dark} size="normal" />
    {translate('need_help_label')}
  </Styled.NeedHelpLink>
)

export default memo(NeedHelpLink)
